import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { VideoCreateInput } from '../generated/globalTypes';
import { createVideoMutation, createVideoMutationVariables } from '../generated/createVideoMutation';
import { videoFieldsFragment } from '../models/Video';

/**
 * Get a callback to create a Video in the store.
 */
export function useCreateVideoCallback(): [(model: VideoCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createVideoMutation, createVideoMutationVariables>(
        gql`
            mutation createVideoMutation ($model: VideoCreateInput!) {
                createVideo(model: $model) {
                    ...videoFields
                }
            }

            ${videoFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: VideoCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
