import '../../../node_modules/react-quill/dist/quill.snow.css';

import * as React from 'react';
import ReactQuill from 'react-quill';

export interface HtmlEditorProps {
    value?: string,
    onChange?: (value: string) => void,
    onBlur?: () => void,
    readOnly?: boolean,
}

/**
 * Html editor component that abstracts the editor actually being used from the code using it where its unimportant.
 * 
 * This version uses ReactQuill internally.
 * 
 * @param props
 */
export const HtmlEditor = (props: HtmlEditorProps) => {
    const { value, onChange, onBlur, readOnly } = props;

    return (
        <ReactQuill value={value}
            readOnly={readOnly}
            onChange={value => {
                if (onChange) {
                    onChange(value);
                }
            }}
            onBlur={() => {
                if (onBlur) {
                    onBlur();
                }
            }}
        />
        );
};