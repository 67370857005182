import * as React from 'react';
import { RequirementSchoolBusLinkCreateInput, RequirementSchoolBusLinkUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateRequirementSchoolBusLinkCallback } from './useCreateRequirementSchoolBusLinkCallback';
import { useUpdateRequirementSchoolBusLinkCallback } from './useUpdateRequirementSchoolBusLinkCallback';
import { RequirementSchoolBusLink } from '../models/RequirementSchoolBusLink';

/**
 * Get a callback to save a RequirementSchoolBusLink in the store using either a create or update.
 */
export function useSaveRequirementSchoolBusLinkCallback(options: SaveInStoreOptions<RequirementSchoolBusLink, string> = {}) {
    const [_create, createStatus] = useCreateRequirementSchoolBusLinkCallback();
    const create = React.useCallback((model: Partial<RequirementSchoolBusLink>) => _create(model as RequirementSchoolBusLinkCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateRequirementSchoolBusLinkCallback();
    const update = React.useCallback((id: string, changes: Partial<RequirementSchoolBusLink>) => _update(id, changes as RequirementSchoolBusLinkUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
