import * as React from 'react';
import { Waypoint } from "react-waypoint";

/**
 * Customised Waypoint that knows how to find the right scrollable ancestor.
 * @param props
 */
export const WaypointExtended = (props: Waypoint.WaypointProps) => {
    // Find the right scrollableAncestor depending on if we are integrated into TheSchoolBus' own layout or not.
    const scrollableAncestorRef = React.useRef<any>();
    React.useEffect(() => {
        //if (integrationConfig.fullLayoutControl) {
            scrollableAncestorRef.current = window;
        //} else {
        //    scrollableAncestorRef.current = document.getElementsByClassName('mat-sidenav-content')?.item(0) ?? window;
        //}
    }, []);

    return (
        <Waypoint scrollableAncestor={scrollableAncestorRef.current} {...props} />
        );
};
