import gql from "graphql-tag";
import { schoolFields } from "../generated/schoolFields";

export const schoolFieldsFragment = gql`
    fragment schoolFields on School {
        id
        schoolName
        street
        town
        address3
        county
        postCode
        establishmentNo
        isMasterSchool
    }
`;


export type School = Omit<schoolFields, '__typename'>;
