import * as React from 'react';
import { Requirement } from '../../api/main/models/Requirement';
import { RequirementsReleaseRequirement } from '../../api/main/models/RequirementsReleaseRequirement';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Row, Col, Button, Card, CardBody, CardHeader, Collapse, FormText, Badge } from 'reactstrap';
import { ValidatedInput } from 'pojo-validator-reactstrap/dist/lib/commonjs';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import { HtmlEditor } from '../../shared/htmlEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToggleState } from 'use-toggle-state';
import { TwoValueSwitch } from '../shared/TwoValueSwitch';
import { Guid } from 'guid-string';
import { SchoolPhase } from '../../api/main/models/SchoolPhase';
import { SchoolType } from '../../api/main/models/SchoolType';
import { RequirementSchoolPhase } from '../../api/main/models/RequirementSchoolPhase';
import { RequirementSchoolType } from '../../api/main/models/RequirementSchoolType';
import { ModelArrayChanges } from '../../shared/useChanges';
import { RequirementSchoolBusLink } from '../../api/main/models/RequirementSchoolBusLink';
import { EditRequirementSchoolBusLink } from './EditRequirementSchoolBusLink';
import { policyManagerLinkTypes } from '../../services/policyManagerIntegration/policyManagerLinkTypes';
import { ConditionalFragment } from 'react-conditionalfragment';
import { PolicyManagerMandatoryPolicy } from '../../api/main/models/PolicyManagerMandatoryPolicy';
import { RequirementArea } from '../../api/main/models/RequirementArea';
import { standardRatings, getStandardRatingMetadata, StandardRating } from '../../api/main/models/constants/StandardRatings';
import { requirementsReleaseSupportingDataQuery_videos } from '../../api/main/generated/requirementsReleaseSupportingDataQuery';
import { VideoTile } from '../videos/VideoTile';

export interface EditRequirementProps {
    model: Requirement,
    change: (changes: Partial<Requirement>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    linkModel: RequirementsReleaseRequirement,
    changeLink: (changes: Partial<RequirementsReleaseRequirement>) => void,
    otherLinks: Array<RequirementsReleaseRequirement>,

    startOpen?: boolean,

    remove: () => void,
    removeLink: () => void,

    schoolPhases: Array<SchoolPhase>,
    schoolTypes: Array<SchoolType>,
    policyManagerMandatoryPolicies: Array<PolicyManagerMandatoryPolicy>,

    schoolPhaseLinksManager: ModelArrayChanges<RequirementSchoolPhase, string>,
    schoolTypeLinksManager: ModelArrayChanges<RequirementSchoolType, string>,
    schoolBusLinksManager: ModelArrayChanges<RequirementSchoolBusLink, string>,

    areas: Array<RequirementArea>,

    readOnly: boolean,

    videos: Array<requirementsReleaseSupportingDataQuery_videos>,
}

/**
 * An area within a RequirementsRelease.
 * @param props
 */
export const EditRequirement = (props: EditRequirementProps) => {
    const {
        model, change, validate, validationErrors,
        //linkModel, changeLink,
        otherLinks,
        startOpen,
        remove, removeLink,
        schoolPhases, schoolTypes, policyManagerMandatoryPolicies,
        schoolPhaseLinksManager, schoolTypeLinksManager,
        schoolBusLinksManager,
        areas,
        readOnly,
        videos,
    } = props;

    const { t } = useTranslation();
    const [isOpen, toggleOpen] = useToggleState(!!startOpen);

    // Is this phase applicable to this requirement?
    const isPhaseApplicable = React.useCallback((schoolPhaseId: string) => {
        if (!model) {
            return false;
        }

        return !!schoolPhaseLinksManager.model.find(it => it.requirementId === model.id && it.schoolPhaseId === schoolPhaseId);
    }, [schoolPhaseLinksManager, model]);
    // Toggle if this phase is applicable to this requirement.
    const togglePhaseApplicable = React.useCallback((schoolPhaseId: string) => {
        if (!model) {
            return;
        }

        const existing = schoolPhaseLinksManager.model.find(it => it.requirementId === model.id && it.schoolPhaseId === schoolPhaseId);
        if (existing) {
            schoolPhaseLinksManager.removeFor(existing.id);
        } else {
            schoolPhaseLinksManager.addFor({ id: Guid.newGuid(), requirementId: model.id, schoolPhaseId: schoolPhaseId });
        }
    }, [schoolPhaseLinksManager, model]);

    // Is this type applicable to this requirement?
    const isTypeApplicable = React.useCallback((schoolTypeId: string) => {
        if (!model) {
            return false;
        }

        return !!schoolTypeLinksManager.model.find(it => it.requirementId === model.id && it.schoolTypeId === schoolTypeId);
    }, [schoolTypeLinksManager, model]);
    // Toggle if this type is applicable to this requirement.
    const toggleTypeApplicable = React.useCallback((schoolTypeId: string) => {
        if (!model) {
            return;
        }

        const existing = schoolTypeLinksManager.model.find(it => it.requirementId === model.id && it.schoolTypeId === schoolTypeId);
        if (existing) {
            schoolTypeLinksManager.removeFor(existing.id);
        } else {
            schoolTypeLinksManager.addFor({ id: Guid.newGuid(), requirementId: model.id, schoolTypeId: schoolTypeId });
        }
    }, [schoolTypeLinksManager, model]);

    // Add a SchoolBusLink
    const addSchoolBusLink = React.useCallback(() => {
        if (!model) {
            return;
        }

        // Work out the next display order.
        let nextDisplayOrder = 1;
        for (const item of schoolBusLinksManager.model) {
            if (item.displayOrder >= nextDisplayOrder) {
                nextDisplayOrder = item.displayOrder + 1;
            }
        }

        schoolBusLinksManager.addFor({ id: Guid.newGuid(), requirementId: model.id, linkText: '', url: '', archived: false, displayOrder: nextDisplayOrder, })
    }, [schoolBusLinksManager, model]);

    const policyManagerLinkType = React.useMemo(() => policyManagerLinkTypes.items.find(it => it.id === model?.policyManagerType), [model]);

    // Get metadata for the standard rating we are currently in.
    const standardRatingMetadata = getStandardRatingMetadata(model.standardRating as StandardRating);

    // Get the selected video
    const video = React.useMemo(() => videos?.find(it => it.id === model?.videoId), [model, videos]);

    return (
        <Card>
            <CardHeader style={{ cursor: 'pointer' }} onClick={() => toggleOpen()}>
                <Row>
                    <Col>
                        {model.name}
                    </Col>
                    <Col xs="auto">
                        <Badge style={{ backgroundColor: standardRatingMetadata.color, }}>
                            <FontAwesomeIcon icon="award" />
                            <> </>
                            {standardRatingMetadata.displayName}
                        </Badge>
                    </Col>
                    <ConditionalFragment showIf={!!otherLinks?.length}>
                        <Col xs="auto">
                            <Badge>
                                {t('common.integer', '{{value, #,0}}', { value: 1 + otherLinks.length })}
                                <FontAwesomeIcon icon="link" />
                            </Badge>
                        </Col>
                    </ConditionalFragment>
                    <Col xs="auto">
                        <FontAwesomeIcon icon={ isOpen? 'caret-up': 'caret-down' } />
                        <span className="sr-only">{t('common.seeMore', 'See more')}</span>
                    </Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <ConditionalFragment showIf={isOpen}>
                    <CardBody tag="div">
                        <FormGroup>
                            <Label htmlFor="name">{t('editRequirement.name', 'Title')}</Label>
                            <Row>
                                <Col>
                                    <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={() => validate('name')} validationErrors={validationErrors['name']} />
                                </Col>
                                <ConditionalFragment showIf={!readOnly}>
                                    <Col xs="auto">
                                        {
                                            !!otherLinks.length ? (
                                                <Button color="link" className="text-danger" onClick={removeLink}>
                                                    <FontAwesomeIcon icon="unlink" />
                                                    <span className="sr-only">{t('editRequirement.unlink', 'Unlink requirement')}</span>
                                                </Button>
                                            ) : (
                                                    <Button color="link" className="text-danger" onClick={remove}>
                                                        <FontAwesomeIcon icon="trash" />
                                                        <span className="sr-only">{t('editRequirement.delete', 'Delete requirement')}</span>
                                                    </Button>
                                                    )
                                        }
                                    </Col>
                                </ConditionalFragment>
                            </Row>

                            <ConditionalFragment showIf={!!otherLinks.length}>
                                <FormText>
                                    {t('editRequirement.alsoFoundIn', 'This requirement can also be found in: {{otherAreaNames}}.', { otherAreaNames: otherLinks.map(link => areas.find(it => it.id === link.requirementAreaId)?.name ?? '').join(', ') })}
                                </FormText>
                            </ConditionalFragment>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="name">{t('editRequirement.descriptionHtml', 'Description')}</Label>
                            <HtmlEditor readOnly={readOnly} value={model?.descriptionHtml ?? ''} onChange={value => change({ descriptionHtml: value })} />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="standardRating">{t('editRequirement.standardRating.label', 'Award level')}</Label>
                            <ValidatedInput name="standardRating" type="select"
                                value={model.standardRating} onChange={e => change({ standardRating: e.currentTarget.value || '' })}
                                onBlur={() => validate('standardRating')} validationErrors={validationErrors['standardRating']}
                                style={{ color: standardRatingMetadata.color, }}
                            >
                                {
                                    standardRatings.map(item => {
                                        const metadata = getStandardRatingMetadata(item);

                                        return (
                                            <option key={item} value={item} style={{ color: metadata.color }}>{metadata.displayName}</option>
                                        );
                                    })
                                }
                            </ValidatedInput>
                        </FormGroup>

                        <Row>
                            <Col xs={12} lg={6}>
                                <FormGroup>
                                    <Label htmlFor="name">{t('editRequirement.appliesToSchoolTypes', 'Applicable to school types')}</Label>
                                    <div>
                                        {
                                            schoolTypes.map(item => (
                                                <Button key={item.id} color="primary" size="sm" outline={!isTypeApplicable(item.id)} className="ml-1" onClick={() => {
                                                    if (readOnly) { return; }
                                                    toggleTypeApplicable(item.id);
                                                }}>
                                                    {item.name}
                                                </Button>
                                            ))
                                        }
                                    </div>
                                    <FormText>
                                        {t('editRequirement.appliesToSchoolTypesHint', 'Requirement will be shown to schools with any of the selected types.')}
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormGroup>
                                    <Label htmlFor="name">{t('editRequirement.appliesToSchoolPhases', 'Applicable to school phases')}</Label>
                                    <div>
                                        {
                                            schoolPhases.map(item => (
                                                <Button key={item.id} color="primary" size="sm" outline={!isPhaseApplicable(item.id)} className="ml-1" onClick={() => {
                                                    if (readOnly) { return; }
                                                    togglePhaseApplicable(item.id);
                                                }}>
                                                    {item.name}
                                                </Button>
                                            ))
                                        }
                                    </div>
                                    <FormText>
                                        {t('editRequirement.appliesToSchoolPhasesHint', 'Requirement will be shown to schools with any of the selected phases.')}
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Label htmlFor="videoId">{t('editRequirement.videoId.label', 'Video assistance')}</Label>
                            <Row>
                                <Col>
                                    <ValidatedInput name="videoId" type="select" readOnly={readOnly} value={model?.videoId ?? ''} onChange={e => change({ videoId: e.currentTarget.value ?? null })} onBlur={() => validate('videoId')} validationErrors={validationErrors['videoId']}>
                                        <option value="">{t('editRequirement.videoId.none', '(No video)')}</option>
                                        {
                                            videos?.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </Col>
                                {
                                    video ? (
                                        <Col xs={12} md="auto">
                                            <VideoTile
                                                size="xs"
                                                model={video}
                                                thumbnailBlob={video.thumbnailBlob}
                                            />
                                        </Col>
                                    ) : null
                                }
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="name">{t('editRequirement.theSchoolBusLinks', 'Links to The School Bus or other sites')}</Label>
                            <div>
                                {
                                    schoolBusLinksManager.model?.filter(item => item.requirementId === model?.id)?.map(item => (
                                        <EditRequirementSchoolBusLink key={item.id}
                                            readOnly={readOnly}
                                            model={item} change={changes => schoolBusLinksManager.changeFor(item.id, changes)}
                                            validate={() => true} validationErrors={{}}
                                            remove={() => schoolBusLinksManager.removeFor(item.id)}
                                            />
                                        ))
                                }

                                <ConditionalFragment showIf={!readOnly}>
                                    <Button color="primary" outline onClick={addSchoolBusLink}>
                                        <FontAwesomeIcon icon="plus" />
                                        <> </>{t('editRequirement.addSchooLBusLink', 'Add link')}
                                    </Button>
                                </ConditionalFragment>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="allowEvidenceUpload">{t('editRequirement.allowEvidenceUpload', 'Can evidence be uploaded by users against this requirement?')}</Label>
                            <TwoValueSwitch
                                readOnly={readOnly}
                                checked={model?.allowEvidenceUpload ?? false} onChange={checked => change({ allowEvidenceUpload: checked })}
                                leftLabel={t('editRequirement.evidenceUploadNotAllowed ', 'No uploads allowed')} rightLabel={t('editRequirement.evidenceUploadAllowed', 'Uploading of evidence is allowed')}
                            />
                        </FormGroup>

                        <Row>
                            <Col xs={12} lg={6}>
                                <FormGroup>
                                    <Label htmlFor="policyManagerType">{t('editRequirement.linksToPolicyManager', 'Policy manager automation')}</Label>
                                    <Row>
                                        <Col xs={policyManagerLinkType?.requiresLink? 'auto': ''}>
                                            <ValidatedInput name="policyManagerType" type="select" readOnly={readOnly} value={model?.policyManagerType ?? ''} onChange={e => change({ policyManagerType: e.currentTarget.value })} onBlur={() => validate('policyManagerType')} validationErrors={validationErrors['policyManagerType']}>
                                                {
                                                    policyManagerLinkTypes.items.map(item => (
                                                        <option key={item.id} value={item.id}>{item.localizedName()}</option>
                                                        ))
                                                }
                                            </ValidatedInput>
                                        </Col>
                                        <ConditionalFragment showIf={!!policyManagerLinkType?.requiresLink}>
                                            <Col>
                                                <ValidatedInput name="policyManagerId" type="select" readOnly={readOnly} value={model?.policyManagerId ?? 0} onChange={e => change({ policyManagerId: parseInt(e.currentTarget.value) })} onBlur={() => validate('policyManagerId')} validationErrors={validationErrors['policyManagerId']}>
                                                    <option value="0">{t('editRequirement.selectPolicy', '(Please select a policy from policy manager to link to)')}</option>
                                                    {
                                                        policyManagerMandatoryPolicies.map(item => (
                                                            <option key={item.id} value={item.id}>{item.title}</option>
                                                        ))
                                                    }
                                                </ValidatedInput>
                                            </Col>
                                        </ConditionalFragment>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormGroup>
                                    <Row>
                                        <Col xs="12" lg="">
                                            <FormGroup>
                                                <Label htmlFor="reviewTimescaleType">{t('editRequirement.reviewTimescale', 'Review')}</Label>
                                                <TwoValueSwitch
                                                    readOnly={readOnly}
                                                    checked={model?.requiresReview ?? false} onChange={checked => change({ requiresReview: checked })}
                                                    leftLabel={t('editRequirement.noReview ', 'Does not require review')} rightLabel={t('editRequirement.reviewRequired', 'Review required')}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <ConditionalFragment showIf={!!model?.requiresReview}>
                                            <Col xs="12" lg="">
                                                <Label htmlFor="reviewTimescaleType">{t('editRequirement.requiresReviewInDefaultMonths', 'Default months for review')}</Label>
                                                <ValidatedInput name="requiresReviewInDefaultMonths" type="number" readOnly={readOnly} value={model?.requiresReviewInDefaultMonths ?? ''} onChange={e => change({ requiresReviewInDefaultMonths: parseInt(e.currentTarget.value) })} onBlur={() => validate('requiresReviewInDefaultMonths')} validationErrors={validationErrors['requiresReviewInDefaultMonths']} />
                                            </Col>
                                        </ConditionalFragment>
                                    </Row>
                                    <ConditionalFragment showIf={!!model?.requiresReview}>
                                        <FormGroup>
                                            <Label htmlFor="requiresReviewDescriptionHtml">{t('editRequirement.requiresReviewDescriptionHtml', 'Review frequency recommendation')}</Label>
                                            <HtmlEditor readOnly={readOnly} value={model?.requiresReviewDescriptionHtml ?? ''} onChange={value => change({ requiresReviewDescriptionHtml: value })} />
                                        </FormGroup>
                                    </ConditionalFragment>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} lg={6}>
                                <FormGroup>
                                    <Label htmlFor="sourcesHtml">{t('editRequirement.sourcesHtml', 'Sources')}</Label>
                                    <HtmlEditor readOnly={readOnly} value={model?.sourcesHtml ?? ''} onChange={value => change({ sourcesHtml: value })} />
                                    <FormText>
                                        {t('editRequirement.sourcesHtmlHint', 'Used for internal editorial purposes only')}
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormGroup>
                                    <Label htmlFor="notesHtml">{t('editRequirement.notesHtml', 'Requirement basis')}</Label>
                                    <HtmlEditor readOnly={readOnly} value={model?.notesHtml ?? ''} onChange={value => change({ notesHtml: value })} />
                                    <FormText>
                                        {t('editRequirement.notesHtmlHint', 'Only complete where required.  Leave blank for requirements that do not need this shared with the user.')}
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </ConditionalFragment>
            </Collapse>
        </Card>
        );
};
