import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RequirementsReleasesList } from "./RequirementsReleasesList";
import { EditRequirementsRelease, CreateRequirementsRelease } from "./EditRequirementsRelease";
import { DeleteRequirementsRelease } from "./DeleteRequirementsRelease";
import { EditCurrentRequirementsRelease } from "./EditCurrentRequirementsRelease";


export const requriementRoutes: Array<RouteEntry> = [
    { path: '/administration/requirements', exact: true, component: RequirementsReleasesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/requirements/edit/:id', component: EditRequirementsRelease, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/requirements/add', component: CreateRequirementsRelease, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/requirements/delete/:id', component: DeleteRequirementsRelease, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/update-requirements/:timestamp?', component: EditCurrentRequirementsRelease, authorize: true, requireRole: IdentityRoles.Administration },
];
