import './layout.scss';

import * as React from 'react';
//import { AppBreadcrumb } from '@coreui/react';
import { Header } from './Header';
import { Footer } from './Footer';

import { Sidebar } from './Sidebar';
import { LoadingIndicator } from '../../components/shared/LoadingIndicator';

import { RouteEntry } from '../../shared/routes';

export interface LayoutProps {
    appRoutes: Array<RouteEntry>,
    children: React.ReactNode
}

export const Layout = (props: LayoutProps) => {
    return (
        <div className="app">
            <Header />
            <div className="app-body">
                <Sidebar />
                <main className="main">
                    {/*<AppBreadcrumb appRoutes={props.appRoutes} />*/}
                    <React.Suspense fallback={<LoadingIndicator />}>
                        {props.children}
                    </React.Suspense>
                </main>
            </div>
            <Footer />
        </div>
    );
};
