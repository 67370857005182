import gql from "graphql-tag";
import { schoolPhaseFields } from "../generated/schoolPhaseFields";

export const schoolPhaseFieldsFragment = gql`
    fragment schoolPhaseFields on SchoolPhase {
        id
        name
        archived
        displayOrder
    }
`;


export type SchoolPhase = Omit<schoolPhaseFields, '__typename'>;
