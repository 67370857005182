import * as React from 'react';
import { SchoolReportCreateInput, SchoolReportUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSchoolReportCallback } from './useCreateSchoolReportCallback';
import { useUpdateSchoolReportCallback } from './useUpdateSchoolReportCallback';
import { SchoolReport } from '../models/SchoolReport';

/**
 * Get a callback to save a SchoolReport in the store using either a create or update.
 */
export function useSaveSchoolReportCallback(options: SaveInStoreOptions<SchoolReport, string> = {}) {
    const [_create, createStatus] = useCreateSchoolReportCallback();
    const create = React.useCallback((model: Partial<SchoolReport>) => _create(model as SchoolReportCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSchoolReportCallback();
    const update = React.useCallback((id: string, changes: Partial<SchoolReport>) => _update(id, changes as SchoolReportUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
