import gql from "graphql-tag";
import { requirementsReleaseFields } from "../generated/requirementsReleaseFields";

export const requirementsReleaseFieldsFragment = gql`
    fragment requirementsReleaseFields on RequirementsRelease {
        id
        versionNumber
        patchNumber
        publishDate
        archived
        releaseNotes
    }
`;


export type RequirementsRelease = Omit<requirementsReleaseFields, '__typename'>;
