import './areaComplianceCard.scss';

import * as React from 'react';
import { RequirementArea } from '../../api/main/models/RequirementArea';
import { Card, CardBody, Row, Col, CardTitle, ListGroup, ListGroupItem, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { School } from '../../api/main/models/School';
import { User } from '../../api/main/models/User';
import { ComplianceProgressBar } from '../schoolCompliance/ComplianceProgressBar';
import { RequirementsReleaseRequirement } from '../../api/main/models/RequirementsReleaseRequirement';
import { Requirement } from '../../api/main/models/Requirement';
import { SchoolRequirementCompliance } from '../../api/main/models/SchoolRequirementCompliance';
import { getComplianceAreaUrl } from '../../utilities/getComplianceAreaUrl';
import { RequirementComplianceCutdownItem } from './RequirementComplianceCutdownItem';
import { ModelArrayChanges } from '../../shared/useChanges';
import { Action } from '../../api/main/models/Action';
import { RenderIfVisible } from "../../shared/useIsElementVisible";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface AreaComplianceCardProps {
    area: RequirementArea,
    user: User | undefined | null,
    school: School | undefined | null,
    requirements: Array<Requirement> | undefined | null,
    requirementLinks: Array<RequirementsReleaseRequirement> | undefined | null,
    compliances: Array<SchoolRequirementCompliance> | undefined | null,

    changeCompliance: (requirement: Requirement, changes: Partial<SchoolRequirementCompliance>) => void,
    addOrUpdateAction: (requirement: Requirement, reason: 'userAdded' | 'review' | 'failed' | 'delete', isAutoAction: boolean, date?: string) => void,
    actionsManager: ModelArrayChanges<Action, string>,
    saveFormDebounce: () => void,
    //videos: Array<schoolOverviewSupportingDataQuery_videos>,

    isOpen: boolean,
    toggle: () => void,

    shouldShowRequirement: (requirement: Requirement, compliance: SchoolRequirementCompliance | undefined | null) => boolean,
    resetFilter: () => void,
    isFiltered: boolean,
}

/**
 * Card component that lists all area and a schools compliance to it.
 * @param props
 */
export const AreaComplianceCard = (props: AreaComplianceCardProps) => {
    const {
        user, school, area,
        requirements, compliances,
        changeCompliance, addOrUpdateAction,
        actionsManager,
        saveFormDebounce,
        //videos,
        isOpen, toggle,
        shouldShowRequirement,
        resetFilter,
        isFiltered,
    } = props;

    const { t } = useTranslation();

    // Get a list of visible requirements after applying the filtering.
    const filteredRequirements = React.useMemo(() => requirements?.filter(requirement => {
        // Get the compliance state (if any) for this requirement.
        const compliance = compliances?.find(it => it.requirementOriginKey === requirement.originKey);

        // Check if we should display this item.
        const shouldShow = shouldShowRequirement(requirement, compliance);
        if (!shouldShow) {
            return false;
        }

        return true;
    }), [requirements, compliances, shouldShowRequirement]);
    const hasNoRequirementsToShow = !filteredRequirements?.length && !!requirements?.length;

    return (
        <Card className="area-compliance-card">
            <CardBody tag="div">
                <CardTitle onClick={toggle}>
                    <Row>
                        <Col>
                            <Link to={getComplianceAreaUrl(area.id, user, school?.id)} onClick={(e) => e.stopPropagation() /* Prevent toggle() being called. */}>
                                <h5 className="area-compliance-card-heading">
                                    {area.name}
                                </h5>
                            </Link>
                            <div>
                                <Link to={getComplianceAreaUrl(area.id, user, school?.id)} onClick={(e) => e.stopPropagation() /* Prevent toggle() being called. */}>
                                    {t('areaComplianceCard.viewLink', 'View {{name}} requirements in detail', { name: area?.name })}
                                </Link>
                            </div>
                        </Col>
                        <Col>
                            <RenderIfVisible>
                                <ComplianceProgressBar requirements={requirements} compliances={compliances} />
                            </RenderIfVisible>

                            <Row>
                                <Col></Col>
                                <Col xs="auto" style={{ cursor: 'pointer' }}>
                                    {
                                        isOpen ? (<>{t('areaComplianceCard.toggle.collapse', 'Collapse {{ name }}', { name: area.name })}</>)
                                            : isFiltered ? (<span className={!!filteredRequirements?.length ? 'area-compliance-card-expand-message-with-matches' : 'area-compliance-card-expand-message-without-matches'}>{t('areaComplianceCard.toggle.expand.filtered', 'Expand {{name}}', { name: area.name, count: (filteredRequirements?.length || 0), total: (requirements?.length || 0), })}</span>)
                                                : (<>{t('areaComplianceCard.toggle.expand.unfiltered', 'Expand {{name}}', { name: area.name, count: (filteredRequirements?.length || 0), })}</>)
                                    }
                                    <> </>
                                    <FontAwesomeIcon icon={isOpen ? 'caret-up' : 'caret-down'} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardTitle>

                {
                    isOpen ? (
                        <ListGroup flush>
                            <RenderIfVisible fallback={<div style={{ minHeight: `${64 * (filteredRequirements?.length ?? 0)}px` }}></div>}>
                                {
                                    filteredRequirements?.map(item => {
                                        // Get the compliance state (if any) for this requirement.
                                        const compliance = compliances?.find(it => it.requirementOriginKey === item.originKey);

                                        // Render the item.
                                        return (
                                            <RequirementComplianceCutdownItem
                                                key={item.id}
                                                requirement={item}
                                                areaId={area.id}
                                                schoolCompliance={compliance}
                                                changeCompliance={(changes) => changeCompliance(item, changes)}
                                                saveComplianceDebounce={saveFormDebounce}
                                                action={actionsManager.model.find(it => it.requirementOriginKey === item.originKey && /*it.isAutomaticFromRequirementCompliance === isAutoAction &&*/ !it.archived && !it.completedDate)}
                                                addOrUpdateAction={(reason, isAutoAction, date) => addOrUpdateAction(item, reason, isAutoAction, date)}
                                                user={user} school={school}
                                            />
                                        );
                                    })
                                }
                            </RenderIfVisible>

                            {
                                hasNoRequirementsToShow ? (
                                    <ListGroupItem className="text-muted text-center">
                                        {t('areaComplianceCard.nothingToShow.before', 'There are no requirements in {{name}} that meet your filter.', { name: area.name, })}
                                        <> </>
                                        <Button color="link" style={{ paddingLeft: '0px', }} onClick={resetFilter}>
                                            {t('schoolOverview.filter.reset', 'Reset filters')}
                                        </Button>
                                        <> </>
                                        {t('areaComplianceCard.nothingToShow.after', '', { name: area.name, })}
                                    </ListGroupItem>
                                ): null
                            }
                        </ListGroup>
                    ): null
                }
            </CardBody>
        </Card>
        );
};
