/**
 * Constants for the available roles.
 */
export const IdentityRoles = {
    SchoolUser: 'SchoolUser',
    MATUser: 'MATUser',
    Administration: 'Administration',

    // Role added to schoolBusUserRoles if the user has IsMaster set to true.
    IsMaster: 'IsMaster',

    // Special role that is used to restrict access within schools so only policy managers of the school (along with the master user above) can access this app.
    SB_Policy_Manager: 'SB_Policy_Manager',
};

///**
// * Constants (enum) for the available Roles.
// */
//export enum IdentityRoles {
//    Administration = 'Administration'
//}
