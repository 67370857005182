import * as React from 'react';
import { SchoolPhaseCreateInput, SchoolPhaseUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSchoolPhaseCallback } from './useCreateSchoolPhaseCallback';
import { useUpdateSchoolPhaseCallback } from './useUpdateSchoolPhaseCallback';
import { SchoolPhase } from '../models/SchoolPhase';

/**
 * Get a callback to save a SchoolPhase in the store using either a create or update.
 */
export function useSaveSchoolPhaseCallback(options: SaveInStoreOptions<SchoolPhase, string> = {}) {
    const [_create, createStatus] = useCreateSchoolPhaseCallback();
    const create = React.useCallback((model: Partial<SchoolPhase>) => _create(model as SchoolPhaseCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSchoolPhaseCallback();
    const update = React.useCallback((id: string, changes: Partial<SchoolPhase>) => _update(id, changes as SchoolPhaseUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
