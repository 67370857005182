import * as React from 'react'
import { LoginWaiting } from './LoginWaiting';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import authService, { AuthenticationResultStatus } from '../../api/api-authorization/AuthorizeService';
import { apiAuthorizationConfig } from '../../configure/apiAuthorizationConfig';
import { Redirect } from 'react-router';

export interface LoginProps {
    action: string,
}

export interface LoginState {
    message: string | undefined | null,
    redirectToUrl: string | undefined | null,
}

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);

        this.state = {
            message: undefined,
            redirectToUrl: undefined,
        };
    }

    componentDidMount() {
        const action = this.props.action;
        switch (action) {
            case LoginActions.Login:
                this.login(this.getReturnUrl());
                break;
            case LoginActions.LoginFailed:
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                this.setState({ message: error });
                break;
            case LoginActions.Profile:
                this.redirectToProfile();
                break;
            case LoginActions.Register:
                this.redirectToRegister();
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    render() {
        const action = this.props.action;
        const { message } = this.state;

        // Handle react style redirection to the return URL rather than full page redirection.
        if (this.state.redirectToUrl) {
            return (<Redirect to={this.state.redirectToUrl} />);
        }

        // Render based on the requested action.
        switch (action) {
            case LoginActions.Login:
                return (<LoginWaiting waiting message={message || "Processing login"} />);
            case LoginActions.Profile:
            case LoginActions.Register:
                return (<LoginWaiting waiting message={message || "Loading"} />);
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    async login(returnUrl: string) {
        const state = { returnUrl };
        const result = await authService.signIn(state);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                if (apiAuthorizationConfig.allowDeveloperLogin) {
                    window.location.href = apiAuthorizationConfig.developerLoginPageRedirectUrl;
                } else {
                    window.location.href = apiAuthorizationConfig.loginPageRedirectUrl;
                }
                break;
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(returnUrl);
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    }


    getReturnUrl(state?: any) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(ApplicationPaths.Login)}`);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath: string) {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    navigateToReturnUrl(returnUrl: string) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);        

        //this.setState({ redirectToUrl: returnUrl?.replace(window.location.origin, '') ?? '/' });
    }
}
