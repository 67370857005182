import gql from "graphql-tag";
import { schoolTypeFields } from "../generated/schoolTypeFields";

export const schoolTypeFieldsFragment = gql`
    fragment schoolTypeFields on SchoolType {
        id
        name
        archived
        displayOrder
    }
`;


export type SchoolType = Omit<schoolTypeFields, '__typename'>;
