import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Row, Col, Input, Alert, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchoolReport } from '../../api/main/models/SchoolReport';
import moment from 'moment';
import { useAsyncCallback } from 'react-use-async-callback';
import { useShareSchoolReportCallback } from '../../api/main/schoolReports/useShareSchoolReportCallback';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ButtonAsync } from 'reactstrap-buttonasync';

export interface ShareLinkModalProps {
    isOpen: boolean,
    toggle: () => void,
    model: SchoolReport,
}

/**
 * Modal to let the share a link to a report (that does not require authorizatio to view).
 * @param props
 */
export const ShareLinkModal = (props: ShareLinkModalProps) => {
    const {
        isOpen, toggle,
        model,
    } = props;
    const { t } = useTranslation();

    const [email, setEmail] = React.useState<string>('');

    // Send an email to share the report.
    const [emailSentTo, setEmailSentTo] = React.useState<string>('');
    const [shareReport, { errors: shareReportErrors }] = useShareSchoolReportCallback();
    const [sendEmail, { isExecuting: isSendingEmail, errors: sendEmailErrors }] = useAsyncCallback(async () => {
        // Do nothing if they haven't typed an email.
        if (!email) {
            return;
        }

        // Send the email.
        await shareReport(model.id, email);

        // Set the last person we sent an email to.
        setEmailSentTo(email);
    }, [shareReport, model, email, setEmailSentTo]);
    
    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle} modalClassName="root-app-modal">
            <ModalHeader toggle={toggle}>
                {
                    model.isDetailed ? t('reportHistoryCard.detailedReportName', 'Share deep dive report from {{date, L LT}}', { date: moment(model.date) })
                        : t('reportHistoryCard.detailedReportName', 'Share summary report from {{date, L LT}}', { date: moment(model.date) })
                }     
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={[shareReportErrors, sendEmailErrors]} />

                <ConditionalFragment showIf={!!emailSentTo}>
                    <Alert color="success">
                        {t('shareLinkModel.emailSent', 'An email has been sent to {{email}} with a link to the report.', { email: email })}
                    </Alert>
                </ConditionalFragment>

                <FormGroup>
                    <Label htmlFor="email">{t('shareLinkModal.email', 'Who would you like to send the link to?')}</Label>
                    <Row>
                        <Col>
                            <Input type="email" placeholder="someone@email.com" value={email} onChange={e => setEmail(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <ButtonAsync color="primary"
                                isExecuting={isSendingEmail} onClick={() => sendEmail()}
                                executingChildren={<><Spinner size="sm" /><> </>{t('shareLinkModal.sending', 'Sending...')}</>}
                                >
                                <FontAwesomeIcon icon="paper-plane" />
                                <> </>
                                {t('shareLinkModal.sendEmail', 'Send link via email')}
                            </ButtonAsync>
                        </Col>
                    </Row>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" outline onClick={toggle}>
                    {t('common.close', 'Close')}
                </Button>
            </ModalFooter>
        </Modal>
        );
};
