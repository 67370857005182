import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ActionUserTaskCreateInput } from '../generated/globalTypes';
import { createActionUserTaskMutation, createActionUserTaskMutationVariables } from '../generated/createActionUserTaskMutation';
import { actionUserTaskFieldsFragment } from '../models/ActionUserTask';

/**
 * Get a callback to create a ActionUserTask in the store.
 */
export function useCreateActionUserTaskCallback(): [(model: ActionUserTaskCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createActionUserTaskMutation, createActionUserTaskMutationVariables>(
        gql`
            mutation createActionUserTaskMutation ($model: ActionUserTaskCreateInput!) {
                createActionUserTask(model: $model) {
                    ...actionUserTaskFields
                }
            }

            ${actionUserTaskFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ActionUserTaskCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
