import { RouteEntry } from "../../shared/routes";
import { MATOverview } from "./MATOverview";
import { MATNavigation } from "./MATNavigation";

export const matOverviewRoutes: Array<RouteEntry> = [
    { path: '/mat-overview', exact: true, component: MATOverview, authorize: true },
    { path: '/mat-overview/:schoolGroupId', exact: true, component: MATOverview, authorize: true },
];

export const matSidebarRoutes: Array<RouteEntry> = [
    { path: '/mat-overview', component: MATNavigation, authorize: true },
];
