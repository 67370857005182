import './adminReportsSchoolSummaryCard.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col, CardHeader, Collapse, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToggleState } from 'use-toggle-state';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LinkContainer } from 'react-router-bootstrap';
import { AreaCertificateThumbnail } from '../certificates/AreaCertificateThumbnail';

export interface AdminReportsSchoolSummaryCardProps {
    schoolId: string,
    schoolName: string,

    awardLevel: string | null | undefined,

    startOpen?: boolean,
}

/**
 * Card component that lists all area and a schools compliance to it.
 * @param props
 */
export const AdminReportsSchoolSummaryCard = (props: AdminReportsSchoolSummaryCardProps) => {
    const {
        schoolId,
        schoolName,
        awardLevel,
        startOpen,
    } = props;
    const { t } = useTranslation();

    // Allow ourselves to be collapsed.
    const [isOpen, toggleOpen] = useToggleState(startOpen ?? false);

    return (
        <Card className="admin-reports-school-summary-card">
            <CardHeader className="admin-reports-school-summary-card-header" style={{ cursor: 'pointer' }} onClick={() => toggleOpen()}>
                <Row>
                    <Col>
                        {schoolName}
                    </Col>
                    <Col xs="auto">
                        <AreaCertificateThumbnail awardLevel={awardLevel ?? ''} />
                    </Col>
                    <Col xs="auto">
                        <FontAwesomeIcon icon={isOpen ? 'caret-up' : 'caret-down'} />
                        <span className="sr-only">{t('common.seeMore', 'See more')}</span>
                    </Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <ConditionalFragment showIf={isOpen}>
                    <CardBody tag="div">
                        <Row>
                            <Col xs={12} md={4}>
                                <LinkContainer to={`/overview/${schoolId}`}>
                                    <Button color="primary" outline className="admin-reports-school-summary-action-button">
                                        <FontAwesomeIcon icon="book-reader" />
                                        <> </>{t('schoolSummaryCard.viewSchoolCompliance', 'View compliance details')}
                                    </Button>
                                </LinkContainer>
                            </Col>
                            <Col xs={12} md={4}>
                                <LinkContainer to={`/action-plan/${schoolId}`}>
                                    <Button color="primary" outline className="admin-reports-school-summary-action-button">
                                        <FontAwesomeIcon icon="tasks" />
                                        <> </>{t('schoolSummaryCard.viewSchoolActions', 'View action plan')}
                                    </Button>
                                </LinkContainer>
                            </Col>
                            <Col xs={12} md={4}>
                                <LinkContainer to={`/awards/${schoolId}`}>
                                    <Button color="primary" outline className="admin-reports-school-summary-action-button">
                                        <FontAwesomeIcon icon="award" />
                                        <> </>{t('schoolSummaryCard.viewSchoolActions', 'View awards')}
                                    </Button>
                                </LinkContainer>
                            </Col>
                        </Row>
                    </CardBody>
                </ConditionalFragment>
            </Collapse>
        </Card>
        );
};
