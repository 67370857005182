import * as React from 'react';
import { Spinner } from 'reactstrap';

export interface LoadingIndicatorProps {
    className?: string,
    fullWidth?: boolean,
    size?: 'sm' | 'md' | 'lg',
    inline?: boolean,
}

/**
 * Simple spinner that can be shown during loading.
 */
export const LoadingIndicator = (props: LoadingIndicatorProps) => {
    const { className, fullWidth, size, inline } = props;

    const style = React.useMemo(() => {
        let ret: React.CSSProperties = {};
        if (fullWidth) {
            ret.width = '100%';
            ret.textAlign = 'center';
        }

        if (inline) {
            ret.display = 'inline';
        }

        return ret;
    }, [inline, fullWidth])

    return (
        <div className={'loading-indicator ' + (className ? className : '')} style={style}>
            <Spinner type="grow" size={size} />
            <span className="sr-only">Loading</span>
        </div>
    );
};

