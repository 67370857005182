import * as React from 'react';
import { useLocation } from "react-router";

/**
 * Hook to get the URLSearchParams from the current or passed in location.
 * @param search
 */
export function useURLSearchParams(search?: string) {
    const location = useLocation();
    return React.useMemo(() => new URLSearchParams(search ?? location?.search ?? ''), [search, location]);
}