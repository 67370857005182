import * as React from "react";
import { Requirement } from '../../../api/main/models/Requirement';
import { SchoolRequirementCompliance } from '../../../api/main/models/SchoolRequirementCompliance';
import { StandardRating } from '../../../api/main/models/constants/StandardRatings';
import { ComplianceProgressCalculator } from '../../../services/complianceProgressCalculator/ComplianceProgressCalculator';
import { ComplianceProgressResult } from "../../../services/complianceProgressCalculator/ComplianceProgressResult";
import { RequirementsReleaseRequirement } from "../../../api/main/models/RequirementsReleaseRequirement";

export interface CurrentAward {
    currentLevel: StandardRating | null,
    bronzeCompliance: ComplianceProgressResult,
    silverCompliance: ComplianceProgressResult,
    goldCompliance: ComplianceProgressResult,

    areas: Array<CurrentAwardArea> | undefined | null,
}

export interface CurrentAwardArea {
    requirementAreaId: string,
    currentLevel: StandardRating | null,
    bronzeCompliance: ComplianceProgressResult,
    silverCompliance: ComplianceProgressResult,
    goldCompliance: ComplianceProgressResult,
}

/**
 * Returns the current award for a school along with bronze, silver and gold breakdown.
 */
export function useCalculateCurrentAward(requirements: Array<Requirement> | null | undefined, compliances: Array<SchoolRequirementCompliance> | null | undefined, requirementsReleaseRequirements: Array<RequirementsReleaseRequirement> | null | undefined) {
    const award = React.useMemo(() => {
        return calculateCurrentAward(requirements, compliances, requirementsReleaseRequirements);
    }, [requirements, compliances, requirementsReleaseRequirements]);

    return award;
}

/**
 * Non hook version of useCalculateCurrentAward().
 * @param requirements
 * @param compliances
 */
export function calculateCurrentAward(requirements: Array<Requirement> | null | undefined, compliances: Array<SchoolRequirementCompliance> | null | undefined, requirementsReleaseRequirements: Array<RequirementsReleaseRequirement> | null | undefined) {
    const calculator = new ComplianceProgressCalculator(requirements ?? [], compliances ?? []);

    const bronzeCompliance = calculator.calculate(StandardRating.Bronze);
    const silverCompliance = calculator.calculate(StandardRating.Silver);
    const goldCompliance = calculator.calculate(StandardRating.Gold);

    // Work out the level the school is currently at.
    //

    // Start with no level awarded.
    let level: StandardRating | null = null;

    // If there are no requirements (usually because data is still loading), we don't assign out any awards.
    if (!!requirements?.length) {
        // We need to have passed all the bronze questions to get bronze.
        if (bronzeCompliance.totals.notSkipped > 0 && bronzeCompliance.totals.outstanding <= 0) {
            level = StandardRating.Bronze;

            // We need to have bronze, at least one silver question available, and passed all the silver answers to get silver.
            if (silverCompliance.totals.notSkipped > 0 && silverCompliance.totals.outstanding <= 0) {
                level = StandardRating.Silver;

                // We need to have silver, at least one gold question available, and passed at least 90% of all the gold answers to get gold.  This
                // is a special rule just for gold, everything else needs 100%.
                if (goldCompliance.totals.notSkipped > 0 && (goldCompliance.success / goldCompliance.totals.notSkipped) >= 0.9) {
                    level = StandardRating.Gold;
                }
            }
        }   
    }

    // Work out the compliance per area as well.
    let areaIds: Array<string> = [];
    for (const item of requirementsReleaseRequirements ?? []) {
        if (!!areaIds.find(it => it === item.requirementAreaId)) {
            continue;
        }

        areaIds.push(item.requirementAreaId);
    }

    let areaResults: Array<CurrentAwardArea> = [];
    for (const areaId of areaIds) {
        const myRequirements = requirements?.filter(requirement => !!requirementsReleaseRequirements?.find(link => link.requirementAreaId === areaId && link.requirementId === requirement.id));

        const myResults = calculateCurrentAward(myRequirements, compliances, null /* Ensures we don't try do areas again */);
        areaResults.push({
            requirementAreaId: areaId,
            ...myResults,
        });
    }

    return {
        currentLevel: level,
        bronzeCompliance,
        silverCompliance,
        goldCompliance,
        areas: areaResults,
    } as CurrentAward;
}
