import './generateCard.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
//import ProgressBar from 'react-customizable-progressbar';
import { ComplianceProgressCalculator } from '../../services/complianceProgressCalculator/ComplianceProgressCalculator';
import { Requirement } from '../../api/main/models/Requirement';
import { SchoolRequirementCompliance } from '../../api/main/models/SchoolRequirementCompliance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RequirementArea } from '../../api/main/models/RequirementArea';
import { RequirementsReleaseRequirement } from '../../api/main/models/RequirementsReleaseRequirement';
import { getComplianceAreaUrl } from '../../utilities/getComplianceAreaUrl';
import { Link } from 'react-router-dom';
import { ComplianceProgressBar } from '../schoolCompliance/ComplianceProgressBar';
import { HtmlDisplay } from '../../shared/htmlEditor/HtmlDisplay';
import { School } from '../../api/main/models/School';
import { User } from '../../api/main/models/User';
import { RoundedButtonGroup } from '../shared/RoundedButtonGroup';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../shared/LoadingIndicator';


export interface GenerateCardProps {
    user: User | undefined | null,
    school: School | undefined | null,

    areas: Array<RequirementArea> | undefined | null,
    requirements: Array<Requirement> | undefined | null,
    requirementLinks: Array<RequirementsReleaseRequirement> | undefined | null,
    compliances: Array<SchoolRequirementCompliance> | undefined | null,

    generateReport: () => void,
    isGeneratingReport: boolean,
    isLoading: boolean,

    isDetailedReport: boolean,
    toggleDetailedReport: (value?: boolean) => void,

    isAreaExcluded: (id: string) => boolean,
    toggleAreaExcluded: (id: string, value?: boolean) => void,
}

/**
 * Card component that lists all area and a schools compliance to it.
 * @param props
 */
export const GenerateCard = (props: GenerateCardProps) => {
    const {
        user,
        school,
        areas,
        requirements: allRequirements,
        requirementLinks: allRequirementLinks,
        compliances,
        generateReport, isGeneratingReport,
        isLoading,
        isDetailedReport, toggleDetailedReport,
        isAreaExcluded, toggleAreaExcluded,
    } = props;
    const { t } = useTranslation();

    // Work out requirements based only on those areas that are not excluded.
    const { requirements, requirementLinks } = React.useMemo(() => {
        if (!allRequirements || !allRequirementLinks) {
            return { requirements: allRequirements, requirementLinks: allRequirementLinks };
        }

        const includedLinks = allRequirementLinks.filter(item => !isAreaExcluded(item.requirementAreaId));
        const includedRequirements = allRequirements.filter(item => includedLinks.find(it => it.requirementId === item.id));

        return {
            requirements: includedRequirements,
            requirementLinks: includedLinks,
        };
    }, [allRequirements, allRequirementLinks, isAreaExcluded]);

    // Work out our overall compliance across all areas for our progress bar.
    const { result: compliance, percentageSuccess } = React.useMemo(() => {
        const calculator = new ComplianceProgressCalculator(requirements ?? [], compliances ?? []);
        const result = calculator.calculate();
        const totalSuccessOrFailed = result.success + result.failed;
        return {
            result,
            percentageSuccess: totalSuccessOrFailed === 0 ? 0 : (result.success / totalSuccessOrFailed) * 100,
        }
    }, [requirements, compliances]);

    // Need a count of areas included on the report.
    const includedAreasCount = React.useMemo(() => {
        if (!areas) {
            return 0;
        }

        let ret = 0;
        for (const item of areas) {
            const myLinks = requirementLinks?.filter(it => it.requirementAreaId === item.id);
            const myRequirements = requirements?.filter(it => !!myLinks?.find(link => link.requirementId === it.id));
            const myCompliances = compliances?.filter(it => !!myRequirements?.find(req => req.originKey === it.requirementOriginKey));

            if (myCompliances?.length) {
                ret++;
            }
        }

        return ret;
    }, [requirementLinks, requirements, compliances, areas]);

    return (
        <Card className="generate-card">
            <CardBody tag="div">
                <CardTitle>
                    <h5>
                        <FontAwesomeIcon icon="print" />
                        <> </>
                        {t('generateCard.heading', 'Generate a compliance report')}
                    </h5>
                </CardTitle>

                <div className="generate-card-help-container">
                    <p>
                        {t('', 'Use the options below to select the style of report you want and the areas you want to include.  Once generated we\'ll save a copy of the report for you so you can share it or come back to it whenever you want.')}
                    </p>
                </div>

                <div className="generate-card-type-container">
                    <h6 className="generate-card-sub-heading">
                        {t('generateCard.whatTypeHeading', 'What type of report do you want to generate?')}
                    </h6>

                    <RoundedButtonGroup style={{ width: '100%' }}>
                        <Button color="secondary"
                            outline={isDetailedReport}
                            onClick={() => toggleDetailedReport(false)}>
                            <div style={{ fontSize: '3rem' }}>
                                <FontAwesomeIcon icon="briefcase" />
                            </div>
                            {t('generateCard.type.summary', 'Summary report with overview details on a single page')}
                        </Button>
                        <Button color="secondary"
                            outline={!isDetailedReport}
                            onClick={() => toggleDetailedReport(true)}>
                            <div style={{ fontSize: '3rem' }}>
                                <FontAwesomeIcon icon="list" />
                            </div>
                            {t('generateCard.type.detailed', 'Deep dive report with each area\'s compliance broken down for you')}
                        </Button>
                    </RoundedButtonGroup>
                </div>
                <div className="generate-card-areas-container">
                    <h6 className="generate-card-sub-heading">
                        {t('generateCard.whichAreasHeading', 'Which areas would you like to include on the report?')}
                    </h6>
                    {
                        areas?.map(item => {
                            const myLinks = requirementLinks?.filter(it => it.requirementAreaId === item.id);
                            const myRequirements = requirements?.filter(it => !!myLinks?.find(link => link.requirementId === it.id));
                            const myCompliances = compliances?.filter(it => !!myRequirements?.find(req => req.originKey === it.requirementOriginKey));

                            // Work out if we have any responses at all for this area.
                            const allMyLinks = allRequirementLinks?.filter(it => it.requirementAreaId === item.id);
                            const allMyRequirements = allRequirements?.filter(it => !!allMyLinks?.find(link => link.requirementId === it.id));
                            const allMyCompliances = compliances?.filter(it => !!allMyRequirements?.find(req => req.originKey === it.requirementOriginKey));
                            const hasAnyReponses = !!allMyCompliances?.length;

                            return (
                                <div key={item.id} className="area-compliance-card-area">
                                    <Row>
                                        <Col>
                                            <strong>
                                                <Link to={getComplianceAreaUrl(item.id, user, school?.id)}>
                                                    {item.name}
                                                </Link>
                                            </strong>
                                        </Col>
                                        <Col>
                                            {
                                                !hasAnyReponses ? (
                                                    <span className="text-muted">
                                                        {t('generateCard.areaIsEmpty', 'You have not responded to any requirements for this area so it can\'t be included on the report')}
                                                    </span>
                                                ) : isAreaExcluded(item.id) ? (
                                                        <span className="text-muted">
                                                            {t('generateCard.areaIsExcludedFromReport', 'This area has been excluded from the report')}
                                                        </span>
                                                    ) : (
                                                            <ComplianceProgressBar requirements={myRequirements} compliances={myCompliances} />
                                                            )
                                            }
                                        </Col>
                                        <Col xs={12} md="auto">
                                            <RoundedButtonGroup>
                                                <Button color={hasAnyReponses ? 'secondary' : 'dark'}
                                                    outline={!hasAnyReponses || isAreaExcluded(item.id)}
                                                    disabled={!hasAnyReponses}
                                                    onClick={() => toggleAreaExcluded(item.id, false)}>
                                                    {t('generateCard.area.include', 'Include on report')}
                                                </Button>
                                                <Button color={hasAnyReponses ? 'secondary' : 'dark'}
                                                    outline={hasAnyReponses && !isAreaExcluded(item.id)}
                                                    disabled={!hasAnyReponses}
                                                    onClick={() => toggleAreaExcluded(item.id, true)}>
                                                    {t('generateCard.area.exclude', 'Exclude from report')}
                                                </Button>
                                            </RoundedButtonGroup>
                                        </Col>
                                    </Row>
                                    <div className="text-muted">
                                        <HtmlDisplay sanatizedHtml={item.descriptionHtml} />
                                    </div>
                                </div>
                            );
                        })
                    }

                    <ConditionalFragment showIf={!areas?.length && isLoading}>
                        <LoadingIndicator fullWidth />
                    </ConditionalFragment>
                </div>


                <div className="generate-card-progress-container">
                    <Row>
                        {/*<Col xs={12} md="auto">
                            <ProgressBar progress={Math.round(percentageSuccess)}
                                strokeWidth={10}
                                trackStrokeWidth={10}
                                radius={30}
                                cut={60}
                                rotate={120}
                                strokeColor="#5c62cd">
                                <div className="generate-card-progress-label">
                                    <div>{t('common.percentageInteger', '{{value, #,0}}%', { value: Math.round(percentageSuccess) })}</div>
                                </div>
                            </ProgressBar>
                        </Col>*/}
                        <Col>
                            <p className="text-center">
                                <strong>
                                    {t(
                                        'generateCard.progressDescription',
                                        'Your report show your overall standard and include details of {{includedAreasCount, #,0}} selected areas.',
                                        {
                                            successPercentage: Math.round(percentageSuccess),
                                            answeredCount: compliance.success + compliance.failed,
                                            includedAreasCount: includedAreasCount,
                                        }
                                    )}
                                </strong>
                            </p>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col>
                    </Col>
                    <Col xs="auto">
                        <ButtonAsync disabled={isLoading} color="primary" isExecuting={isGeneratingReport} onClick={() => generateReport()}
                            executingChildren={<><Spinner size="sm" /><> </>{t('generateCard.generatingReport', 'Generating report...')}</>}
                            >
                            <FontAwesomeIcon icon="magic" />
                            <> </>
                            {
                                isDetailedReport ? t('generateCard.generateDetailedReport', 'Generate deep dive report')
                                    : t('generateCard.generateSummaryReport', 'Generate summary report')
                            }
                        </ButtonAsync>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        );
};
