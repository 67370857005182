import * as React from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';

export interface DueDateLabelProps {
    date: string | Moment | Date,
}

/**
 * Component that shows a Due Date with appropriate colouring.
 * @param props
 */
export const DueDateLabel = (props: DueDateLabelProps) => {
    const { date } = props;
    const dateMoment: Moment = moment(date);
    const differenceDays = dateMoment.diff(moment(), 'days');

    const { t } = useTranslation();
    
    let color = 'dark';
    if (differenceDays <= 0) {
        color = 'danger';
    } else if (differenceDays <= 30) {
        color = 'warning';
    }

    return (
        <Badge color={color}>
            {t('dueDateLabel.dueDate', 'Due: {{date, L}}', { date: dateMoment })}
        </Badge>
    );
};
