import { CacheState, storeInCache, removeFromCache, raiseExpireEvents } from '../../reduxStore/cache';
import { AnyAction, Dispatch } from "redux";
import { CacheOptions } from './CacheOptions';

/**
 * Cache service that uses the actions redux store and actions to store the cache in redux.
 */
export class CacheService {
    private getCacheState: () => CacheState;
    private dispatch: Dispatch<AnyAction>;

    constructor(getCacheState: () => CacheState, dispatch: Dispatch<AnyAction>) {
        this.getCacheState = getCacheState;
        this.dispatch = dispatch;
    }

    readFromCache<T = any>(id: string): T | null {
        const cachedItem = this.getCacheState().items[id];
        if (!cachedItem) {
            return null;
        }

        // If this item has expired, remove it and return null.
        if (cachedItem.expireAt && cachedItem.expireAt < new Date()) {
            this.removeFromCache(id);
        }

        return cachedItem.data;
    }

    storeInCache(id: string, data: any, options?: CacheOptions): void {
        const expireAt = options ? options.expireAt : undefined;
        const expireOnEvents = options ? options.expireOnEvents ? options.expireOnEvents : [] : [];
        this.dispatch(storeInCache({ id, data, expireAt, expireOnEvents }));
    }

    removeFromCache(id: string): void {
        this.dispatch(removeFromCache({ id }));
    }

    raiseExpireEvents(...events: Array<string>): void {
        this.dispatch(raiseExpireEvents({ events }));
    }
}
