import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { SchoolTypeCreateInput } from '../generated/globalTypes';
import { createSchoolTypeMutation, createSchoolTypeMutationVariables } from '../generated/createSchoolTypeMutation';
import { schoolTypeFieldsFragment } from '../models/SchoolType';

/**
 * Get a callback to create a SchoolType in the store.
 */
export function useCreateSchoolTypeCallback(): [(model: SchoolTypeCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createSchoolTypeMutation, createSchoolTypeMutationVariables>(
        gql`
            mutation createSchoolTypeMutation ($model: SchoolTypeCreateInput!) {
                createSchoolType(model: $model) {
                    ...schoolTypeFields
                }
            }

            ${schoolTypeFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: SchoolTypeCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
