import gql from "graphql-tag";
import { schoolRequirementComplianceFields } from "../generated/schoolRequirementComplianceFields";

export const schoolRequirementComplianceFieldsFragment = gql`
    fragment schoolRequirementComplianceFields on SchoolRequirementCompliance {
        id
        schoolId
        requirementOriginKey
        date
        requirementComplianceState
        isImportFromPolicyManager
        archived
        notesHtml
    }
`;


export type SchoolRequirementCompliance = Omit<schoolRequirementComplianceFields, '__typename'>;
