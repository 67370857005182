import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import { CacheState, cacheReducer } from './cache';
import { persistConfig } from "./persistConfig";

export interface AppReduxStore {
    cache: CacheState
}

export const rootReducer = combineReducers<AppReduxStore>({
    cache: cacheReducer
});

export const persistedRootReducer = persistReducer(
    persistConfig,
    rootReducer
);

/**
 * Create and configure a redux store for the app.
 */
export const createAppReduxStore = () => {
    var windowAny = window as any;
    const preloadedState = windowAny.initialReduxState;

    const store = configureStore({
        reducer: persistedRootReducer,
        preloadedState: preloadedState ?? {},
        middleware: []
    });

    // Persist to locaal storage if needed.
    const persistor = persistStore(store);

    //if (process.env.NODE_ENV !== 'production' && module.hot) {
    //    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
    //}

    return { store, persistor };
};
