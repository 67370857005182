import * as React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { TFunction } from 'i18next';
import { Action } from "../../api/main/models/Action";
import { ActionUserTask } from "../../api/main/models/ActionUserTask";
import { School } from "../../api/main/models/School";
import moment from 'moment';

export interface ActionsePdfProps {
    school: School,
    actions: Array<Action>,
    actionUserTasks: Array<ActionUserTask>,
    schoolUsers: Array<{ id: string, firstname: string, surname: string, }>,
    t: TFunction,
}

/**
 * PDF of a school's actions (excluding completed).
 */
export const ActionsPdf = (props: ActionsePdfProps) => {
    const {
        school,
        actions,
        actionUserTasks,
        schoolUsers,
        t,
    } = props;

    
    // Build the actual report.
    return (
        <Document>
            <Page size="A4" style={Styles.page}>
                <View style={Styles.body}>
                    <View>
                        <Text style={Styles.h1}>
                            <Text>
                                {school.schoolName}
                            </Text>
                        </Text>
                        <Text style={{ ...Styles.h1Small }}>
                            {t('actionsPdf.title', 'Action plan')}
                        </Text>
                    </View>

                    <View style={Styles.table}>
                        <View style={Styles.thead}>
                            <View style={Styles.trHead}>
                                <View style={{ ...Styles.th, width: '60%' }}>
                                    <Text>{t('actionsPdf.headers.action', 'Action')}</Text>
                                </View>
                                <View style={{ ...Styles.th, width: '20%' }}>
                                    <Text>{t('actionsPdf.headers.dueDate', 'Due date')}</Text>
                                </View>
                                <View style={{ ...Styles.th, width: '20%' }}>
                                    <Text>{t('actionsPdf.headers.assignedTo', 'Assigned to')}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={Styles.tbody}>
                            {
                                actions?.map(action => {
                                    const myTask = actionUserTasks.find(it => it.actionId === action.id);
                                    const myUser = schoolUsers.find(it => it.id === myTask?.userId?.toString() ?? '');

                                    return (
                                        <View key={action.id} style={Styles.tr} wrap={false}>
                                            <View style={{ ...Styles.td, width: '60%' }}>
                                                <Text>{action.name}</Text>
                                            </View>
                                            <View style={{ ...Styles.td, width: '20%' }}>
                                                <Text>{t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(action.actionAddedDate), })}</Text>
                                            </View>
                                            <View style={{ ...Styles.td, width: '20%' }}>
                                                <Text>{t('common.fullName', '{{firstname}} {{surname}}', { firstname: myUser?.firstname, surname: myUser?.surname })}</Text>
                                            </View>
                                        </View>
                                    );
                                })}
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

