import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Row, Col, FormText, ButtonGroup } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';
import { useEditVideoViewModel } from '../../api/main/videos/viewModels/useEditVideoViewModel';
import { useSaveVideoCallback } from '../../api/main/videos/useSaveVideoCallback';
import { Video, videoDefaultValues } from '../../api/main/models/Video';
import { Blob } from '../../api/main/models/Blob';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/Background';
import { useUploadBlobCallback } from '../../api/main/blobs/useUploadBlobCallback';
import { VideoComponent } from "./VideoComponent";
import { FileUploadButton } from "../shared/fileUploadButton/FileUploadButton";
import { HtmlEditor } from '../../shared/htmlEditor';

interface EditVideoProps {
    isCreate?: boolean,
}

/**
 * Createa new Video.
 */
export const CreateVideo = () => (<EditVideo isCreate={true} />);

/**
 * Edit a Video.
 */
export const EditVideo = (props: EditVideoProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams();
    const { data: { model: storeModel }, isLoading, errors: loadErrors } = useEditVideoViewModel(id);
    const { model, change, changes } = useChanges<Video>(storeModel, isCreate ? { ...videoDefaultValues() } : {});
    const [save, { errors: saveErrors }] = useSaveVideoCallback();
    const history = useHistory();

    // Storage of the blobs for videos and thumbnails.
    const [_videoBlob, setVideoBlob] = React.useState<Blob | undefined | null>();
    const videoBlob = _videoBlob ?? storeModel?.videoBlob;
    const [_thumbnailBlob, setThumbnailBlob] = React.useState<Blob | undefined | null>();
    const thumbnailBlob = _thumbnailBlob ?? storeModel?.thumbnailBlob;


    
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editVideo.nameRequired', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        history.goBack();
    }, [validate, save, model, changes, isCreate, history]);

    
    // Upload a video.
    const [videoFileSizeError, setVideoFileSizeError] = React.useState<string>('');
    var [uploadBlob, { errors: uploadBlobErrors, }] = useUploadBlobCallback();
    const [onUploadVideo, { isExecuting: isUploadingVideo }] = useAsyncCallback(async (files: FileList | null) => {
        setVideoFileSizeError('');

        if (!files || !files.length) {
            return;
        }

        // Only accept files of the right format (based on file extension).
        if (!!files[0]
            && !!files[0].name
            && !files[0].name.toLowerCase().endsWith('.mp4')) {
            setVideoFileSizeError(`The video must be in mp4 format.`);
            return;
        }

        // NOTE curretly no limit on the video size.

        // Upload the files as the video.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        setVideoBlob(blob);
        change({ videoBlobId: blob?.id });
    }, [uploadBlob, setVideoFileSizeError, change, setVideoBlob]);

    // Upload a thumbnail.
    const [thumbnailFileSizeError, setThumbnailFileSizeError] = React.useState<string>('');
    const [onUploadThumbnail, { isExecuting: isUploadingThumbnail }] = useAsyncCallback(async (files: FileList | null) => {
        setThumbnailFileSizeError('');

        if (!files || !files.length) {
            return;
        }

        // Only accept files of the right format (based on file extension).
        if (files[0].name
            && !files[0].name.toLowerCase().endsWith('.png')
            && !files[0].name.toLowerCase().endsWith('.jpg')
            && !files[0].name.toLowerCase().endsWith('.jpeg')
        ) {
            setThumbnailFileSizeError(t('editVideo.thumbnailFormatError', 'The thumbnail must be in png or jpg format.'));
            return;
        }

        // Limit the file size upload to 12mb as per specfieid requirements.
        const maximumFileSize = (1024 * 1024 * 12);
        if (files[0].size > maximumFileSize) {
            setThumbnailFileSizeError(t('editVideo.thumbnailSizeError', 'The maximum file size you can upload for a thumbnail is 12mb.  This file is {{fileSize}}mb and too large to be uploaded.', { fileSize: (files[0].size / 1024.0 / 1024.0).toFixed(1), }));
            return;
        }

        // Upload the files as the thumbnail.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        setThumbnailBlob(blob);
        change({ thumbnailBlobId: blob?.id });
    }, [uploadBlob, setThumbnailFileSizeError, t, setThumbnailBlob]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editVideo.createHeading', 'Add video')}</>
                                        ): (
                                        <>{t('editVideo.editHeading', 'Edit video')}</>
                                        )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors, uploadBlobErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editVideo.name', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>

                    <FormGroup>
                        <VideoComponent videoBlob={videoBlob} thumbnailBlob={thumbnailBlob} editMode />
                        <FormText>
                            {t('editVideo.videoFormatHint', 'Videos should be in mp4 format and use the standard 16x9 widescreen aspect ratio.  Thumbnails should be in png or jpg format and use the same 16x9 widescreen aspect ratio.')}
                        </FormText>
                        
                        <AlertOnErrors errors={[videoFileSizeError, thumbnailFileSizeError]} />

                        <div>
                            <ButtonGroup>
                                <FileUploadButton color="primary" outline onUpload={files => onUploadVideo(files)}>
                                    {
                                        isUploadingVideo ? (
                                            <>
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <> </><span>Uploading...</span>
                                            </>
                                        ) : (
                                            <span>
                                                {
                                                        Guid.isEmpty(model.videoBlobId) ?
                                                            t('editVideo.uploadVideo', 'Upload video...')
                                                            : t('editVideo.updateVideo', 'Update video...')
                                                }
                                            </span>
                                        )
                                    }
                                </FileUploadButton>
                                <FileUploadButton color="primary" outline onUpload={files => onUploadThumbnail(files)}>
                                    {
                                        isUploadingThumbnail ? (
                                            <>
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <> </><span>Uploading...</span>
                                            </>
                                        ) : (
                                            <span>
                                                {
                                                        Guid.isEmpty(model.thumbnailBlobId) ?
                                                            t('editVideo.uploadThumbnail', 'Upload thumbnail...')
                                                            : t('editVideo.updateThumbnail', 'Update thumbnail...')
                                                }
                                            </span>
                                        )
                                    }
                                </FileUploadButton>
                            </ButtonGroup>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="description">{t('editVideo.description.label', 'Description')}</Label>
                        <HtmlEditor value={model?.description ?? ''} onChange={value => change({ description: value })} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="authorName">{t('editVideo.authorName.label', 'Author')}</Label>
                        <ValidatedInput name="authorName" type="text" value={model?.authorName ?? ''} onChange={e => change({ authorName: e.currentTarget.value })} onBlur={e => validate('authorName')} validationErrors={validationErrors['authorName']} />
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
