import * as React from 'react';
import { ActionUserTaskCreateInput, ActionUserTaskUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateActionUserTaskCallback } from './useCreateActionUserTaskCallback';
import { useUpdateActionUserTaskCallback } from './useUpdateActionUserTaskCallback';
import { ActionUserTask } from '../models/ActionUserTask';

/**
 * Get a callback to save a ActionUserTask in the store using either a create or update.
 */
export function useSaveActionUserTaskCallback(options: SaveInStoreOptions<ActionUserTask, string> = {}) {
    const [_create, createStatus] = useCreateActionUserTaskCallback();
    const create = React.useCallback((model: Partial<ActionUserTask>) => _create(model as ActionUserTaskCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateActionUserTaskCallback();
    const update = React.useCallback((id: string, changes: Partial<ActionUserTask>) => _update(id, changes as ActionUserTaskUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
