import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import { StandardRating, getStandardRatingMetadata } from "../../api/main/models/constants/StandardRatings";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface StandardsHelpModalProps {
    isOpen: boolean,
    toggle: () => void,
}

/**
 * Modal used to give help about the different standards.
 *
 * NOTE the text in this modal is not very translatable due to the way HTML tags and text are mixed together.  To translate to languages
 * other than English you will need to refactor this modal and the approach used within it.
 * @param props
 */
export const StandardsHelpModal = (props: StandardsHelpModalProps) => {
    const {
        isOpen,
        toggle,
    } = props;

    const { t } = useTranslation();

    const bronzeMetadata = getStandardRatingMetadata(StandardRating.Bronze);
    const silverMetadata = getStandardRatingMetadata(StandardRating.Silver);
    const goldMetadata = getStandardRatingMetadata(StandardRating.Gold);

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {t('standardsHelpModal.heading', 'The compliance standards explained',)}
            </ModalHeader>
            <ModalBody>
                <p>
                    {t('standardsHelpModal.intro.p1.part1', 'As you use The Compliance Standard, you will see that each requirement has been assigned a standard. There are three standards we use:')}
                    <> </>
                    <strong>
                        {t('standardsHelpModal.intro.p1.part2', 'bronze,')}
                    </strong>
                    <> </>
                    <strong>
                        {t('standardsHelpModal.intro.p1.part3', 'silver')}
                    </strong>
                    <> </>
                    {t('standardsHelpModal.intro.p1.part4', 'and')}
                    <> </>
                    <strong>
                        {t('standardsHelpModal.intro.p1.part5', 'gold.')}
                    </strong>
                    <> </>
                    {t('standardsHelpModal.intro.p1.part6', 'Below is a break-down of what each standard means and some examples to help you understand their context.')}
                </p>

                {/* Bronze */}
                <div>
                    <Badge style={{ backgroundColor: bronzeMetadata.color, }}>
                        <FontAwesomeIcon icon="award" />
                        <> </>
                        {bronzeMetadata.displayName}
                    </Badge>
                </div>
                <p>
                    <strong>
                        {t('standardsHelpModal.bronze.p1', 'Bronze standard requirements')}
                    </strong>
                    <> </>
                    {t('standardsHelpModal.bronze.p2', 'are statutory, meaning they are required by law. For example, if an item of legislation states your school must have something in place, it will be considered bronze standard. Bronze standard requirements also include anything that is required by government bodies such as the DfE or ESFA.')}
                </p>

                {/* Silver */}
                <div>
                    <Badge style={{ backgroundColor: silverMetadata.color, }}>
                        <FontAwesomeIcon icon="award" />
                        <> </>
                        {silverMetadata.displayName}
                    </Badge>
                </div>
                <p>
                    <strong>
                        {t('standardsHelpModal.silver.p1', 'Silver  standard requirements')}
                    </strong>
                    <> </>
                    {t('standardsHelpModal.silver.p2', 'include anything that is strongly recommended or expected by government bodies and other authorities but is not statutory. For example, if the DfE strongly recommends your school has something in place, but it is not required by law, this will be considered silver standard.')}
                </p>

                {/* Gold */}
                <div>
                    <Badge style={{ backgroundColor: goldMetadata.color, }}>
                        <FontAwesomeIcon icon="award" />
                        <> </>
                        {goldMetadata.displayName}
                    </Badge>
                </div>
                <p>
                    <strong>
                        {t('standardsHelpModal.gold.p1', 'Gold  standard requirements')}
                    </strong>
                    <> </>
                    {t('standardsHelpModal.gold.p2', 'include other best practice options your school can have in place, as recommended by government bodies and other authorities. For example, if the DfE suggests your school has something in place as good practice, but it is optional, this would be considered gold standard. Gold standard also includes common good practice within the sector and recommendations from non-government bodies, such as unions and experts.')}
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" outline onClick={() => toggle()}>
                    {t('standardsHelpModal.close', 'Close')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
