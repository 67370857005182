import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { RequirementAreaCreateInput } from '../generated/globalTypes';
import { createRequirementAreaMutation, createRequirementAreaMutationVariables } from '../generated/createRequirementAreaMutation';
import { requirementAreaFieldsFragment } from '../models/RequirementArea';

/**
 * Get a callback to create a RequirementArea in the store.
 */
export function useCreateRequirementAreaCallback(): [(model: RequirementAreaCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createRequirementAreaMutation, createRequirementAreaMutationVariables>(
        gql`
            mutation createRequirementAreaMutation ($model: RequirementAreaCreateInput!) {
                createRequirementArea(model: $model) {
                    ...requirementAreaFields
                }
            }

            ${requirementAreaFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: RequirementAreaCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
