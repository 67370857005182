import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { VideosList } from "./VideosList";
import { EditVideo, CreateVideo } from "./EditVideo";
import { DeleteVideo } from "./DeleteVideo";

export const videoRoutes: Array<RouteEntry> = [
    { path: '/administration/videos', exact: true, component: VideosList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/videos/edit/:id', component: EditVideo, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/videos/add', component: CreateVideo, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/videos/delete/:id', component: DeleteVideo, authorize: true, requireRole: IdentityRoles.Administration },
];
