import { User } from '../main/models/User';
import { useAuthenticatedState } from './useAuthenticatedState';

/**
 * Hook to get the current user.
 * 
 * NOTE this is a wrapper around useAuthenticationState() for the commmon use case of just wanting the current user.
 */
export function useCurrentUser(): User | null | undefined {
    const { user } = useAuthenticatedState({ includeUser: true });
    return user;
}
