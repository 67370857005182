import './htmlDisplay.scss';

import * as React from 'react';

export interface HtmlDisplayProps {
    sanatizedHtml: string,
}

/**
 * React component that will display HTML.
 * 
 * NOTE: it is expected that the HTML has been pre-santized, it will not be resanatized as part of being displayed.
 * 
 * NOTE: we use the class ql-editor and ql-snow to ensure the contents display within this component as the do when rendered during editing within
 * quill.  If you change the quill theme (or the HTML editing engine) you will need to update these as well.
 */
export const HtmlDisplay = (props: HtmlDisplayProps) => {
    const { sanatizedHtml } = props;

    return (
        <div className="html-display ql-snow">
            <div className="ql-editor" dangerouslySetInnerHTML={{ __html: sanatizedHtml }} />
        </div>
    );
};