import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Card, CardImg, CardImgOverlay, CardBody } from 'reactstrap';
import { Blob } from '../../api/main/models/Blob';
import { Video } from '../../api/main/models/Video';
import "./videoTile.scss";
import { useToggleState } from 'use-toggle-state';
import { WatchVideoModal } from './WatchVideoModal';
import { ConditionalFragment } from 'react-conditionalfragment';

export interface VideoTileProps {
    model: Video,
    thumbnailBlob: Blob | undefined | null,

    size?: 'xs' | 'sm' | 'auto',
}

/**
 * Tile for launching a video.
 * @param props
 */
export const VideoTile = (props: VideoTileProps) => {
    const {
        model,
        thumbnailBlob,
        size = 'auto',
    } = props;

    const [isModalOpen, toggleIsModalOpen] = useToggleState();

    return (
        <>
            <Card className={`video-tile video-tile-size-${size}`} onClick={() => toggleIsModalOpen()}>
                <CardImg className="video-tile-thumbnail img-fluid" src={thumbnailBlob?.url ?? ''} />
                <CardImgOverlay className="video-tile-thumbnail-overlay">
                    <FontAwesomeIcon icon="play" />
                </CardImgOverlay>
                {/* If we are not showing in xs size then include the name under the video */}
                <ConditionalFragment showIf={size !== 'xs'}>
                    <CardBody className="video-tile-name">
                        {model.name}
                    </CardBody>
                </ConditionalFragment>
            </Card>

            <ConditionalFragment showIf={isModalOpen}>
                <WatchVideoModal id={model?.id ?? ''} isOpen={isModalOpen} toggle={() => toggleIsModalOpen()} />
            </ConditionalFragment>
        </>
        );
};
