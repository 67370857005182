import * as React from 'react';
import { Row, Col, ListGroupItem } from 'reactstrap';
import { schoolCertificateQuery_model_areas } from '../../api/main/generated/schoolCertificateQuery';
import { AreaCertificateThumbnail } from './AreaCertificateThumbnail';

export interface AreaCertificateItemProps {
    model: schoolCertificateQuery_model_areas,
}

/**
 * Card with the schools result for an area for the certificate screen.
 * @param props
 */
export const AreaCertificateItem = (props: AreaCertificateItemProps) => {
    const {
        model,
    } = props;

    return (
        <ListGroupItem className="area-certificate-item">
            <Row>
                <Col>
                    <h5>
                        {model.name}
                    </h5>
                </Col>
                <Col xs="auto">
                    <AreaCertificateThumbnail awardLevel={model.awardLevel} />
                </Col>
            </Row>
        </ListGroupItem>
        );
};
