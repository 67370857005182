import * as React from 'react';
import { ModelArrayChanges } from './ModelArrayChanges';
import { isNullOrUndefined } from 'util';

/**
 * An item in a changed array.
 */
interface ModelArrayItemChanges<T, Key> {
    key: Key,
    unchanged: T | undefined | null,
    changes: Partial<T>,
    isAddition: boolean,
    isRemoval: boolean,
    hasUnsavedChanges: boolean,
}

/**
 * Hook that makes it easy to apply changes to multiple items in an array ontop of original data.
 */
export function useChangesArray<T, Key>(unchanged: Array<T> | undefined | null, getKey: (item: T) => Key): ModelArrayChanges<T, Key> {
    // Store changes to be layered ontop of unchangedRecords in the state.
    const [_changes, setChanges] = React.useState<Array<ModelArrayItemChanges<T, Key>>>([]);

    // When unchanged changes, make sure we merge those source changes "under" the changes that come from calling change/changeFor and friends.
    React.useEffect(() => {
        setChanges(prevState => {
            // Loop over each unchanged item and see if we need to add it to our list of changes.
            // NOTE because out unchanged may have come from a filter() or map() statement we look for changes
            // in the array contents not array reference for performance reasons rather than just assuming
            // a change to unchanged needs a state change.  This is what hasAnythingChanged handles for us.
            let ret: Array<ModelArrayItemChanges<T, Key>> = [];
            let hasAnythingChanged = false;

            // Update each change in unchangedRecord into the tracked changes.
            for (let unchangedItem of unchanged ?? []) {
                const unchangedItemKey = getKey(unchangedItem);
                let changeRecord = prevState.find(item => item.key === unchangedItemKey);

                // Check if we have the same underlying unchanged object.
                if (changeRecord) {
                    if (changeRecord.unchanged !== unchangedItem) {
                        // Update the unchanged item.
                        changeRecord.unchanged = unchangedItem;
                        hasAnythingChanged = true;
                    }

                    // Push to ret 
                    ret.push(changeRecord);
                } else {
                    // Add a new unchanged record.
                    ret.push({
                        key: unchangedItemKey,
                        unchanged: unchangedItem,
                        changes: {},
                        isAddition: false,
                        isRemoval: false,
                        hasUnsavedChanges: false,
                    });
                    hasAnythingChanged = true;
                }
            }

            // Loop over all the previous items and if any of those have disapeared from the underlying source, make remove them.
            for (const oldItem of prevState) {
                // If this item never existed in the unchanged list then we need to add it to ret and continue.
                if (!oldItem.unchanged) {
                    if (!ret.find(item => item === oldItem)) {
                        ret.push(oldItem);
                    }
                    continue;
                }

                // Find the item in the old.
                const oldItemKey = getKey(oldItem.unchanged);
                const existsInUnchanged = !!unchanged?.find(item => getKey(item) === oldItemKey);
                if (!existsInUnchanged) {
                    ret = ret?.filter(it => it !== oldItem);
                    hasAnythingChanged = true;
                }
            }

            // If nothing has changed, return the unchanged state.
            if (!hasAnythingChanged) {
                return prevState;
            }

            // Return the updated state.
            return ret;
        });
    }, [unchanged, getKey, setChanges]);

    // Provide a method to apply a change or set of changes.
    // NOTE a side effect of this code is that the first time we make any change, we'll be creating a record for everything from unchangedRecords
    // into _changes for ease in the future.
    // we can't do that before this point as we allow unchanged to be passed in with new values after the state of _changes is originally set.
    const change = React.useCallback((newArray: Array<T>) => {
        setChanges(prevState => {
            let newState = [...prevState];
            let keysInNewArray = [];

            // Loop over each item in the array and find its matching changes
            for (let newChanges of newArray) {
                let key = getKey(newChanges);
                keysInNewArray.push(key);
                let changeRecord = prevState.find(it => it.key === key);

                // If we don't have a change record for this item, see if we have an unchanged record
                if (!changeRecord) {
                    // Add as an addition.
                    newState.push({
                        key: key,
                        unchanged: null,
                        changes: newChanges,
                        isAddition: true,
                        isRemoval: false,
                        hasUnsavedChanges: true,
                    });
                } else {
                    // Update the changes in the change record.
                    changeRecord.changes = { ...changeRecord.changes, ...newChanges };
                    changeRecord.hasUnsavedChanges = true;
                }
            }

            // Loop over anything that wasn't in array and mark it is removed.
            for (let changeRecord of newState) {
                if (!keysInNewArray.find(it => it === changeRecord.key)) {
                    changeRecord.isRemoval = true;
                    changeRecord.hasUnsavedChanges = true;
                }
            }

            return newState;
        });
    }, [setChanges, getKey]);

    // Provide a method to reset all changes.
    const reset = React.useCallback(() => {
        setChanges([]);
    }, [setChanges]);

    // Combine the initial model with the changes to produce model with its current state.
    const modelFromChanges = React.useCallback((changesToUse: Array<ModelArrayItemChanges<T, Key>>): Array<T> => {
        let ret = [];

        for (let item of changesToUse) {
            // Merge the original and the change into a model.
            ret.push({ ...(item.unchanged ?? {}), ...item.changes } as T);
        }

        return ret;
    }, []);

    const model = React.useMemo(() => modelFromChanges(_changes.filter(it => !it.isRemoval)), [_changes, modelFromChanges]);
    const added = React.useMemo(() => modelFromChanges(_changes.filter(it => it.isAddition && !it.isRemoval && it.hasUnsavedChanges)), [_changes, modelFromChanges]);
    const removed = React.useMemo(() => modelFromChanges(_changes.filter(it => it.isRemoval && !it.isAddition && it.hasUnsavedChanges)), [_changes, modelFromChanges]);
    const updated = React.useMemo(() => modelFromChanges(_changes.filter(it => !it.isAddition && !it.isRemoval && Object.keys(it.changes).length && it.hasUnsavedChanges)), [_changes, modelFromChanges]);

    // Combine everything into a return value and return it (caching it with useMemo()).
    const ret = React.useMemo(() => ({
        model: model,
        change: change,
        reset: reset,

        added: added,
        removed: removed,
        updated: updated,
        
        modelFor: (key: Key) => {
            const changeRecord = _changes.find(it => it.key === key);
            if (!changeRecord) {
                return null;
            }

            return { ...(changeRecord.unchanged ?? {}), ...changeRecord.changes } as T;
        },
        changeFor: (key: Key, newChanges: Partial<T>) => {
            setChanges(prevState => {
                let newState = [...prevState];

                // Find the key in question
                let changeRecord = prevState.find(it => it.key === key);

                // If we don't have a change record for this item, add it as an addition.
                if (!changeRecord) {
                    newState.push({
                        key: key,
                        unchanged: null,
                        changes: newChanges,
                        isAddition: true,
                        isRemoval: false,
                        hasUnsavedChanges: true,
                    });
                } else {
                    // Update the changes in the change record.
                    changeRecord.changes = { ...changeRecord.changes, ...newChanges };
                    changeRecord.hasUnsavedChanges = true;
                }

                return newState;
            });
        },
        changesFor: (key: Key) => {
            const changeRecord = _changes.find(it => it.key === key);
            if (!changeRecord) {
                return {};
            }

            return changeRecord.changes;
        },
        addFor: (item: T) => {
            var key = getKey(item);
            setChanges(prevState => {
                let newState = [...prevState];

                // Find the key in question
                let changeRecord = prevState.find(it => it.key === key);
                if (!changeRecord) {
                    newState.push({
                        key: key,
                        unchanged: null,
                        changes: item,
                        isAddition: true,
                        isRemoval: false,
                        hasUnsavedChanges: true,
                    });
                } else if (changeRecord.isRemoval) {
                    // If we are added after being removed then mark ourselves as not removed and reset our changes to the passed in model.
                    changeRecord.isRemoval = false;
                    changeRecord.changes = item;
                } else {
                    // We were asked to add something that already exists, silently ignore that.
                    // TODO would we be better throwing an exception?
                }

                return newState;
            });
        },
        removeFor: (key: Key) => {
            setChanges(prevState => {
                let newState = [...prevState];

                // Find the key in question
                let changeRecord = prevState.find(it => it.key === key);
                if (changeRecord) {
                    changeRecord.isRemoval = true;
                    changeRecord.hasUnsavedChanges = true;
                }

                return newState;
            });
        },

        /**
         * Mark the current changes for this model as saved.
         */
        markAsSaved: (models?: Array<T>) => {
            setChanges(prevState => {
                let newState: Array<ModelArrayItemChanges<T, Key>> = [...prevState];
                let hasChangedSomething = false;
                const keysToCheck = models ? models.map(item => getKey(item)) : null;

                for (const item of prevState) {
                    // If we were passed in an array of items to mark, then make sure this is in the array before marking it as complete
                    // (if we don't get an array passed in then everything gets marked as complete).
                    if (!isNullOrUndefined(keysToCheck)) {
                        if (!keysToCheck.find(it => it === item.key)) {
                            continue;
                        }
                    }

                    // Mark as all changes having been saved.
                    item.hasUnsavedChanges = false;

                    // Saved additions should also no longer be marked as additions (but removals are fine to be left marked as removals.)
                    item.isAddition = false;

                    hasChangedSomething = true;
                }

                // If we didn't end up changing anything then end without having made any changes.
                if (!hasChangedSomething) {
                    return prevState;
                }

                // Otherwise set our new state.
                return newState;
            });
        },
    }), [_changes, setChanges, change, reset, model, added, removed, updated, getKey]);

    return ret;
}