import './noResultsFound.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface NoResultsFoundProps {
    className?: string,
    search?: string,
    children?: React.ReactNode,
}

/**
 * Component to show when a search or query returned no results.
 */
export const NoResultsFound = (props: NoResultsFoundProps) => {
    const { className, search, children } = props;

    const { t } = useTranslation();

    // If we have custom children, render them within our cotainer.
    if (children) {
        return (
            <div className={`no-results-found ${search ? 'no-results-found-search' : 'no-results-found-no-search'} ${className ?? ''}`}>
                {children}
            </div>
            );
    }


    // Otherwise render th default contents.
    return (
        <div className={`no-results-found ${search ? 'no-results-found-search' : 'no-results-found-no-search'} ${className ?? ''}`}>
            <div className="no-results-found-icon">
                <FontAwesomeIcon icon={['far', 'frown']} />
            </div>
            <p>
                {
                    search ? 
                        t('notResultsFound.nothingMatchedSearch', 'Sorry there were no matches for "{{search}}."', { search: search })
                        : t('notResultsFound.nothingFound', 'Sorry there is nothing to show you here yet.')
                }
            </p>
        </div>
        );
};