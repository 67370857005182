import * as React from 'react';
import { Redirect } from 'react-router';

/**
 * Default / home page.
 */
export const Home = () => {
    //const { data: { roles } } = useCurrentUserRoles();

    //return (
    //    <>
    //        <Redirect to={!!roles?.find(item => item === IdentityRoles.MATUser)? '/mat-overview': '/overview'} />
    //    </>
    //);

    return (
        <>
            <Redirect to="/overview" />
        </>
    );
};
