import React from "react";
import { RequirementComplianceState, requirementComplianceStates } from "../../services/requirementComplianceStates/requirementComplianceStates";
import { SchoolRequirementCompliance } from "../../api/main/models/SchoolRequirementCompliance";
import { Requirement } from "../../api/main/models/Requirement";


export interface ChangeOrToggleComplianceStateOptions {
    complianceState: RequirementComplianceState,
    changeCompliance: (changes: Partial<SchoolRequirementCompliance>) => void,
    addOrUpdateAction: (reason: 'userAdded' | 'review' | 'failed' | 'delete', isAutoAction: boolean, date?: string) => void,
    requirement: Requirement,
    saveComplianceDebounce: () => void,
}

/**
 * Method to change or toggle compliance state and make all required logic.
 *
 * This method is shared between RequirementCompliance and RequirementComplianceCutdownItem and so has been abstracted into its own hook to avoid duplication of
 * business logic.
 * 
 * @param options
 */
export function useChangeOrToggleComplianceState(options: ChangeOrToggleComplianceStateOptions) {
    const {
        complianceState,
        changeCompliance,
        addOrUpdateAction,
        requirement,
        saveComplianceDebounce
    } = options;

    const changeOrToggleComplianceState = React.useCallback((newState: RequirementComplianceState) => {
        // If we are toggling the existing state, then toggle back to unanswered.
        if (complianceState.id === newState.id) {
            changeCompliance({ requirementComplianceState: requirementComplianceStates.unanswered.id });

            // Clear any auto action.
            addOrUpdateAction('delete', true);
        } else {
            // Change to the new state.
            changeCompliance({ requirementComplianceState: newState.id });

            // If the new state is failed, then add an auto action for it.
            if (newState.isFailure) {
                addOrUpdateAction('failed', true);
            } else if (newState.isSuccess && requirement.requiresReview) {
                // We no longer auto create an action in this case but prompt on screen, so lets delete the action instead.
                // The add will be triggered via the user clicking a button.
                //addOrUpdateAction('review', true);
                addOrUpdateAction('delete', true);
            } else {
                // Clear any auto action.
                addOrUpdateAction('delete', true);
            }
        }

        // Save the change (don't wait the result).
        // NOTE as we expect this to be debounced we know the state changes above will go through before this runs.
        saveComplianceDebounce();
    }, [changeCompliance, complianceState, saveComplianceDebounce, addOrUpdateAction, requirement]);

    return changeOrToggleComplianceState;
}
