import * as React from 'react';
import { useAuthorizeService } from './useAuthorizeService';
import { User } from '../main/models/User';

export interface UseAuthenticationStateOptions {
    /**
     * By default we don't load the user details as they are often unused and require extra loading.  When this is set to true we'll load the user record and return it as well.
     */
    includeUser?: boolean,
}

/**
 * Hook to return the authenticated state of the user.  The hook subscribes to authenticate changes by authService and will update the state in components whenever that changes.
 * @param onStateChanged
 */
export function useAuthenticatedState(options: UseAuthenticationStateOptions = {}): { isAuthenticated: boolean | null, user: User | null } {
    const authService = useAuthorizeService();
    const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(null);
    const [user, setUser] = React.useState<User | null>(null);

    const stateChanged = React.useCallback(async () => {
        const authenticated = await authService.isAuthenticated();

        let userResult: User | null = null;
        if (options.includeUser) {
            userResult = await authService.getUser();
            setUser(userResult);
        }

        setIsAuthenticated(authenticated);
    }, [authService, options.includeUser]);

    // Subscribe to changes to the authService state.
    React.useEffect(() => {
        const subscription = authService.subscribe(() => { stateChanged(); });
        stateChanged();
        return () => { authService.unsubscribe(subscription); };
    }, [authService, stateChanged]);

    return { isAuthenticated: isAuthenticated, user: user };
}
