import gql from "graphql-tag";
import { schoolRequirementComplianceLinkFields } from "../generated/schoolRequirementComplianceLinkFields";

export const schoolRequirementComplianceLinkFieldsFragment = gql`
    fragment schoolRequirementComplianceLinkFields on SchoolRequirementComplianceLink {
        id
        linkType
        blobId
        url
        linkedDate
        notesHtml
        schoolRequirementComplianceId
    }
`;


export type SchoolRequirementComplianceLink = Omit<schoolRequirementComplianceLinkFields, '__typename'>;
