import * as React from 'react';
import { Table, Button, Row, Col, ButtonGroup } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { TableRowButtons } from '../shared/TableRowButtons';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useSchoolGroups } from '../../api/main/schoolGroups/useSchoolGroups';
import { schoolGroupTypes } from '../../services/schoolGroupTypes/schoolGroupTypes';
import { Link } from 'react-router-dom';
import { WaypointExtended } from '../shared/WaypointExtended';


/**
 * List of MATs.
 */
export const MATsList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useSchoolGroups({
        schoolGroupTypeId: schoolGroupTypes.multiAcademyTrust.id
    });
    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems || !search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.schoolGroupName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || !!item.schools.find(schoolLink => schoolLink.schoolName.toLocaleLowerCase()?.indexOf(lowerSearch) >= 0)
        );
    }, [allItems, search]);

    useReplaceSearchParamsEffect({ search: search });

    const colCount = 4;

    return (
        <>
            <Banner fluid>
                <Row>
                    <Col>
                        <h1>{t('matsList.heading', 'Multi academy trusts')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className="d-table-cell d-lg-none">{t('matsList.mat', 'MAT')}</th>
                            <th className="d-none d-lg-table-cell">{t('matsList.name', 'Name')}</th>
                            <th className="d-none d-lg-table-cell">{t('matsList.numberOfSchools', 'Schools')}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                      
                        {
                            items?.map(item => {
                                // Get an alphabetical list of schools in this trust.
                                const mySchoolsWithDuplicates = item.schools;

                                // Remove duplicates
                                let mySchools: Array<{ id: string, schoolName: string }> = [];
                                for (const school of mySchoolsWithDuplicates) {
                                    if (mySchools.find(it => it.id === school.id)) {
                                        continue;
                                    }

                                    mySchools.push(school);
                                }

                                mySchools.sort((a, b) => a.schoolName.localeCompare(b.schoolName));

                                return (
                                    <tr key={item.id} onDoubleClick={e => history.push(`/mat-overview/${item.id}`)}>
                                        <td className="d-table-cell d-lg-none">
                                            <div>{item.schoolGroupName}</div>
                                            <div className="text-muted">
                                                {
                                                    mySchools.map(school => (
                                                        <div key={school.id}>
                                                            <Link to={`/overview/${school.id}`}>
                                                                {school.schoolName}
                                                            </Link>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </td>
                                        <td className="d-none d-lg-table-cell">{item.schoolGroupName}</td>
                                        <td className="d-none d-lg-table-cell">
                                            <div className="text-muted">
                                                {
                                                    mySchools.map(school => (
                                                        <div key={school.id}>
                                                            <Link to={`/overview/${school.id}`}>
                                                                {school.schoolName}
                                                            </Link>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <TableRowButtons>
                                                <ButtonGroup>
                                                    <LinkContainer to={`/mat-overview/${item.id}`}>
                                                        <Button color="primary" outline>
                                                            <FontAwesomeIcon icon="book-reader" />
                                                            <> {t('matsList.view', 'View MAT overview')}</>
                                                        </Button>
                                                    </LinkContainer>
                                                </ButtonGroup>
                                            </TableRowButtons>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length && !hasMore()}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>
                            <tr><td colSpan={colCount}>
                                <WaypointExtended key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </MainContainer>
        </>
    );
};
