import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../shared/abstractStore';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { schoolCertificateQuery, schoolCertificateQueryVariables } from '../generated/schoolCertificateQuery';


/**
 * Get a the data needed for a school's certificate custom endpoint.
 *
 * This endpoint does all processing on the server so it can return the raw results without any leakage of data to anonymous users who are
 * checking the certificate of a school.
 * @param options
 */
export function useSchoolCertificate(schoolId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<schoolCertificateQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<schoolCertificateQuery, schoolCertificateQueryVariables>(
        gql`
        query schoolCertificateQuery($schoolId: ID!) {
            model: schoolCertificate (id: $schoolId) {
                id
                schoolName
                awardLevel
                unclaimedAwardLevel
                areas {
                    id
                    name
                    awardLevel
                }
            }
        }
        `,
        {
            variables: {
                schoolId: schoolId ?? '0'
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy && !!schoolId) {
            load();
        }
    }, [options.lazy, load, schoolId]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
