import gql from "graphql-tag";
import { actionFields } from "../generated/actionFields";
import { actionUserTaskFieldsFragment } from "./ActionUserTask";

export const actionFieldsFragment = gql`
    fragment actionFields on Action {
        id
        schoolId
        name
        descriptionHtml
        archived
        requirementOriginKey
        isAutomaticFromRequirementCompliance
        actionAddedDate
        actionState
        completedDate
        notesHtml
        isPinned

        actionUserTasks {
            ...actionUserTaskFields
        }
    }

    ${actionUserTaskFieldsFragment}
`;


export type Action = Omit<actionFields, '__typename' | 'actionUserTasks'>;
