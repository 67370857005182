import { ServiceResolver, ServiceProvider, IServiceCollectionBuilder } from "inject-typesafe";
import { ApiFetch } from 'apifetch-json';
import { CacheService } from "../shared/cacheInRedux";
import { AppReduxStore } from "../reduxStore/store";
import { EnhancedStore, CombinedState } from "@reduxjs/toolkit";
import { ApiKeyService } from "../services/ApiKeyService";
import { BlobUploadService } from "../services/bloblUploadService/BlobUploadService";
import { integrationConfig } from "./integrationConfig";

/**
 * Returns a configured service provider.
 */
export function createServiceProvider(reduxStore: EnhancedStore<CombinedState<AppReduxStore>>) {
    return new ServiceProvider((builder: IServiceCollectionBuilder<AppServices>) => ({
        apiFetch: builder.singleton(services => {
            const apiKeyService = new ApiKeyService();
            return new ApiFetch({
                headers: {
                    'x-api-key': apiKeyService.generateKey(),
                },
                credentials: 'include',
            });
        }),
        cacheService: builder.singleton(services => new CacheService(() => services.reduxStore().getState().cache, services.reduxStore().dispatch)),
        reduxStore: builder.singleton(() => reduxStore),
        blobUploadService: builder.singleton(services => new BlobUploadService(`${integrationConfig.apiServer}api/blobs`, services.apiFetch()))
    }));
}

export interface AppServices {
    apiFetch: ServiceResolver<ApiFetch>,
    cacheService: ServiceResolver<CacheService>,
    reduxStore: ServiceResolver<EnhancedStore<CombinedState<AppReduxStore>>>,
    blobUploadService: ServiceResolver<BlobUploadService>,
}

