import gql from "graphql-tag";
import { actionUserTaskFields } from "../generated/actionUserTaskFields";

export const actionUserTaskFieldsFragment = gql`
    fragment actionUserTaskFields on ActionUserTask {
        id
        actionId
        userId
        tasksHtml
        actionAddedDate
        actionState
        completedDate
        notesHtml
        archived
    }
`;


export type ActionUserTask = Omit<actionUserTaskFields, '__typename'>;
