import * as React from 'react';
import { useAsyncCallback } from 'react-use-async-callback';
import { AsyncActionStatus } from '../abstractStore';

/**
 * Options for saiving store data. 
 */
export interface SaveInStoreOptions<T, TId> {
    beforeSaving?: (id: TId, changes: Partial<T>, isCreate: boolean) => void,
    afterSaving?: (id: TId, changes: Partial<T>, isCreate: boolean) => void,
}

/**
 * Hook to give us a save method that can cope with creating or updating the store as appropriate.
 */
export function useSaveInStore<T, TId>(
    createDataStore: [(model: T) => Promise<void>, AsyncActionStatus],
    updateDataStore: [(id: TId, model: Partial<T>) => Promise<void>, AsyncActionStatus],
    options?: SaveInStoreOptions<T, TId>
)
    : [(id: TId, changes: Partial<T>, isCreate: boolean) => Promise<void>, AsyncActionStatus]
{

    const [create, { isExecuting: isCreating, errors: createErrors }] = createDataStore;
    const [update, { isExecuting: isUpdating, errors: updateErrors }] = updateDataStore;
    const [save, { isExecuting: isSaving, errors: saveErrors }] = useAsyncCallback(async (id: TId, changes: Partial<T>, isCreate: boolean) => {
        // Run the pre-save option.
        if (options && options.beforeSaving) {
            options.beforeSaving(id, changes, isCreate);
        } 

        // Create or update the item in the store.
        if (isCreate) {
            await create(changes as T);
        } else {
            await update(id, changes);
        }

        // Run the post-save option.
        if (options && options.afterSaving) {
            options.afterSaving(id, changes, isCreate);
        } 
    }, [create, update, options]);

    // Combine errors from all sources into a single errors result.
    const errors = React.useMemo(() => {
        let ret = [];
        if (createErrors) {
            ret.push(createErrors);
        }
        if (updateErrors) {
            ret.push(updateErrors);
        }
        if (saveErrors) {
            ret.push(saveErrors);
        }

        if (ret.length === 0) {
            return undefined;
        }

        if (ret.length === 1) {
            return ret[0];
        }

        return ret;
    }, [createErrors, updateErrors, saveErrors]);

    return [save, { isExecuting: isSaving || isCreating || isUpdating, errors: errors }];
}
