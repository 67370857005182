import * as React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { SidebarNavTitle } from '../../shared/sidebarNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { SidebarRequired } from '../shared/SidebarRequired';

export const AdministrationNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <SidebarRequired />
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <SidebarNavTitle child>
                    {t('administrationNavigation.reportsHeading', 'Admin reports')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink to="/administration/overview-report" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="chart-bar" className="nav-icon" />
                        <> {t('administrationNavigation.overviewReport', 'Admin reports')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle child>
                    {t('administrationNavigation.schoolsAndMatsAdministrationHeading', 'School administration')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink to="/administration/schools" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="book-reader" className="nav-icon" />
                        <> {t('administrationNavigation.schools', 'Schools')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/administration/mats" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="landmark" className="nav-icon" />
                        <> {t('administrationNavigation.mats', 'MATs')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle child>
                    {t('administrationNavigation.requirementsAdministrationHeading', 'Requirements administration')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink to="/administration/update-requirements" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="chalkboard" className="nav-icon" />
                        <> {t('administrationNavigation.updateRequirements', 'Edit current requirements')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/administration/requirements" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="history" className="nav-icon" />
                        <> {t('administrationNavigation.olderVersions', 'All versions')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle child>
                    {t('administrationNavigation.videosHeading', 'Video administration')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink to="/administration/videos" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="file-video" className="nav-icon" />
                        <> {t('administrationNavigation.videos', 'Videos')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle child>
                    {t('administrationNavigation.tagAdministrationHeading', 'School phase and type administration')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink to="/administration/school-phases" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="graduation-cap" className="nav-icon" />
                        <> {t('administrationNavigation.schoolPhases', 'School phases')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink to="/administration/school-types" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="school" className="nav-icon" />
                        <> {t('administrationNavigation.schoolTypes', 'School types')}</>
                    </NavLink>
                </NavItem>

            </AuthorizeContainer>
        </>
    );
};
