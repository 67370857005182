import * as React from 'react';
import { RequirementCreateInput, RequirementUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateRequirementCallback } from './useCreateRequirementCallback';
import { useUpdateRequirementCallback } from './useUpdateRequirementCallback';
import { Requirement } from '../models/Requirement';

/**
 * Get a callback to save a Requirement in the store using either a create or update.
 */
export function useSaveRequirementCallback(options: SaveInStoreOptions<Requirement, string> = {}) {
    const [_create, createStatus] = useCreateRequirementCallback();
    const create = React.useCallback((model: Partial<Requirement>) => _create(model as RequirementCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateRequirementCallback();
    const update = React.useCallback((id: string, changes: Partial<Requirement>) => _update(id, changes as RequirementUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
