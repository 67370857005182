import gql from "graphql-tag";
import { requirementSchoolTypeFields } from "../generated/requirementSchoolTypeFields";

export const requirementSchoolTypeFieldsFragment = gql`
    fragment requirementSchoolTypeFields on RequirementSchoolType {
        id
        requirementId
        schoolTypeId
    }
`;


export type RequirementSchoolType = Omit<requirementSchoolTypeFields, '__typename' | 'schoolType'>;
