import * as React from 'react';
import { useURLSearchParams } from "./useURLSearchParams";


/**
 * Hook to get the value of specific search parameters using deconstruction.
 * @param name name of the Query param to return the value of.
 */
export function useSearchParams(): { [key: string]: string | null } {
    const params = useURLSearchParams();
    const deconstructionObject = React.useMemo(() => {
        let ret = {};
        params.forEach((value, key) => {
            ret = {
                ...ret,
                [key]: value
            }
        });
        return ret;
    }, [params]);

    return deconstructionObject;
}