import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, ListGroup, DropdownToggle, ButtonGroup, ButtonDropdown, DropdownMenu, DropdownItem, Tooltip, Button, Spinner } from 'reactstrap';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/Background';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useParams } from 'react-router';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useCurrentUser } from '../../api/api-authorization/useCurrentUser';
import { AwardImage } from './awardImage/AwardImage';
import "./awards.scss";
import { AreaCertificateItem } from '../certificates/AreaCertificateItem';
import { useSchoolCertificate } from '../../api/main/schoolCertificates/useSchoolCertificate';
import { StandardRating } from '../../api/main/models/constants/StandardRatings';
import { useToggleState } from 'use-toggle-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Guid } from 'guid-string';
import { AreaCertificateThumbnail } from '../certificates/AreaCertificateThumbnail';
import { isNullOrUndefined } from 'util';
import { ClaimAwardModal } from './ClaimAwardModal';
import { useCreateSchoolAwardCallback } from '../../api/main/schoolAwards/useCreateSchoolAwardCallback';
import { schoolAwardDefaultValues } from '../../api/main/models/SchoolAward';
import { useAsyncCallback } from 'react-use-async-callback';
import moment from "moment";
import { SchoolAwardCreateInput } from '../../api/main/generated/globalTypes';
import { generatePdfAsBlob } from '../../utilities/generatePdfAsBlob';
import { CertificatePdf } from '../../pdf/certificate/CertificatePdf';
import { ButtonAsync } from 'reactstrap-buttonasync';
import QRCode from "qrcode";
import FileSaver from 'file-saver';
import { schoolCertificateQuery_model } from '../../api/main/generated/schoolCertificateQuery';

/**
 * Awards
 */
export const Awards = () => {
    const {
        schoolId: paramsSchoolId,
    } = useParams();

    const user = useCurrentUser();
    const schoolId = paramsSchoolId ?? user?.schoolId?.toString();

    const {
        data: {
            model,
        },
        isLoading, errors: loadingErrors,
        refresh: refreshModel,
    } = useSchoolCertificate(schoolId);

    const { t } = useTranslation();

    // Work out the current award for the school.
    const awardLevel = (model?.awardLevel === 'NoAward' ? undefined : model?.awardLevel) as StandardRating;

    // Generate some URLs and code.
    const linkUrl = `${window.location.protocol}//${window.location.host}/certificate/${model?.id ?? ''}`;
    const imageUrl = `${window.location.protocol}//${window.location.host}/api/webBadge/image/${model?.id ?? ''}`;
    const altText = t('awards.altText', 'The Compliance Standard {{level}} award', { level: (awardLevel ?? 'Commited') });
    const embedCode = 
`<a href="${linkUrl}" target="_blank" rel="noopener noreferrer">
    <img src="${imageUrl}" alt="${altText}" style="max-width: 100px" />
</a>`;

    const [isCertificateMenuOpen, toggleCertificateMenu] = useToggleState();
    const [isEmbedMenuOpen, toggleEmbedMenu] = useToggleState();

    // Copy to clipboard button and menu need special logic.
    // Need a uniqueId for the input so the tooltip can work.
    const copyButtonUniqueId = React.useMemo(() => `copy-to-clipboard-button-${Guid.newGuid()}`, []);

    // Toggle if we're showing the "copied" tooltip.
    const [copiedTooltipIsOpen, setCopiedTooltipOpen] = React.useState<boolean>(false);

    // Copy the copyText to the clipboard.
    const copyToClipboard = React.useCallback((text: string) => {
        try {
            navigator.clipboard.writeText(text ?? '');
            setCopiedTooltipOpen(true);
            setTimeout(() => setCopiedTooltipOpen(false), 2000);
        } catch (error) {
        }
    }, [setCopiedTooltipOpen]);

    // Work out if we have an unclaimed award that the user can claim.
    const hasUnclaimedAwardLevel = model?.unclaimedAwardLevel && model?.awardLevel !== model.unclaimedAwardLevel && model.unclaimedAwardLevel !== 'NoAward';

    // Manage the claim modal.  This can be toggled by a button when an award is available to be claimed, and will automatically open when
    // we first load if we find an award is available for the best user experience.
    const [isClaimModalOpen, _setClaimModalOpen] = React.useState<boolean | undefined>(undefined);
    const toggleClaimModal = React.useCallback(() => _setClaimModalOpen(prevState => !prevState), [_setClaimModalOpen]);
    React.useEffect(() => {
        if (!isNullOrUndefined(isClaimModalOpen)) {
            return;
        }

        if (hasUnclaimedAwardLevel) {
            toggleClaimModal();
        }
    }, [isClaimModalOpen, hasUnclaimedAwardLevel, refreshModel, toggleClaimModal]);

    // Claim the unclaimed award.
    const [createSchoolAward, { errors: createSchoolAwardErrors }] = useCreateSchoolAwardCallback();
    const [refreshWebBadgeQueryString, setRefreshWebBadgeQueryString] = React.useState<string>('');
    const [claimAward, { isExecuting: isClaimingAward, errors: claimAwardErrors, }] = useAsyncCallback(async () => {
        // If we don't have an unclaimed level, do nothing,
        if (!model?.unclaimedAwardLevel || model?.unclaimedAwardLevel === model?.awardLevel) {
            return;
        }

        // Claim the award on the backend.
        await createSchoolAward({
            ...schoolAwardDefaultValues(),
            schoolId: schoolId,
            standardRating: model?.unclaimedAwardLevel ?? '',
        } as SchoolAwardCreateInput);

        // Refresh the model to update the screen to match the new award.
        refreshModel();

        // Force the web badge to refresh by passing a new query string to force the browser to bypass the cache and go to the server again.
        setRefreshWebBadgeQueryString(`?ts=${encodeURIComponent(moment().toISOString())}`)
    }, [model?.unclaimedAwardLevel, createSchoolAward, schoolId, refreshModel]);

    // Generate the certificate report in PDF format.
    const [generateCertifiatePdf, { isExecuting: isGeneratingCertificatePdf, errors: generateCertificatePdfErrors }] = useAsyncCallback(async () => {
        // Generate a QRcode to the certificate URL.
        const qrcodeUrl = await QRCode.toDataURL(linkUrl);

        // Generation of the PDF report.
        const blobData = await generatePdfAsBlob(CertificatePdf({
            model: model ?? ({} as schoolCertificateQuery_model),
            qrcodeUrl,
            t,
        }));

        return blobData;
    }, [model, linkUrl, t]);

    //// Open the PDF in a new window ready for printing.
    //const [printCertificate] = useAsyncCallback(async () => {
    //    const blobData = await generateCertifiatePdf();

    //    // Open the certificate in a new tab.
    //    const url = URL.createObjectURL(blobData);
    //    window.open(url);
    //}, [generateCertifiatePdf]);

    // Download the PDF certificate
    const [downloadCertificate] = useAsyncCallback(async () => {
        const blobData = await generateCertifiatePdf();

        let awardLevelFilename = model?.awardLevel ?? "NoAward";
        if (awardLevelFilename === "NoAward") {
            awardLevelFilename = "Commited";
        }
        FileSaver.saveAs(blobData, `${model?.schoolName} - ${awardLevelFilename} Certificate.pdf`);
    }, [generateCertifiatePdf, model]);

    return (
        <Background className="awards">
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h2 className="text-muted">
                                {t('awards.heading', 'Awards')}
                            </h2>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <ButtonGroup>
                                <ButtonAsync color="primary" onClick={downloadCertificate}
                                    isExecuting={isGeneratingCertificatePdf}
                                    executingChildren={<><Spinner size="sm" /><> </>{t('awards.downloadCertificate.generating', 'Preparing certificate...')}</>}
                                >
                                    <FontAwesomeIcon icon="download" />
                                    <> </>
                                    {t('awards.downloadCertificate.text', 'Download certificate')}
                                </ButtonAsync>
                                <ButtonDropdown isOpen={isCertificateMenuOpen} toggle={() => toggleCertificateMenu()}>
                                    <DropdownToggle color="primary">
                                        <FontAwesomeIcon icon="ellipsis-v" />
                                        <span className="sr-only">
                                            <> </>{t('awards.menu', 'Menu')}
                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem href={imageUrl} download>
                                            {t('awards.downloadImage', 'Download image for marketing')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    {/* If we have an unclaimed award level that is different to our current claimed award level let the user know they can claim it. */}
                    <ConditionalFragment showIf={!!hasUnclaimedAwardLevel}>
                        <Card body>
                            <Row>
                                <Col xs={12} md="auto">
                                    <AreaCertificateThumbnail awardLevel={model?.unclaimedAwardLevel ?? ''} />
                                </Col>
                                <Col>
                                    <p>
                                        {t('awards.unclaimedBanner.text', 'Your school has now met all the requirements for The Compliance Standard {{awardLevel}} award.  You can claim the award now to unlock your certificate and web badge.', { awardLevel: model?.unclaimedAwardLevel, })}
                                    </p>
                                </Col>
                                <Col xs="auto">
                                    <Button color="primary" onClick={() => toggleClaimModal()}>
                                        <FontAwesomeIcon icon="award" />
                                        <> </>
                                        {t('awards.unclaimedBanner.buttonText', 'Claim now')}
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </ConditionalFragment>
                </StickyToolbar>
            </Banner>
            <Container fluid className="mt-2">
                <AlertOnErrors errors={[loadingErrors, createSchoolAwardErrors, claimAwardErrors, generateCertificatePdfErrors, ]} />

                <Row>
                    <Col xs={12} lg={6}>
                        <AwardImage standardRating={(model?.awardLevel as StandardRating) ?? undefined} />
                    </Col>
                    <Col xs={12} lg={6}>
                        <Card body className="mt-4">
                            <ListGroup flush>
                                {
                                    model?.areas?.map(item => {
                                        // Don't show anything where the user hasn't earnt an award level yet.
                                        if ((item.awardLevel ?? 'NoAward') === 'NoAward') {
                                            return null;
                                        }

                                        // Render the item.
                                        return (
                                            <AreaCertificateItem
                                                key={item.id}
                                                model={item}
                                            />
                                        );
                                    })
                                }
                            </ListGroup>
                        </Card>

                        <Card body>
                            <Row>
                                <Col xs={12} md="auto">
                                    <div>
                                        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                                            <img src={`${imageUrl}${refreshWebBadgeQueryString}`} alt={altText} style={{ maxWidth: '100px' }}
                                            />
                                        </a>
                                    </div>
                                </Col>
                                <Col>
                                    <p>
                                        {t('awards.embed.text', 'You can use this HTML code to create a web badge on your site that can be clicked for visitors to view your award and a simple breakdown.  The web badge is a live badge and will be automatically upgraded as you unlock new levels.')}
                                    </p>
                                </Col>
                            </Row>

                            <div>
                                <code lang="html" className="awards-code-block">
                                    <pre>
                                        {embedCode}
                                    </pre>
                                </code>
                            </div>

                            <Row>
                                <Col>
                                </Col>
                                <Col xs="auto">
                                    <ButtonGroup>
                                        <Button id={copyButtonUniqueId} onClick={() => copyToClipboard(embedCode)}>
                                            {t('awards.copyDigitalAwardButton', 'Copy web badge embed code')}
                                        </Button>
                                        <ButtonDropdown isOpen={isEmbedMenuOpen} toggle={() => toggleEmbedMenu()}>
                                            <DropdownToggle>
                                                <FontAwesomeIcon icon="ellipsis-v" />
                                                <span className="sr-only">
                                                    <> </>{t('actionCard.menu', 'Menu')}
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem onClick={() => copyToClipboard(imageUrl)}>
                                                    {t('awards.copyImage', 'Copy web badge image URL')}
                                                </DropdownItem>
                                                <DropdownItem onClick={() => copyToClipboard(linkUrl)}>
                                                    {t('awards.copyImage', 'Copy certificate link URL')}
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </ButtonGroup>
                                    <Tooltip target={copyButtonUniqueId} placement="bottom" isOpen={copiedTooltipIsOpen}>
                                        {t('awards.copiedToClipboard', 'Copied to clipboard')}
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <ConditionalFragment showIf={!!isClaimModalOpen}>
                <ClaimAwardModal
                    isOpen={!!isClaimModalOpen}
                    toggle={toggleClaimModal}
                    awardLevel={model?.unclaimedAwardLevel ?? ''}
                    claimAward={async () => { await claimAward(); toggleClaimModal(); }}
                    isClaimingAward={isClaimingAward}
                />
            </ConditionalFragment>
        </Background>
        );
};
