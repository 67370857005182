import * as React from "react";
import moment, { Moment } from "moment";
import { Guid } from 'guid-string';
import { useTranslation } from 'react-i18next';
import { ModelArrayChanges } from "../../shared/useChanges";
import { Action } from "../../api/main/models/Action";
import { Requirement } from "../../api/main/models/Requirement";
import { actionStates } from '../../services/actionStates/actionStates';

export interface AddOrUpdateActionsOptions {
    actionsManager: ModelArrayChanges<Action, string>,
    schoolId: number,
}

/**
 * Method to add or update the action for a requirement.
 *
 * This method is shared between SchoolAreaCompliance and SchoolOverview and so has been abstracted into its own hook to avoid duplication of
 * business logic.
 * 
 * @param options
 */
export function useAddOrUpdateAction(options: AddOrUpdateActionsOptions) {
    const {
        actionsManager,
        schoolId,
    } = options;

    const { t } = useTranslation();

    const addOrUpdateAction = React.useCallback((requirement: Requirement, reason: 'userAdded' | 'review' | 'failed' | 'delete', isAutoAction: boolean, date?: string) => {
        let name = reason === 'userAdded' ? t('schoolAreaCompliance.actions.userAdded.name', 'Requirement "{{requirementName}}" has been added to the action plan for further action.', { requirementName: requirement.name })
            : reason === 'review' ? t('schoolAreaCompliance.actions.review.name', 'Requirement "{{requirementName}}" needs to be reviewed.', { requirementName: requirement.name })
                : reason === 'failed' ? t('schoolAreaCompliance.actions.review.name', 'Requirement "{{requirementName}}" has not been met.', { requirementName: requirement.name })
                    : '';

        // Lookup an action for this requirement.
        const action = actionsManager.model.find(item => item.requirementOriginKey === requirement.originKey && item.isAutomaticFromRequirementCompliance === isAutoAction && !item.archived && !item.completedDate);

        // Work out the due date.
        let dueDate: Moment = moment();
        if (date) {
            dueDate = moment(date);
        } else if (reason === 'review') {
            // Review due dates need to be calcualted based on the requirement's settings.
            dueDate.add(requirement.requiresReviewInDefaultMonths, 'month');
        } else {
            // Everything else has a 1 month due date by default (user can change it on the action itself). 
            dueDate.add(1, 'month');
        }


        if (reason === 'delete') {
            if (action) {
                actionsManager.removeFor(action.id);
            }
        } else {
            if (!action) {
                actionsManager.addFor({
                    id: Guid.newGuid(),
                    schoolId: schoolId,
                    name: name,
                    descriptionHtml: '',
                    archived: false,
                    requirementOriginKey: requirement.originKey,
                    isAutomaticFromRequirementCompliance: isAutoAction,
                    actionAddedDate: dueDate.toISOString(),
                    actionState: actionStates.outstanding.id,
                    completedDate: null,
                    notesHtml: '',
                    isPinned: false,
                });
            } else {
                if (isAutoAction) {
                    actionsManager.changeFor(action.id, {
                        name: name, // Update the name (e.g for swapping between reviewed and failed).
                        actionAddedDate: dueDate.toISOString(), // Update the due date.
                    });
                } else {
                    // Do nothing, we have already added ourselves to the actions and don't want to override anything.
                }
            }
        }
    }, [actionsManager, schoolId, t]);

    return addOrUpdateAction;
}
