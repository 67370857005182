import * as React from 'react';
import { Action } from '../../api/main/models/Action';
import { ActionUserTask } from '../../api/main/models/ActionUserTask';
import { Modal, ModalHeader, Row, Col, ModalBody, FormGroup, Label, Button, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useTranslation } from 'react-i18next';
import { HtmlEditor } from '../../shared/htmlEditor';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import { ConditionalFragment } from 'react-conditionalfragment';
import moment from 'moment';
import { actionStates, ActionState } from '../../services/actionStates/actionStates';
import { Requirement } from '../../api/main/models/Requirement';
import { RequirementsReleaseRequirement } from '../../api/main/models/RequirementsReleaseRequirement';
import { getComplianceRequirementUrl } from '../../utilities/getComplianceRequirementUrl';
import { Link } from 'react-router-dom';
import { School } from '../../api/main/models/School';
import { User } from '../../api/main/models/User';
import { DueDateLabel } from '../shared/DueDateLabel';
import { ValidatedDateInput } from '../shared/ValidatedDateInput';
import { useChanges } from '../../shared/useChanges';
import "./editActionModal.scss";
import { useActionColor } from './useActionColor';

export interface EditActionModalProps {
    isOpen: boolean,
    toggle: () => void,

    model: Action,
    change: (changes: Partial<Action>) => void,
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    tasks: Array<ActionUserTask>,
    changeTask: (id: string, changes: Partial<ActionUserTask>) => void,
    addTask: () => void,
    removeTask: (id: string) => void,

    requirement: Requirement | undefined | null,
    requirementLink: RequirementsReleaseRequirement | undefined | null,
    user: User | undefined | null,
    school: School | undefined | null,

    users: Array<{ id: string, firstname: string, surname: string }> | null | undefined,

    saveDebounce: () => void,
    askIfWantToContinueWorkflowOnCompletion: () => void,
}

/**
 * Edit an action.
 * @param props
 */
export const EditActionModal = (props: EditActionModalProps) => {
    const {
        isOpen, toggle: _toggle,
        model: underlyingModel, change: underlyingChange, remove,
        /*validate,*/ validationErrors,
        tasks, changeTask: underlyingChangeTask, /*addTask, removeTask,*/
        requirement, requirementLink, user, school,
        users,
        saveDebounce: _saveDebounce,
        askIfWantToContinueWorkflowOnCompletion,
    } = props;
    const { t } = useTranslation();

    // Create a local overlay of the model so our changes can be handled locally and synced back to the overall array on demand rather than automatically.
    // This creates a better user experience (things don't move around unexpectedly) and a much faster user experience too.
    const { model, change, changes } = useChanges(underlyingModel);

    // Get the first task, as we want to be able to modify that.  If we don't have one wait as ActionCard will create one for us.
    // NOTE the database still supports multiple tasks for an action, but the UI now only cares about using tasks for who is assigned, so we flatten
    // it in the UI here to a single task.
    // As with model we also want to keep a localised version of this for performance reasons.
    const underlyingTask = tasks.length ? tasks[0] : undefined;
    //React.useEffect(() => {
    //    if (!underlyingTask) {
    //        addTask();
    //    }
    //}, [underlyingTask, addTask]);
    const { model: task, change: changeTask, changes: taskChanges } = useChanges(underlyingTask);

    // Commit local changes made by change up to our parent by passing them on to realChange.
    const saveDebounce = React.useCallback(() => {
        underlyingChange(changes);
        underlyingChangeTask(task.id, taskChanges);
        _saveDebounce();
    }, [changes, taskChanges, _saveDebounce, underlyingChange, underlyingChangeTask, task]);

    // When we toggle off, make sure we trigger a final save.
    const toggle = React.useCallback(() => {
        saveDebounce();
        _toggle();
    }, [_toggle, saveDebounce]);

    // Get the current state of the action.
    const actionState = React.useMemo(() => actionStates.findById(model?.actionState), [model]);

    // Change the state of this task
    const changeState = React.useCallback((state: ActionState) => {
        change({
            actionState: state.id,
            completedDate: state.isCompleted ? moment().toISOString() : null,
        });

        if (state.isCompleted) {
            askIfWantToContinueWorkflowOnCompletion();
        }

        saveDebounce();
    }, [change, saveDebounce, askIfWantToContinueWorkflowOnCompletion]);

    // What colour should we be?
    const color = useActionColor(model);

    return (
        <Modal size="xl" isOpen={isOpen} toggle={toggle} color={color} className={`modal-${color} edit-action-modal` /* Have to do this ourselves as setting color doesn't work */} modalClassName="root-app-modal">
            <ModalHeader toggle={toggle}>
                {model.name}
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Row>
                        <Col>
                            <Label htmlFor="name">{t('editActionModal.name', 'Action')}</Label>
                        </Col>
                        <ConditionalFragment showIf={!actionState.isCompleted}>
                            <Col xs="auto">
                                <DueDateLabel date={model?.actionAddedDate} />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    
                    <Row>
                        <Col>
                            <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} />
                        </Col>
                        <Col xs="auto">
                            <Button color="link" className="text-danger" onClick={() => { remove(); toggle(); saveDebounce(); }}>
                                <FontAwesomeIcon icon="trash" />
                                <span className="sr-only">{t('editActionModal.delete', 'Delete action')}</span>
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>

                <Row>
                    <Col xs={12} md="">
                        <FormGroup>
                            <Label htmlFor="actionAddedDate">{t('editActionModal.actionAddedDate', 'Due date')}</Label>
                            <ValidatedDateInput name="actionAddedDate" type="date" value={model?.actionAddedDate ?? ''} onChange={e => change({ actionAddedDate: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col xs={12} md="auto">
                        <FormGroup>
                            <ConditionalFragment showIf={!!model.completedDate}>
                                <Label htmlFor="completedDate">
                                    {t('editActionModal.completedDate', 'Completed on')}
                                </Label>
                                <Row>
                                    <Col>
                                        <ValidatedDateInput name="completedDate" type="date" value={model?.completedDate ?? ''} onChange={e => change({ completedDate: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} />
                                    </Col>
                                    <Col xs="auto">
                                        <Button color="danger" outline onClick={() => changeState(actionStates.outstanding)}>
                                            <FontAwesomeIcon icon="undo" />
                                            <> </>{t('editActionModal.undoCompletion', 'Undo completion')}
                                        </Button>
                                    </Col>
                                </Row>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!model.completedDate}>
                                <Label htmlFor="completedDate">&nbsp;</Label>
                                <div>
                                    <Button color="success" outline onClick={() => changeState(actionStates.completed)}>
                                        <FontAwesomeIcon icon="check" />
                                        <> </>
                                        {t('editActionModal.complete', 'Complete')}
                                    </Button>
                                </div>
                            </ConditionalFragment>
                        </FormGroup>
                    </Col>
                </Row>


                <ConditionalFragment showIf={!!requirement}>
                    <FormGroup>
                        <Label htmlFor="requirement">{t('editActionModal.requirement', 'Related requirement')}</Label>
                        <div>
                            <Link to={getComplianceRequirementUrl(requirement?.originKey ?? '', requirementLink?.requirementAreaId ?? '', user, school?.id)}>
                                {requirement?.name ?? ''}
                            </Link>
                        </div>
                    </FormGroup>
                </ConditionalFragment>

                <FormGroup>
                    <Label htmlFor="actionAssignedTo">{t('actionCard.task.userId.label', 'Assigned to')}</Label>
                    {
                        task ? (
                            <ValidatedInput key={task.id} name="userId" type="select" value={task?.userId ?? ''} onChange={e => changeTask({ userId: parseInt(e.currentTarget.value) })} onBlur={() => saveDebounce()} validationErrors={validationErrors}>
                                <option value={0}>{t('editActionUserTask.userUnassigned', '(No user assigned yet)')}</option>
                                {
                                    users?.map(item => (
                                        <option key={item.id} value={item.id}>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.firstname, lastName: item.surname })}</option>
                                    ))
                                }
                            </ValidatedInput>
                        ) : null
                    }
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="descriptionHtml">{t('editActionModal.descriptionHtml', 'Notes')}</Label>
                    <HtmlEditor value={model?.descriptionHtml ?? ''} onChange={value => change({ descriptionHtml: value })} onBlur={() => saveDebounce()} />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" outline onClick={() => { saveDebounce(); toggle(); }}>
                    {t('common.close', 'Close')}
                </Button>
            </ModalFooter>
        </Modal>
        );
}
