import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { SchoolSchoolPhaseUpdateInput } from '../generated/globalTypes';
import { updateSchoolSchoolPhaseMutation, updateSchoolSchoolPhaseMutationVariables } from '../generated/updateSchoolSchoolPhaseMutation';
import { schoolSchoolPhaseFieldsFragment } from '../models/SchoolSchoolPhase';

/**
 * Get a callback to update a SchoolSchoolPhase in the store.
 */
export function useUpdateSchoolSchoolPhaseCallback(): [(id: string, model: SchoolSchoolPhaseUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateSchoolSchoolPhaseMutation, updateSchoolSchoolPhaseMutationVariables>(
        gql`
            mutation updateSchoolSchoolPhaseMutation ($model: SchoolSchoolPhaseUpdateInput!) {
                updateSchoolSchoolPhase(model: $model) {
                    ...schoolSchoolPhaseFields
                }
            }

            ${schoolSchoolPhaseFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: SchoolSchoolPhaseUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
