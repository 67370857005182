import * as React from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import { AlertOnErrors } from "../../shared/alertOnErrors";
import "./watchVideoModal.scss";
import { useWatchVideoModalViewModel } from "../../api/main/videos/viewModels/useWatchVideoModalViewModel";
import { VideoComponent } from './VideoComponent';
import { HtmlDisplay } from '../../shared/htmlEditor/HtmlDisplay';

export interface WatchVideoModalProps {
    id: string,
    isOpen: boolean,
    toggle: () => void,
}

/**
 * Modal for watching a video.
 * @param props
 */
export const WatchVideoModal = (props: WatchVideoModalProps) => {
    const {
        id,
        isOpen,
        toggle,
    } = props;


    const { data: { model }, errors: loadErrors } = useWatchVideoModalViewModel(id);

    return (
        <Modal className="watch-video-modal" size="xl" isOpen={isOpen} toggle={toggle}>
            <ModalBody>
                <div className="watch-video-modal-close" onClick={() => toggle()}>
                    &times;
                </div>

                <AlertOnErrors errors={[loadErrors]} />

                <VideoComponent videoBlob={model?.videoBlob} thumbnailBlob={model?.thumbnailBlob} autoPlay={true} />
                
                <div className="watch-video-modal-name">
                    <Row>
                        <Col>
                            {model?.name}
                        </Col>
                        <Col xs={12} md="auto" className="text-muted">
                            {model?.authorName}
                        </Col>
                    </Row>
                </div>

                <div className="watch-video-modal-description">
                    <HtmlDisplay sanatizedHtml={model?.description ?? ''} />
                </div>
            </ModalBody>
        </Modal>
    );
};
