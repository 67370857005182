import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { StandardRatingComplianceProgressBar } from './StandardRatingComplianceProgressBar';
import "./complianceProgressBarPreCalculated.scss";
import { ConditionalFragment } from 'react-conditionalfragment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrentAward } from "./useCalculateCurrentAward/useCalculateCurrentAward";
import { StandardRating } from '../../api/main/models/constants/StandardRatings';

export interface ComplianceProgressBarPreCalculatedProps {
    size?: 'md' | 'lg',

    currentAward: CurrentAward,

    // NOTE for these props null means unknonw, undefined means not passed
    bronzeInitiallyComplete?: boolean | null,
    silverInitiallyComplete?: boolean | null,
}

/**
 * Progress bar for an compliance of a school's compliance.
 * @param props
 */
export const ComplianceProgressBarPreCalculated = (props: ComplianceProgressBarPreCalculatedProps) => {
    const {
        size = 'md',
        currentAward,
        bronzeInitiallyComplete,
        silverInitiallyComplete,
    } = props;

    // Calculator our compliance across the different levels.
    const {
        bronzeCompliance,
        silverCompliance,
        goldCompliance,
    } = currentAward;

    const anyBronze = (bronzeCompliance.unanswered + bronzeCompliance.failed + bronzeCompliance.success > 0);
    const bronzeComplete = currentAward.currentLevel === StandardRating.Gold || currentAward.currentLevel === StandardRating.Silver || currentAward.currentLevel === StandardRating.Bronze;
    const anySilver = (silverCompliance.unanswered + silverCompliance.failed + silverCompliance.success > 0);
    const silverComplete = currentAward.currentLevel === StandardRating.Gold || currentAward.currentLevel === StandardRating.Silver;
    const anyGold = (goldCompliance.unanswered + goldCompliance.failed + goldCompliance.success > 0);
    const goldComplete = currentAward.currentLevel === StandardRating.Gold;


    // Render a series of progress bars to match the schools current progress level.
    return (
        <Row className={`compliance-progress-bar compliance-progress-bar-${size}`} noGutters>
            {/* Bronze */}
            <ConditionalFragment showIf={anyBronze}>
                <Col xs="auto" style={{ zIndex: 99 }}>
                    <div className="compliance-progress-bar-award-thumbnail compliance-progress-bar-award-thumbnail-noaward">
                        <FontAwesomeIcon icon="check-circle" />
                    </div>
                </Col>
                <Col className={`compliance-progress-bar-bar-container-bronze ${bronzeComplete ? 'compliance-progress-bar-minimized' : ''}`}>
                    <StandardRatingComplianceProgressBar
                        size={size}
                        compliance={bronzeCompliance}
                    />
                </Col>
                <Col xs="auto" style={{ zIndex: 99 }}>
                    <div className="compliance-progress-bar-award-thumbnail compliance-progress-bar-award-thumbnail-bronze">
                        <ConditionalFragment showIf={bronzeComplete}>
                            <FontAwesomeIcon icon="check-circle" />
                        </ConditionalFragment>
                    </div>
                </Col>
            </ConditionalFragment>

            {/* Silver - only shown once bronze has been completed. */}
            <ConditionalFragment showIf={anySilver}>
                <Col className={`compliance-progress-bar-bar-container-silver ${silverComplete ? 'compliance-progress-bar-minimized' : ''}`} style={{ maxWidth: bronzeComplete ? undefined : '0px', transition: bronzeInitiallyComplete || !bronzeComplete ? undefined : 'max-width 2s' }}>
                    <StandardRatingComplianceProgressBar
                        size={size}
                        compliance={silverCompliance}
                    />
                </Col>
                <ConditionalFragment showIf={bronzeComplete}>
                    <Col xs="auto" style={{ zIndex: 99 }}>
                        <div className="compliance-progress-bar-award-thumbnail compliance-progress-bar-award-thumbnail-silver">
                            <ConditionalFragment showIf={silverComplete}>
                                <FontAwesomeIcon icon="check-circle" />
                            </ConditionalFragment>
                        </div>
                    </Col>
                </ConditionalFragment>
            </ConditionalFragment>

            {/* Gold - only show if bronze and silver have been completed. */}
            <ConditionalFragment showIf={anyGold}>
                <Col className="compliance-progress-bar-bar-container-gold" style={{ maxWidth: silverComplete ? undefined : '0px', transition: silverInitiallyComplete || !silverComplete ? undefined : 'max-width 2s' }}>
                    <StandardRatingComplianceProgressBar
                        size={size}
                        compliance={goldCompliance}
                    />
                </Col>
                <ConditionalFragment showIf={silverComplete}>
                    <Col xs="auto" style={{ zIndex: 99 }}>
                        <div className="compliance-progress-bar-award-thumbnail compliance-progress-bar-award-thumbnail-gold">
                            <ConditionalFragment showIf={goldComplete}>
                                <FontAwesomeIcon icon="check-circle" />
                            </ConditionalFragment>
                        </div>
                    </Col>
                </ConditionalFragment>
            </ConditionalFragment>
        </Row>
    );
};
