import * as React from "react";
import { useIsElementVisible } from "./useIsElementVisible";

export interface RenderIfVisibleProps {
    children: React.ReactNode,

    /**
     * What to render if we are not rendering children.
     */
    fallback?: React.ReactNode,

    /**
     * When true we will unmount the component each time it scrolls off screen (which will loose any component state etc.).
     * Otherwise we simply postpone rendering until the component is first on screen, and then continue to render regardless of visiblity after that.
     *
     * Default is false.
     */
    unmountIfInvisible?: boolean,
}

/**
 * Component that will render its contents if it is visible on the screen, and otherwise will render fallback if supplied.
 * @param props
 */
export const RenderIfVisible = (props: RenderIfVisibleProps) => {
    const {
        children,
        fallback,
        unmountIfInvisible = false,
    } = props;

    const ref = React.useRef<HTMLDivElement | null>(null);
    const isVisible = useIsElementVisible(ref, { notifyVisibleOnly: !unmountIfInvisible, });

    return (
        <div ref={ref}>
            {
                isVisible ? children: fallback
            }
        </div>
        );
};
