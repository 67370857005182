import './sidebarNavTitle.scss';

import * as React from 'react';
import { NavItem } from 'reactstrap';

export interface SidebarNavTitleProps {
    className?: string,
    child?: boolean,
    children?: React.ReactNode,
}

export const SidebarNavTitle = (props: SidebarNavTitleProps) => {
    const { className, child, children } = props;

    return (
        <NavItem className={'nav-title ' + (child? 'nav-title-child': '') + (className ?? '')}>
            {children}
        </NavItem>
    );
};
