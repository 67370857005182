import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { ReportsOverview } from "./ReportsOverview";
import { ViewReportFromLink } from "./ViewReportFromLink";

export const reportsOverviewRoutes: Array<RouteEntry> = [
    { path: '/reports', exact: true, component: ReportsOverview, authorize: true },
    { path: '/reports/:schoolId', exact: true, component: ReportsOverview, authorize: true, requireRole: IdentityRoles.MATUser },

    { path: '/link/report/:id/:checksum', component: ViewReportFromLink, authorize: false, },
];
