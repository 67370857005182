import * as React from "react";
import { SchoolRequirementCompliance } from '../../../api/main/models/SchoolRequirementCompliance';
import { CurrentAward, calculateCurrentAward } from "./useCalculateCurrentAward";
import { SchoolSchoolType } from "../../../api/main/models/SchoolSchoolType";
import { SchoolSchoolPhase } from "../../../api/main/models/SchoolSchoolPhase";
import { filterRequirementsForSchool } from "../../schoolOverview/useRequirementsForSchool/filterRequirementsForSchool";
import { currentRequirementsReleaseQuery_model_requirements_requirement } from "../../../api/main/generated/currentRequirementsReleaseQuery";
import { RequirementsReleaseRequirement } from "../../../api/main/models/RequirementsReleaseRequirement";


export type SchoolCurrentAwardMap = { [id: string]: CurrentAward };

/**
 * Filter the requirements by school type and school phase return the visible requirements for each school..
 */
export function useCalculateCurrentAwardForSchools(
    schools: Array<{ id: string }>,
    allRequirements: Array<currentRequirementsReleaseQuery_model_requirements_requirement>,
    allCompliances: Array<SchoolRequirementCompliance>,
    schoolSchoolTypes: Array<SchoolSchoolType>,
    schoolSchoolPhases: Array<SchoolSchoolPhase>,
    requirementsReleaseRequirements: Array<RequirementsReleaseRequirement> | null | undefined
): SchoolCurrentAwardMap {
    const ret = React.useMemo(() => {
        const map: SchoolCurrentAwardMap = {};

        // For each school, calculate the results.
        for (const school of schools) {
            const schoolId = parseInt(school.id);

            // Filter the requirments to be included on the needs of the school.
            const mySchoolSchoolTypes = schoolSchoolTypes.filter(it => it.schoolId === schoolId);
            const mySchoolSchoolPhases = schoolSchoolPhases.filter(it => it.schoolId === schoolId);

            const requirements = filterRequirementsForSchool(allRequirements ?? [], mySchoolSchoolTypes, mySchoolSchoolPhases);

            // Get compliances for this school.
            const compliances = allCompliances?.filter(it => it.schoolId === schoolId);

            // Calculate the current award for the school based on the appropriate requirements.
            const award = calculateCurrentAward(requirements, compliances, requirementsReleaseRequirements);

            map[schoolId] = award;
        }

        return map;
    }, [schools, allRequirements, allCompliances, schoolSchoolTypes, schoolSchoolPhases, requirementsReleaseRequirements]);

    return ret;
}
