import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "reactstrap";
import { RequirementsFilterOptions } from "./useRequirementsFilterOptions";
import "./requirementsFilter.scss";

export interface RequirementsFilterProps {
    filter: RequirementsFilterOptions,
    toggleFilter: (key: keyof RequirementsFilterOptions) => void,
    resetFilter: () => void,
}

/**
 * Component to show the RequirementsFilter for the SchoolOver and related screens.
 * @param props
 */
export const RequirementsFilter = (props: RequirementsFilterProps) => {
    const {
        filter,
        toggleFilter,
        resetFilter,
    } = props;

    const { t } = useTranslation();

    return (
        <div className="requirements-filter">
            <div>
                {t('schoolOverview.showOnly.label', 'Show: ')}
                <ButtonGroup size="sm">
                    <Button className="requirements-filter-bronze" outline={!filter.bronze} onClick={() => toggleFilter('bronze')}>
                        {t('schoolOverview.filter.bronze', 'Bronze')}
                    </Button>
                    <Button className="requirements-filter-silver" outline={!filter.silver} onClick={() => toggleFilter('silver')}>
                        {t('schoolOverview.filter.silver', 'Silver')}
                    </Button>
                    <Button className="requirements-filter-gold" outline={!filter.gold} onClick={() => toggleFilter('gold')}>
                        {t('schoolOverview.filter.gold', 'Gold')}
                    </Button>
                </ButtonGroup>
                <> </>
                <ButtonGroup size="sm">
                    <Button className="requirements-filter-unanswered" outline={!filter.unanswered} onClick={() => toggleFilter('unanswered')}>
                        {t('schoolOverview.filter.unanswered', 'Unanswered')}
                    </Button>
                    <Button color="danger" outline={!filter.notMet} onClick={() => toggleFilter('notMet')}>
                        {t('schoolOverview.filter.notMet', 'Not met')}
                    </Button>
                    <Button color="success" outline={!filter.met} onClick={() => toggleFilter('met')}>
                        {t('schoolOverview.filter.met', 'Met')}
                    </Button>
                </ButtonGroup>
            </div>
            <div>
                <Button color="link" style={{ paddingLeft: '0px', }} onClick={resetFilter}>
                    {t('schoolOverview.filter.reset', 'Reset filters')}
                </Button>
            </div>
        </div>
    );
};
