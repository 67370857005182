import * as React from 'react';
import { SchoolRequirementComplianceLinkCreateInput, SchoolRequirementComplianceLinkUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSchoolRequirementComplianceLinkCallback } from './useCreateSchoolRequirementComplianceLinkCallback';
import { useUpdateSchoolRequirementComplianceLinkCallback } from './useUpdateSchoolRequirementComplianceLinkCallback';
import { SchoolRequirementComplianceLink } from '../models/SchoolRequirementComplianceLink';

/**
 * Get a callback to save a SchoolRequirementComplianceLink in the store using either a create or update.
 */
export function useSaveSchoolRequirementComplianceLinkCallback(options: SaveInStoreOptions<SchoolRequirementComplianceLink, string> = {}) {
    const [_create, createStatus] = useCreateSchoolRequirementComplianceLinkCallback();
    const create = React.useCallback((model: Partial<SchoolRequirementComplianceLink>) => _create(model as SchoolRequirementComplianceLinkCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSchoolRequirementComplianceLinkCallback();
    const update = React.useCallback((id: string, changes: Partial<SchoolRequirementComplianceLink>) => _update(id, changes as SchoolRequirementComplianceLinkUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
