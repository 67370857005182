import gql from "graphql-tag";
import { policyManagerMandatoryPolicyFields } from "../generated/policyManagerMandatoryPolicyFields";

export const policyManagerMandatoryPolicyFieldsFragment = gql`
    fragment policyManagerMandatoryPolicyFields on PolicyManagerMandatoryPolicy {
        id
        title
        articleId
        isDeleted
    }
`;


export type PolicyManagerMandatoryPolicy = Omit<policyManagerMandatoryPolicyFields, '__typename'>;
