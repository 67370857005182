import gql from "graphql-tag";
import { schoolSchoolPhaseFields } from "../generated/schoolSchoolPhaseFields";
import { schoolPhaseFieldsFragment } from "./SchoolPhase";

export const schoolSchoolPhaseFieldsFragment = gql`
    fragment schoolSchoolPhaseFields on SchoolSchoolPhase {
        id
        schoolId
        schoolPhaseId

        schoolPhase {
            ...schoolPhaseFields
        }
    }

    ${schoolPhaseFieldsFragment}
`;


export type SchoolSchoolPhase = Omit<schoolSchoolPhaseFields, '__typename' | 'schoolPhase'>;
