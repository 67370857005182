import { RouteEntry } from "../shared/routes";
import { apiAuthorizationRoutes } from "../components/api-authorization/routes";
import { NotFound } from "../components/shared/NotFound";
import { administrationSidebarRoutes, administrationRoutes } from "../components/administration/routes";
import { homeRoutes } from "../components/home/routes";
import { requriementRoutes } from "../components/requirements/routes";
import { schoolPhaseRoutes } from "../components/schoolPhases/routes";
import { schoolTypeRoutes } from "../components/schoolTypes/routes";
import { schoolOverviewRoutes } from "../components/schoolOverview/routes";
import { schoolComplianceRoutes } from "../components/schoolCompliance/routes";
import { actionRoutes } from "../components/actions/routes";
import { schoolRoutes } from "../components/schools/routes";
import { schoolGroupRoutes } from "../components/schoolGroups/routes";
import { matOverviewRoutes } from "../components/matOverview/routes";
import { accountRoutes } from "../components/account/routes";
import { apiAuthorizationConfig } from "./apiAuthorizationConfig";
import { reportsOverviewRoutes } from "../components/reportsOverview/routes";
import { adminReportsRoutes } from "../components/adminReports/routes";
import { schoolSidebarRoutes } from "../components/schoolNavigation/routes";
import { matSidebarRoutes } from "../components/matOverview/routes";
import { awardsRoutes } from "../components/awards/routes";
import { schoolSettingsRoutes } from "../components/schoolSettings/routes";
import { certificateRoutes } from "../components/certificates/routes";
import { videoRoutes } from "../components/videos/routes";

export const appRoutes: Array<RouteEntry> = [
    ...administrationRoutes,
    ...apiAuthorizationRoutes,
    ...homeRoutes,

    // Add the developer login routes if we have develoer logins switched on.
    ...(apiAuthorizationConfig.allowDeveloperLogin ? accountRoutes : []),

    ...actionRoutes,
    ...adminReportsRoutes,
    ...awardsRoutes,
    ...certificateRoutes,
    ...matOverviewRoutes,
    ...schoolGroupRoutes,
    ...reportsOverviewRoutes,
    ...requriementRoutes,
    ...schoolComplianceRoutes,
    ...schoolOverviewRoutes,
    ...schoolPhaseRoutes,
    ...schoolRoutes,
    ...schoolSettingsRoutes,
    ...schoolTypeRoutes,
    ...videoRoutes,

    { path: undefined, component: NotFound }
];

export const sidebarRoutes: Array<RouteEntry> = [
    ...schoolSidebarRoutes,
    ...matSidebarRoutes,
    ...administrationSidebarRoutes,

];
