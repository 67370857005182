import i18next from "i18next";

export interface RequirementComplianceState {
    id: string,
    localizedName: () => string,
    isUnanswered: boolean,
    isSuccess: boolean,
    isFailure: boolean,
    isSkipped: boolean,
}

class RequirementComplianceStates {
    /**
     * Answer has not been given.
     */
    unanswered = { id: '', localizedName: () => i18next.t('requirementComplianceStates.unanswered.name', 'Not answer given'), isUnanswered: true, isSuccess: false, isFailure: false, isSkipped: false } as RequirementComplianceState;

    /**
     * Requirement has been met.
     */
    success = { id: 'success', localizedName: () => i18next.t('requirementComplianceStates.success.name', 'Compliant'), isUnanswered: false, isSuccess: true, isFailure: false, isSkipped: false } as RequirementComplianceState;

    /**
     * Requirement has been failed.
     */
    failed = { id: 'failed', localizedName: () => i18next.t('requirementComplianceStates.failed.name', 'Failed'), isUnanswered: false, isSuccess: false, isFailure: true, isSkipped: false } as RequirementComplianceState;

    /**
     * Requirement has been skipped (as not relivant to the school).
     */
    skipped = { id: 'skipped', localizedName: () => i18next.t('requirementComplianceStates.skipped.name', 'Not relevant'), isUnanswered: false, isSuccess: false, isFailure: false, isSkipped: true } as RequirementComplianceState;

    /**
     * Access to all available link types as an array.
     */
    items = [
        this.unanswered,
        this.success,
        this.failed,
        this.skipped,
    ];

    /**
     * Find a state from its id.
     * @param id
     */
    findById = (id: string | undefined | null): RequirementComplianceState  => {
        const ret = this.items.find(item => item.id === id);
        if (!ret) {
            return this.unanswered;
        }

        return ret;
    }
}

/**
 * Requirement compliance states.
 */
export const requirementComplianceStates = new RequirementComplianceStates();

