import * as React from 'react';
import { Requirement } from '../../api/main/models/Requirement';
import { Badge, Row, Col, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroupItem, ButtonGroup } from 'reactstrap';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchoolRequirementCompliance } from '../../api/main/models/SchoolRequirementCompliance';
import { requirementComplianceStates } from '../../services/requirementComplianceStates/requirementComplianceStates';
import { useToggleState } from 'use-toggle-state';
import { User } from '../../api/main/models/User';
import { School } from '../../api/main/models/School';
import { getStandardRatingMetadata, StandardRating } from '../../api/main/models/constants/StandardRatings';
import { useChangeOrToggleComplianceState } from '../schoolCompliance/useChangeOrToggleComplianceState';
import { Link } from 'react-router-dom';
import { getActionUrl } from '../../utilities/getActionUrl';
import "./requirementComplianceCutdownItem.scss";
import { Action } from '../../api/main/models/Action';
import { useChanges } from '../../shared/useChanges';
import { useDebounce } from '../shared/hooks/useDebounce';
import { StandardsHelpModal } from '../schoolCompliance/StandardsHelpModal';
import { getComplianceRequirementUrl } from '../../utilities/getComplianceRequirementUrl';

export interface RequirementComplianceProps {
    requirement: Requirement,
    schoolCompliance: SchoolRequirementCompliance | undefined | null,
    changeCompliance: (changes: Partial<SchoolRequirementCompliance>) => void,
    saveComplianceDebounce: () => void,

    action: Action | undefined | null,
    addOrUpdateAction: (reason: 'userAdded' | 'review' | 'failed' | 'delete', isAutoAction: boolean, date?: string) => void,

    user: User | undefined | null,
    school: School | undefined | null,
    areaId: string,

    //video: schoolOverviewSupportingDataQuery_videos | undefined | null,
}

/**
 * Requirement presented for people to indicate their schools compliance.
 * @param props
 */
export const RequirementComplianceCutdownItem = (props: RequirementComplianceProps) => {
    const {
        requirement,
        schoolCompliance: underlyingSchoolCompliance, changeCompliance: underlyingChangeCompliance, saveComplianceDebounce: _saveComplianceDebounce,
        action, addOrUpdateAction,
        user,
        school,
        areaId,
        //video,
    } = props;

    const { t } = useTranslation();

    // Create a local overlay of the model so our changes can be handled locally and synced back to the overall array on demand rather than automatically.
    // This creates a much less laggy user experience.
    const { model: schoolCompliance, change: changeCompliance, changes: schoolComplianceChanges } = useChanges(underlyingSchoolCompliance);

    // Commit local changes made by change up to our parent by passing them on to realChange.
    const commitChanges = React.useCallback(() => {
        underlyingChangeCompliance(schoolComplianceChanges);
        _saveComplianceDebounce();
    }, [schoolComplianceChanges, _saveComplianceDebounce, underlyingChangeCompliance]);

    // We have use a debounce timer ourselves here to cope with issues around the onBlur event of the date input field.
    const saveComplianceDebounce = useDebounce(() => commitChanges());

    // State of our compliance.
    const complianceState = React.useMemo(() => requirementComplianceStates.findById(schoolCompliance?.requirementComplianceState), [schoolCompliance]);

    // Change the state of our compliance, if we are changing to the same state as it currently is then toggle it to unanswered instead.
    const changeOrToggleComplianceState = useChangeOrToggleComplianceState({
        addOrUpdateAction,
        changeCompliance,
        complianceState,
        requirement,
        saveComplianceDebounce,
    });

    const standardRatingMetadata = getStandardRatingMetadata((requirement?.standardRating as StandardRating | undefined) ?? StandardRating.Bronze);

    // Dropdown menu.
    const [isMenuOpen, toggleMenu] = useToggleState();

    // Standard levels help.
    const [isStandardHelpModalOpen, toggleStandardHelpModal] = useToggleState();

    return (
        <ListGroupItem className="requirement-compliance-cutdown-item" tag="div">
            <Row>
                <Col>
                    <div className={complianceState.isSkipped ? 'text-muted' : ''}>
                        <Link to={getComplianceRequirementUrl(requirement.originKey, areaId, user, school?.id)} className="requirement-compliance-cutdown-item-requirement-text-link">
                            {requirement.name}
                        </Link>
                    </div>
                    <Badge style={{ backgroundColor: standardRatingMetadata.color, cursor: 'pointer' }} onClick={() => toggleStandardHelpModal()}>
                        <FontAwesomeIcon icon="award" />
                        <> </>
                        {standardRatingMetadata.displayName}
                    </Badge>
                </Col>
                {/* Show the video if we have one */}
                {/* NOTE disabled at the request of Hub4Leaders as they will only have 6 videos at launch */}
                {/*{*/}
                {/*    video ? (*/}
                {/*        <Col xs={12} md="auto">*/}
                {/*            <VideoTile*/}
                {/*                size="xs"*/}
                {/*                model={video}*/}
                {/*                thumbnailBlob={video.thumbnailBlob}*/}
                {/*            />*/}
                {/*        </Col>*/}
                {/*    ): null*/}
                {/*}*/}
                <Col xs={12} md={5} lg={/*!!video? 2:*/ 4} className="text-right">
                    <ConditionalFragment showIf={!complianceState.isSkipped}>
                        <Row noGutters>
                            <Col>
                            </Col>
                            <Col xs="auto">
                                <ButtonGroup>
                                    <Button size="sm" color="success" outline={!complianceState.isSuccess} onClick={() => changeOrToggleComplianceState(requirementComplianceStates.success)}>
                                        <FontAwesomeIcon icon="check" />
                                        <> </>
                                        {t('requirement.compliances.toggle.notMet', 'Yes')}
                                    </Button>
                                    <Button size="sm" color="danger" outline={!complianceState.isFailure} onClick={() => changeOrToggleComplianceState(requirementComplianceStates.failed)}>
                                        <FontAwesomeIcon icon="times" />
                                        <> </>
                                        {t('requirement.compliances.toggle.notMet', 'No')}
                                    </Button>
                                </ButtonGroup>
                            </Col>

                            <Col xs="auto">
                                <ButtonDropdown isOpen={isMenuOpen} toggle={() => toggleMenu()}>
                                    <DropdownToggle size="sm" color="link" outline className="action-card-menu-dropdown-toggle">
                                        <FontAwesomeIcon icon="ellipsis-v" />
                                        <span className="sr-only">
                                            <> </>{t('actionCard.menu', 'Menu')}
                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={() => changeOrToggleComplianceState(requirementComplianceStates.skipped)}>
                                            <FontAwesomeIcon icon="times" />
                                            <> </>
                                            {t('requirementCompliance.notRelevant', 'This requirement is not relevant to our school')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                    {
                        complianceState.isSuccess ? (
                            <div className="text-success">
                                {t('requirementCompliance.compliant', 'Our school meets this requirement')}
                            </div>
                        ) : complianceState.isFailure ? (
                            <div className="text-danger">
                                    {t('requirementCompliance.compliant', 'Our school is working towards this requirement.')}
                                    <ConditionalFragment showIf={!!action}>
                                        <> </>
                                        <Link to={getActionUrl(action?.id ?? '', user, school?.id)}>
                                            {t('requirementCompliance.seeAction', 'See action.')}
                                        </Link>
                                    </ConditionalFragment>
                            </div>
                        ) : complianceState.isSkipped ? (
                            <div className="text-muted">
                                {t('requirementCompliance.skipped', 'This requirement is not relevant to our school')}
                                <> </>
                                <Button size="sm" outline onClick={() => changeOrToggleComplianceState(requirementComplianceStates.skipped)}>
                                    <FontAwesomeIcon icon="undo" />
                                    <> </>
                                    {t('requirementCompliance.undoSkip', 'Undo')}
                                </Button>
                            </div>
                        ) : null
                    }
                </Col>
            </Row>

            <ConditionalFragment showIf={isStandardHelpModalOpen}>
                <StandardsHelpModal isOpen={isStandardHelpModalOpen} toggle={() => toggleStandardHelpModal()} />
            </ConditionalFragment>
        </ListGroupItem>
        );
};
