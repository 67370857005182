import i18next from "i18next";

/**
 * Available standard rating values.
 */
export enum StandardRating {
    Gold = 'Gold',
    Silver = 'Silver',
    Bronze = 'Bronze',
}

/**
 * StandardReating values as an array.
 */
export const standardRatings = Object.values(StandardRating);

/**
 * Metadata per standard rating.
 */
export interface StandardRatingMetadata {
    id: StandardRating,
    displayName: string,
    color: string,
}

/**
 * Get the metadata for a standard rating.
 */
export function getStandardRatingMetadata(value: StandardRating): StandardRatingMetadata {
    switch (value) {
        case StandardRating.Gold:
            return {
                id: value,
                displayName: i18next.t('getStandardRatingMetadata.gold.displayName', 'Gold'),
                color: '#91630f',
            } as StandardRatingMetadata;
        case StandardRating.Silver:
            return {
                id: value,
                displayName: i18next.t('getStandardRatingMetadata.silver.displayName', 'Silver'),
                color: '#5c5c5c',
            } as StandardRatingMetadata;
        case StandardRating.Bronze:
            return {
                id: value,
                displayName: i18next.t('getStandardRatingMetadata.bronze.displayName', 'Bronze'),
                color: '#bf692a',
            } as StandardRatingMetadata;
    }
}
