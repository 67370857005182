import i18next from "i18next";

export interface PolicyManagerLinkType {
    id: string,
    localizedName: () => string,
    requiresLink: boolean,
}


const _policyManagerLinkTypes = {
    /**
     * Requirement is not linked.
     */
    notLinked: { id: '', localizedName: () => i18next.t('policyManagerLinkTypes.notLinked.name', 'Not linked'), requiresLink: false, } as PolicyManagerLinkType,

    /**
     * Requirement is linked.
     */
    linked: { id: 'linked', localizedName: () => i18next.t('policyManagerLinkTypes.linked.name', 'Linked to policy manager'), requiresLink: true, } as PolicyManagerLinkType,
};

/**
 * Integration types for a policy manager.
 */
export const policyManagerLinkTypes = {
    ..._policyManagerLinkTypes,

    /**
     * Access to all available link types as an array.
     */
    items: [
        _policyManagerLinkTypes.notLinked,
        _policyManagerLinkTypes.linked,
    ],
};
