import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { SchoolAwardCreateInput } from '../generated/globalTypes';
import { createSchoolAwardMutation, createSchoolAwardMutationVariables } from '../generated/createSchoolAwardMutation';
import { schoolAwardFieldsFragment } from '../models/SchoolAward';

/**
 * Get a callback to create a SchoolAward in the store.
 */
export function useCreateSchoolAwardCallback(): [(model: SchoolAwardCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createSchoolAwardMutation, createSchoolAwardMutationVariables>(
        gql`
            mutation createSchoolAwardMutation ($model: SchoolAwardCreateInput!) {
                createSchoolAward(model: $model) {
                    ...schoolAwardFields
                }
            }

            ${schoolAwardFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: SchoolAwardCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
