import gql from "graphql-tag";
import { schoolReportFields } from "../generated/schoolReportFields";
import { blobFieldsFragment } from "./Blob";

export const schoolReportFieldsFragment = gql`
    fragment schoolReportFields on SchoolReport {
        id
        schoolId
        date
        userId
        isDetailed
        areas
        blobId

        blob {
            ...blobFields
        }
    }

    ${blobFieldsFragment}
`;


export type SchoolReport = Omit<schoolReportFields, '__typename' | 'blob'>;
