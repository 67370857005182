import gql from "graphql-tag";
import { schoolGroupFields } from "../generated/schoolGroupFields";

export const schoolGroupFieldsFragment = gql`
    fragment schoolGroupFields on SchoolGroup {
        id
        schoolGroupName
    }
`;


export type SchoolGroup = Omit<schoolGroupFields, '__typename' | 'groupType'>;
