import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const SidebarNav = (props: any) => {
    return (
        <PerfectScrollbar className="sidebar-nav">
            {props.children}
        </PerfectScrollbar>
    );
};
