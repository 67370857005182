import * as React from 'react';
import { RequirementsReleaseRequirementAreaCreateInput, RequirementsReleaseRequirementAreaUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateRequirementsReleaseRequirementAreaCallback } from './useCreateRequirementsReleaseRequirementAreaCallback';
import { useUpdateRequirementsReleaseRequirementAreaCallback } from './useUpdateRequirementsReleaseRequirementAreaCallback';
import { RequirementsReleaseRequirementArea } from '../models/RequirementsReleaseRequirementArea';

/**
 * Get a callback to save a RequirementsReleaseRequirementArea in the store using either a create or update.
 */
export function useSaveRequirementsReleaseRequirementAreaCallback(options: SaveInStoreOptions<RequirementsReleaseRequirementArea, string> = {}) {
    const [_create, createStatus] = useCreateRequirementsReleaseRequirementAreaCallback();
    const create = React.useCallback((model: Partial<RequirementsReleaseRequirementArea>) => _create(model as RequirementsReleaseRequirementAreaCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateRequirementsReleaseRequirementAreaCallback();
    const update = React.useCallback((id: string, changes: Partial<RequirementsReleaseRequirementArea>) => _update(id, changes as RequirementsReleaseRequirementAreaUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
