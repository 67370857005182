import './matSummaryCard.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col } from 'reactstrap';


export interface MATSummaryCardProps {
    goldDisplayName: string,
    silverDisplayName: string,
    bronzeDisplayName: string,

    goldSchools: number,
    silverSchools: number,
    bronzeSchools: number,
    noAwardSchools: number,
}

/**
 * Card component that lists all area and a schools compliance to it.
 * @param props
 */
export const MATSummaryCard = (props: MATSummaryCardProps) => {
    const {
        //schoolGroupId,
        //requirements, compliances, schoolIds,
        bronzeSchools, silverSchools, goldSchools, noAwardSchools
    } = props;
    const { t } = useTranslation();

    return (
        <Card className="mat-summary-card">
            <CardBody tag="div">
                <div className="mat-summary-card-progress-container">
                    <Row className="mb-2">
                        <Col xs="auto">
                            <div className="mat-summary-card-award-thumbnail mat-summary-card-award-thumbnail-gold">
                            </div>
                        </Col>
                        <Col>
                            {
                                t('matSummaryCard.goldSummary', '{{count}} schools have achieved {{goldDisplayName}} compliance standard.', {
                                    count: goldSchools,
                                    goldDisplayName: props.goldDisplayName
                                })
                            }
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col xs="auto">
                            <div className="mat-summary-card-award-thumbnail mat-summary-card-award-thumbnail-silver">
                            </div>
                        </Col>
                        <Col>
                            {
                                t('matSummaryCard.silverSummary', '{{count}} schools have achieved {{silverDisplayName}} compliance standard.', {
                                    count: silverSchools,
                                    silverDisplayName: props.silverDisplayName
                                })
                            }
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col xs="auto">
                            <div className="mat-summary-card-award-thumbnail mat-summary-card-award-thumbnail-bronze">
                            </div>
                        </Col>
                        <Col>
                            {
                                t('matSummaryCard.bronzeSummary', '{{count}} schools have achieved {{bronzeDisplayName}} compliance standard.', {
                                    count: bronzeSchools,
                                    bronzeDisplayName: props.bronzeDisplayName
                                })
                            }
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col xs="auto">
                            <div className="mat-summary-card-award-thumbnail mat-summary-card-award-thumbnail-none">
                            </div>
                        </Col>
                        <Col>
                            {
                                t('matSummaryCard.noStandardSummary', '{{count}} schools are yet to achieve a compliance standard.',
                                    {
                                        count: noAwardSchools
                                    }
                                )
                            }
                        </Col>
                    </Row>
                </div >
            </CardBody >
        </Card>
    );
};
