import * as React from 'react';
import { Modal, ModalHeader, ModalBody, Label, Button, ModalFooter, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Requirement } from '../../api/main/models/Requirement';
import { LinkContainer } from 'react-router-bootstrap';
import { getComplianceRequirementUrl } from '../../utilities/getComplianceRequirementUrl';
import { Link } from 'react-router-dom';
import { RequirementsReleaseRequirement } from '../../api/main/models/RequirementsReleaseRequirement';
import { School } from '../../api/main/models/School';
import { User } from '../../api/main/models/User';

export interface CreateNextReviewActionModalProps {
    isOpen: boolean,
    toggle: () => void,

    model: Requirement,
    requirementLink: RequirementsReleaseRequirement | null | undefined,
    user: User | null | undefined,
    school: School | null | undefined,
}

/**
 * Link the user to a requirement when completing a not-met action.
 * @param props
 */
export const UpdateRequirementModal = (props: CreateNextReviewActionModalProps) => {
    const {
        isOpen, toggle,
        model,
        requirementLink,
        user, school,
    } = props;
    const { t } = useTranslation();

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle} modalClassName="root-app-modal">
            <ModalHeader toggle={toggle}>
                {t('updateRequirementModal.heading', 'Time to update your compliance')}
            </ModalHeader>
            <ModalBody>
                <p>
                    <strong>
                        {t('updateRequirementModal.message', 'Now you have completed these tasks, it’s time to review this requirement and update your compliance status. Click the link below to change your compliance status now.')}
                    </strong>
                </p>
                <FormGroup>
                    <Label htmlFor="name">
                        {t('updateRequirementModal.requirement', 'Requirement')}
                    </Label>
                    <div>
                        <Link to={getComplianceRequirementUrl(model?.originKey ?? '', requirementLink?.requirementAreaId ?? '', user, school?.id)}>
                            {model.name}
                        </Link>
                    </div>
                </FormGroup>                
            </ModalBody>
            <ModalFooter>
                <LinkContainer to={getComplianceRequirementUrl(model?.originKey ?? '', requirementLink?.requirementAreaId ?? '', user, school?.id)}>
                    <Button color="success">
                        {t('updateRequirementModal.save', 'Review and update')}
                    </Button>
                </LinkContainer>
                <> </>
                <Button color="danger" onClick={() => toggle()}>
                    {t('updateRequirementModal.skip', 'Skip')}
                </Button>
            </ModalFooter>
        </Modal>
        );
}