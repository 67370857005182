import * as React from 'react';

/**
 * Returns a hook based callback that can be used to fetch more data in a controlled way and will automatically stop fetching when it reaches the end,
 * which it identifies by a call that returns less data than we are expecting.
 * 
 * You can check if there is more using the hasMore() function returned in its state.
 */
export function useFetchMoreCallback<T extends {} = any>(
    fetchMoreFromStore: (...args: any) => Promise<{ data: T }>,
    currentEndOffset: number | undefined | null,
    merge: (prevResult: T | undefined | null, newResult: T | undefined | null) => T | undefined | null,
    newResultLength: (newResult: T | undefined | null) => number | undefined | null
): { fetchMore: () => void, hasMore: () => boolean }
{
    const [_hasMore, setHasMore] = React.useState<boolean>(true);

    // Read more data.
    const fetchMore = React.useCallback(() => {
        fetchMoreFromStore({
            variables: { offset: currentEndOffset ?? 0 },
            updateQuery: (previousQueryResult: T | undefined | null, { fetchMoreResult }: { fetchMoreResult: (T | undefined | null) }) => {
                if (!fetchMoreResult) {
                    return previousQueryResult;
                }

                // If we didn't read any extra results, tell the cursor its reached the end.
                if (!newResultLength(fetchMoreResult)) {
                    setHasMore(false);
                }

                // Merge everything together.
                return merge(previousQueryResult, fetchMoreResult);

            }
        });
    }, [fetchMoreFromStore, currentEndOffset, merge, newResultLength]);

    // Is there any more data available?  True until fetchMore reads past the end of the data.
    const hasMore = React.useCallback(() => _hasMore, [_hasMore]);

    return React.useMemo(() => ({
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [fetchMore, hasMore]);
} 