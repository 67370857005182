import './banner.scss';

import * as React from 'react';
import { Container } from 'reactstrap';

export interface BannerProps {
    children?: React.ReactNode,
    className?: string,
    fluid?: boolean,
}

/**
 * Banner to show title and toolbars at the top of pages.
 * @param props
 */
export const Banner = (props: BannerProps) => {
    const { children, className, fluid } = props;

    return (
        <div className={`banner ${fluid? 'banner-fluid': ''} ${className ?? ''}`}>
            <Container fluid={fluid}>
                {children}
            </Container>
        </div>
        );
};
