import * as React from 'react';
import { RequirementsReleaseRequirementCreateInput, RequirementsReleaseRequirementUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateRequirementsReleaseRequirementCallback } from './useCreateRequirementsReleaseRequirementCallback';
import { useUpdateRequirementsReleaseRequirementCallback } from './useUpdateRequirementsReleaseRequirementCallback';
import { RequirementsReleaseRequirement } from '../models/RequirementsReleaseRequirement';

/**
 * Get a callback to save a RequirementsReleaseRequirement in the store using either a create or update.
 */
export function useSaveRequirementsReleaseRequirementCallback(options: SaveInStoreOptions<RequirementsReleaseRequirement, string> = {}) {
    const [_create, createStatus] = useCreateRequirementsReleaseRequirementCallback();
    const create = React.useCallback((model: Partial<RequirementsReleaseRequirement>) => _create(model as RequirementsReleaseRequirementCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateRequirementsReleaseRequirementCallback();
    const update = React.useCallback((id: string, changes: Partial<RequirementsReleaseRequirement>) => _update(id, changes as RequirementsReleaseRequirementUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
