import { User } from "../api/main/models/User";

/**
 * Returns a link to the compliance area provided.
 */
export function getComplianceAreaUrl(requirementAreaId: string, user: User | undefined | null, schoolId: string | undefined | null): string {
    // If this is the user's own school, use the route that doesn't require MAT permissions.
    if (schoolId === user?.schoolId?.toString()) {
        return `/compliance/${requirementAreaId ?? ''}`;
    }

    // Otherwise use the route that includes the school.
    return `/compliance-for/${schoolId}/${requirementAreaId ?? ''}`;
}
