import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, FormText, Row, Col, Spinner } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useEditSchoolTypesAndPhasesModalViewModel } from '../../api/main/schoolOverview/useEditSchoolTypesAndPhasesModalViewModel';
import { useChangesArray } from '../../shared/useChanges';
import { SchoolSchoolType } from '../../api/main/models/SchoolSchoolType';
import { SchoolSchoolPhase } from '../../api/main/models/SchoolSchoolPhase';
import { useSaveSchoolSchoolPhaseCallback } from '../../api/main/schoolSchoolPhases/useSaveSchoolSchoolPhaseCallback';
import { useDeleteSchoolSchoolPhaseCallback } from '../../api/main/schoolSchoolPhases/useDeleteSchoolSchoolPhaseCallback';
import { useSaveSchoolSchoolTypeCallback } from '../../api/main/schoolSchoolTypes/useSaveSchoolSchoolTypeCallback';
import { useDeleteSchoolSchoolTypeCallback } from '../../api/main/schoolSchoolTypes/useDeleteSchoolSchoolTypeCallback';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';

export interface EditSchoolTypesAndPhasesModalProps {
    isOpen: boolean,
    toggle: () => void,

    schoolId: string | number,
    mandatory?: boolean,
}

/**
 * Modal to let the user link existing requirements into new sections.
 * @param props
 */
export const EditSchoolTypesAndPhasesModal = (props: EditSchoolTypesAndPhasesModalProps) => {
    const {
        isOpen, toggle: _toggle,
        schoolId: _schoolId,
        mandatory = false,
    } = props;
    const schoolId: number = parseInt(_schoolId?.toString() ?? '0');

    const { t } = useTranslation();

    const { data: { schoolTypes, schoolPhases, schoolSchoolTypes: storeSchoolSchoolTypes, schoolSchoolPhases: storeSchoolSchoolPhases }, isLoading, errors: loadErrors } = useEditSchoolTypesAndPhasesModalViewModel(schoolId.toString());

    const schoolTypeLinksManager = useChangesArray<SchoolSchoolType, string>(storeSchoolSchoolTypes, item => item.id);
    const schoolPhaseLinksManager = useChangesArray<SchoolSchoolPhase, string>(storeSchoolSchoolPhases, item => item.id);

    // Toggle needs special handling to cope with the mandatory flag.  When passed as true this flag ensures
    // at least one type and one phase is selected before allowing the modal to close.
    const toggle = React.useCallback(() => {
        if (mandatory) {
            if (!schoolPhaseLinksManager.model.length || !schoolTypeLinksManager.model.length) {
                // Do nothing until we have at least one type and one phase selected.
                return;
            }
        }

        _toggle();
    }, [_toggle, mandatory, schoolPhaseLinksManager, schoolTypeLinksManager]);



    const [saveSchoolTypeLink, { errors: saveSchoolTypeLinkErrors }] = useSaveSchoolSchoolTypeCallback();
    const [removeSchoolTypeLink, { errors: removeSchoolTypeLinkErrors }] = useDeleteSchoolSchoolTypeCallback();

    const [saveSchoolPhaseLink, { errors: saveSchoolPhaseLinkErrors }] = useSaveSchoolSchoolPhaseCallback();
    const [removeSchoolPhaseLink, { errors: removeSchoolPhaseLinkErrors }] = useDeleteSchoolSchoolPhaseCallback();

    // Is this phase applicable to this requirement?
    const isPhaseApplicable = React.useCallback((schoolPhaseId: string) => {
        return !!schoolPhaseLinksManager.model.find(it => it.schoolId === schoolId && it.schoolPhaseId === schoolPhaseId);
    }, [schoolPhaseLinksManager, schoolId]);
    // Toggle if this phase is applicable to this requirement.
    const togglePhaseApplicable = React.useCallback((schoolPhaseId: string) => {
        const existing = schoolPhaseLinksManager.model.find(it => it.schoolId === schoolId && it.schoolPhaseId === schoolPhaseId);
        if (existing) {
            schoolPhaseLinksManager.removeFor(existing.id);
        } else {
            schoolPhaseLinksManager.addFor({ id: Guid.newGuid(), schoolId: schoolId, schoolPhaseId: schoolPhaseId });
        }
    }, [schoolPhaseLinksManager, schoolId]);

    // Is this type applicable to this requirement?
    const isTypeApplicable = React.useCallback((schoolTypeId: string) => {
        return !!schoolTypeLinksManager.model.find(it => it.schoolId === schoolId && it.schoolTypeId === schoolTypeId);
    }, [schoolTypeLinksManager, schoolId]);
    // Toggle if this type is applicable to this requirement.
    const toggleTypeApplicable = React.useCallback((schoolTypeId: string) => {
        const existing = schoolTypeLinksManager.model.find(it => it.schoolId === schoolId && it.schoolTypeId === schoolTypeId);
        if (existing) {
            schoolTypeLinksManager.removeFor(existing.id);
        } else {
            schoolTypeLinksManager.addFor({ id: Guid.newGuid(), schoolId: schoolId, schoolTypeId: schoolTypeId });
        }
    }, [schoolTypeLinksManager, schoolId]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options: { dontNavigateBack?: boolean } = {}) => {
        // Save all the school type links.
        for (const item of schoolTypeLinksManager.added) {
            await saveSchoolTypeLink(item.id, schoolTypeLinksManager.changesFor(item.id), true);
        }
        for (const item of schoolTypeLinksManager.updated) {
            await saveSchoolTypeLink(item.id, schoolTypeLinksManager.changesFor(item.id), false);
        }
        for (const item of schoolTypeLinksManager.removed) {
            await removeSchoolTypeLink(item.id);
        }
        schoolTypeLinksManager.markAsSaved();

        // Save all the school phase links.
        for (const item of schoolPhaseLinksManager.added) {
            await saveSchoolPhaseLink(item.id, schoolPhaseLinksManager.changesFor(item.id), true);
        }
        for (const item of schoolPhaseLinksManager.updated) {
            await saveSchoolPhaseLink(item.id, schoolPhaseLinksManager.changesFor(item.id), false);
        }
        for (const item of schoolPhaseLinksManager.removed) {
            await removeSchoolPhaseLink(item.id);
        }
        schoolPhaseLinksManager.markAsSaved();

        // Close the modal.
        toggle();
    }, [
        schoolPhaseLinksManager, saveSchoolPhaseLink, removeSchoolPhaseLink,
        schoolTypeLinksManager, saveSchoolTypeLink, removeSchoolTypeLink,
        toggle,
    ]);

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle} modalClassName="root-app-modal">
            <ModalHeader toggle={toggle}>
                <Row>
                    <Col>
                        {t('editSchoolTypesAndPhasesModal.heading', 'Tell us about your school so we can monitor the best compliance requirements for you')}
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </ModalHeader>
            <ModalBody>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveSchoolTypeLinkErrors, removeSchoolTypeLinkErrors,
                    saveSchoolPhaseLinkErrors, removeSchoolPhaseLinkErrors,
                    saveFormErrors,
                ]} />
                <FormGroup>
                    <Label htmlFor="schoolType">{t('editSchoolTypesAndPhasesModal.schoolTypesLabel', 'What type is your school?')}</Label>
                    <div>
                        {
                            schoolTypes?.map(item => (
                                <Button key={item.id} size="sm" color="primary" outline={!isTypeApplicable(item.id)} className="mr-1"
                                    onClick={() => toggleTypeApplicable(item.id)}
                                >
                                    {item.name}
                                </Button>
                            ))
                        }
                    </div>
                    <FormText>
                        {t('editSchoolTypesAndPhasesModal.schoolTypesHint', 'Select all types that apply to your school.  This helps us show you requirements and advice that is designed for your school.')}
                    </FormText>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="schoolPhase">{t('editSchoolTypesAndPhasesModal.schoolPhasesLabel', 'Which phases does your school cover?')}</Label>
                    <div>
                        {
                            schoolPhases?.map(item => (
                                <Button key={item.id} size="sm" color="primary" outline={!isPhaseApplicable(item.id)} className="mr-1"
                                    onClick={() => togglePhaseApplicable(item.id)}
                                >
                                    {item.name}
                                </Button>
                            ))
                        }
                    </div>
                    <FormText>
                        {t('editSchoolTypesAndPhasesModal.schoolTypesHint', 'Select all phases that apply to your school.  This helps us show you requirements and advice that is designed for your school.')}
                    </FormText>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <ConditionalFragment showIf={!isLoading}>
                    <ButtonAsync color="primary" isExecuting={isSaving}
                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}
                        onClick={() => saveForm()}>
                        <FontAwesomeIcon icon="save" />
                        <> {t('common.save', 'Save')}</>
                    </ButtonAsync>
                </ConditionalFragment>
                <ConditionalFragment showIf={!mandatory}>
                    <Button color="primary" outline onClick={() => toggle()}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </ConditionalFragment>
            </ModalFooter>
        </Modal>
    );
};
