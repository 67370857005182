import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../shared/abstractStore';
import { lookupSchoolGroupForSchoolQuery, lookupSchoolGroupForSchoolQueryVariables } from '../generated/lookupSchoolGroupForSchoolQuery';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { schoolGroupFieldsFragment } from '../models/SchoolGroup';

/**
 * Find the school group for a school using the school and group type passed in.
 * @param id
 * @param options
 */
export function useLookupSchoolGroupForSchool(schoolId: string | undefined | null, schoolGroupTypeId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<lookupSchoolGroupForSchoolQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<lookupSchoolGroupForSchoolQuery, lookupSchoolGroupForSchoolQueryVariables>(
        gql`
        query lookupSchoolGroupForSchoolQuery ($schoolId: ID!, $schoolGroupTypeId: ID!) {
            model: lookupSchoolGroupForSchool (schoolId: $schoolId, schoolGroupTypeId: $schoolGroupTypeId) {
                ...schoolGroupFields

                schools {
                    id
                    schoolName
                }
            }
        }

        ${schoolGroupFieldsFragment}
        `,
        {
            variables: {
                schoolId: schoolId ?? '0',
                schoolGroupTypeId: schoolGroupTypeId ?? '0',
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy && !!schoolId) {
            load();
        }
    }, [options.lazy, load, schoolId]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
