import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { mainApiConfig } from "./mainApiConfig";
import ApolloClient from "apollo-client";
import { CachePersistor } from 'apollo-cache-persist';
import { ApiKeyService } from "../services/ApiKeyService";

const cache = new InMemoryCache();

// Load cache from the persisited storage.
export const apolloCachePersistor = new CachePersistor({
    cache,
    storage: (window.localStorage as any),
});
apolloCachePersistor.persist();

const link = new HttpLink({
    uri: mainApiConfig.endpoint,
    //credentials: 'same-origin',

    fetch: (input: RequestInfo, init?: RequestInit | undefined): Promise<Response> => {
        // Add our API header to all GraphQL requests.
        const apiKeyService = new ApiKeyService();
        return fetch(input, {
            ...(init ?? {}),
            headers: {
                ...(init?.headers ?? {}),
                'x-api-key': apiKeyService.generateKey(),
            }
        });
    }
});

export const apolloClient = new ApolloClient({
    cache,
    link
});