import './requirementCompliance.scss';

import * as React from 'react';
import { Requirement } from '../../api/main/models/Requirement';
import { Card, CardBody, CardTitle, Badge, Row, Col, Button, Collapse, FormGroup, Label, ButtonGroup, Input, Spinner, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormText } from 'reactstrap';
import { HtmlDisplay } from '../../shared/htmlEditor/HtmlDisplay';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { RequirementSchoolBusLink } from '../../api/main/models/RequirementSchoolBusLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchoolRequirementCompliance } from '../../api/main/models/SchoolRequirementCompliance';
import { requirementComplianceStates } from '../../services/requirementComplianceStates/requirementComplianceStates';
import { useToggleState } from 'use-toggle-state';
import { HtmlEditor } from '../../shared/htmlEditor';
import { RequirementComplianceLinkType, requirementComplianceLinkTypes } from '../../services/requirementComplianceLinkTypes/requirementComplianceLinkTypes';
import { SchoolRequirementComplianceLink } from '../../api/main/models/SchoolRequirementComplianceLink';
import { useAsyncCallback } from 'react-use-async-callback';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { useUploadBlobCallback } from '../../api/main/blobs/useUploadBlobCallback';
import { addTrackingToUrl } from '../../utilities/addTrackingToUrl';
import { Action } from '../../api/main/models/Action';
import { Link } from 'react-router-dom';
import { getActionUrl } from '../../utilities/getActionUrl';
import { User } from '../../api/main/models/User';
import { School } from '../../api/main/models/School';
import striptags from 'striptags';
import { fileUploadConfig } from '../../configure/fileUploadConfig';
import { DateInput } from '../shared/DateInput';
import moment from 'moment';
import { isIntegratedInSchoolBus } from '../../configure/integrationConfig';
import { getStandardRatingMetadata, StandardRating } from '../../api/main/models/constants/StandardRatings';
import { useChangeOrToggleComplianceState } from './useChangeOrToggleComplianceState';
import { useChanges } from '../../shared/useChanges';
import { useDebounce } from '../shared/hooks/useDebounce';
import { VideoTile } from '../videos/VideoTile';
import { StandardsHelpModal } from './StandardsHelpModal';
import { schoolAreaComplianceSupportingDataQuery_videos } from '../../api/main/generated/schoolAreaComplianceSupportingDataQuery';

export interface RequirementComplianceProps {
    requirement: Requirement,
    schoolCompliance: SchoolRequirementCompliance | undefined | null,
    changeCompliance: (changes: Partial<SchoolRequirementCompliance>) => void,
    saveComplianceDebounce: () => void,

    links: Array<SchoolRequirementComplianceLink> | undefined | null,
    addLink: (linkType: RequirementComplianceLinkType | null, url?: string, blobId?: string) => void,
    removeLink: (id: string) => void,

    action: Action | undefined | null,
    addOrUpdateAction: (reason: 'userAdded' | 'review' | 'failed' | 'delete', isAutoAction: boolean, date?: string) => void,

    user: User | undefined | null,
    school: School | undefined | null,
    schoolBusLinks: Array<RequirementSchoolBusLink> | undefined | null,

    video: schoolAreaComplianceSupportingDataQuery_videos | undefined | null,
}

/**
 * Requirement presented for people to indicate their schools compliance.
 * @param props
 */
export const RequirementCompliance = (props: RequirementComplianceProps) => {
    const {
        requirement,
        schoolCompliance: underlyingSchoolCompliance, changeCompliance: underlyingChangeCompliance, saveComplianceDebounce: _saveComplianceDebounce,
        links, addLink: _addLink, removeLink: _removeLink,
        action, addOrUpdateAction,
        user, school, schoolBusLinks,
        video,
    } = props;

    const { t } = useTranslation();

    // Create a local overlay of the model so our changes can be handled locally and synced back to the overall array on demand rather than automatically.
    // This creates a much less laggy user experience.
    const { model: schoolCompliance, change: changeCompliance, changes: schoolComplianceChanges } = useChanges(underlyingSchoolCompliance);

    // Commit local changes made by change up to our parent by passing them on to realChange.
    const commitChanges = React.useCallback(() => {
        underlyingChangeCompliance(schoolComplianceChanges);
        _saveComplianceDebounce();
    }, [schoolComplianceChanges, _saveComplianceDebounce, underlyingChangeCompliance]);

    // We have use a debounce timer ourselves here to cope with issues around the onBlur event of the date input field.
    const saveComplianceDebounce = useDebounce(() => commitChanges());


    // State of our compliance.
    const complianceState = React.useMemo(() => requirementComplianceStates.findById(schoolCompliance?.requirementComplianceState), [schoolCompliance]);

    // Change the state of our compliance, if we are changing to the same state as it currently is then toggle it to unanswered instead.
    const changeOrToggleComplianceState = useChangeOrToggleComplianceState({
        addOrUpdateAction,
        changeCompliance,
        complianceState,
        requirement,
        saveComplianceDebounce,
    });

    // Add a linked upload for our compliance.
    const addLink = React.useCallback((linkType: RequirementComplianceLinkType | null, url?: string, blobId?: string) => {
        _addLink(linkType, url, blobId);

        // Save the change (don't wait the result).
        // NOTE as we expect this to be debounced we know the state changes above will go through before this runs.
        saveComplianceDebounce();
    }, [_addLink, saveComplianceDebounce]);

    // Remove a linked upload.
    const removeLink = React.useCallback((id: string) => {
        _removeLink(id);

        // Save the change (don't wait the result).
        // NOTE as we expect this to be debounced we know the state changes above will go through before this runs.
        saveComplianceDebounce();
    }, [_removeLink, saveComplianceDebounce]);

    const isOpen = !complianceState.isSkipped;

    // Find the first available link from the links for the requirement.
    const firstSchoolBusLink = React.useMemo(() => {
        if (!schoolBusLinks?.length) {
            return null;
        }

        return schoolBusLinks[0];
    }, [schoolBusLinks]);

    // Get the filename for a link.
    const getLinkFileName = React.useCallback((link: SchoolRequirementComplianceLink | null | undefined) => {
        if (!link) {
            return null;
        }

        //const type = requirementComplianceLinkTypes.findById(link.linkType);

        // Work out an appropriate filename to show.
        let fileName = link.url ?? t('requirementCompliance.document', 'Document');
        // Sepcial handling of links that go to theschool bus.
        if (link.url === firstSchoolBusLink?.url && !!firstSchoolBusLink?.linkText) {
            fileName = firstSchoolBusLink.linkText;
        } else {
            // Try get the last part of the filename from the url.
            try {
                const url = new URL(link.url);
                if (url.pathname) {
                    let lastSlashIndex = url.pathname.lastIndexOf('/');
                    if (lastSlashIndex >= -1 && lastSlashIndex !== (url.pathname.length - 1)) {
                        fileName = decodeURIComponent(url.pathname.substr(lastSlashIndex + 1));
                    }
                }
            } catch {
                // Do nothing with invalid urls.
            }
        }

        return fileName;
    }, [t, firstSchoolBusLink]);

    // Upload a file.
    const [fileSizeError, setFileSizeError] = React.useState<string>('');
    const [uploadBlob, { errors: uploadBlobErrors }] = useUploadBlobCallback();
    const [uploadFile, { isExecuting: isUploadingFile, errors: uploadFileErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Check that the file is not too large to upload.
        if (files[0].size > fileUploadConfig.maximumFileSize) {
            setFileSizeError(t(
                'requirementCompliance.fileTooLargeError',
                'The maximum file size you can upload is {{maximumFileSizeMb, 0.0}}mb.  This file is {{fileSizeMb, 0.0}}mb and too large to be uploaded.',
                {
                    fileSizeMb: (files[0].size / 1024.0 / 1024.0),
                    maximumFileSizeMb: (fileUploadConfig.maximumFileSize / 1024.0 / 1024.0),
                }
            ))
            return;
        } else {
            setFileSizeError('');
        }

        // Upload the file.
        const blob = await uploadBlob(files);
        addLink(requirementComplianceLinkTypes.fileUpload, blob?.url, blob?.id);
    }, [uploadBlob, addLink, setFileSizeError, t]);

    // Drop down menu for file uploads (used to remove the file).
    const [isUploadMenuOpen, toggleUploadMenuOpen] = useToggleState(false);

    // We want to know if requirement.notesHtml is empty or white space.
    const requirementNotesHtmlIsEmpty = React.useMemo(() => !striptags(requirement?.notesHtml ?? '').replace(/\s/g, '').length, [requirement]);

    // Review data used when creating a review action on the action plan.
    const [reviewDate, setReviewDate] = React.useState<string>(() => moment().add(requirement.requiresReviewInDefaultMonths, 'month').local().toISOString());

    // From a UI perspective (at Hub4Leader's request) we allow a maximum of 3 links to be uploaded.
    const maxLinksCount = 3;

    const standardRatingMetadata = getStandardRatingMetadata((requirement?.standardRating as StandardRating | undefined) ?? StandardRating.Bronze);

    // Dropdown menu.
    const [isMenuOpen, toggleMenu] = useToggleState();

    // Standard levels help.
    const [isStandardHelpModalOpen, toggleStandardHelpModal] = useToggleState();

    return (
        <Card className="requirement-compliance">
            <CardBody tag="div">
                <CardTitle>
                    <Row>
                        <Col>
                            <h5 className={complianceState.isSkipped? 'text-muted': ''}>
                                {requirement.name}
                            </h5>
                            <Badge style={{ backgroundColor: standardRatingMetadata.color, cursor: 'pointer' }} onClick={() => toggleStandardHelpModal()}>
                                <FontAwesomeIcon icon="award" />
                                <> </>
                                {standardRatingMetadata.displayName}
                            </Badge>
                        </Col>
                        <Col xs={12} md={5} lg="auto">
                            <ConditionalFragment showIf={!complianceState.isSkipped}>
                                <Row noGutters>
                                    <Col>
                                    </Col>
                                    <Col xs="auto">
                                        <ButtonGroup>
                                            <Button color="success" outline={!complianceState.isSuccess} onClick={() => changeOrToggleComplianceState(requirementComplianceStates.success)}>
                                                <FontAwesomeIcon icon="check" />
                                                <> </>
                                                {t('requirement.compliances.toggle.notMet', 'Yes')}
                                            </Button>
                                            <Button color="danger" outline={!complianceState.isFailure} onClick={() => changeOrToggleComplianceState(requirementComplianceStates.failed)}>
                                                <FontAwesomeIcon icon="times" />
                                                <> </>
                                                {t('requirement.compliances.toggle.notMet', 'No')}
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                    
                                    <Col xs="auto">
                                        <ButtonDropdown isOpen={isMenuOpen} toggle={() => toggleMenu()}>
                                            <DropdownToggle color="link" outline className="action-card-menu-dropdown-toggle">
                                                <FontAwesomeIcon icon="ellipsis-v" />
                                                <span className="sr-only">
                                                    <> </>{t('actionCard.menu', 'Menu')}
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem onClick={() => changeOrToggleComplianceState(requirementComplianceStates.skipped)}>
                                                    <FontAwesomeIcon icon="times" />
                                                    <> </>
                                                    {t('requirementCompliance.notRelevant', 'This requirement is not relevant to our school')}
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </Col>
                                </Row>
                            </ConditionalFragment>
                            {
                                complianceState.isSuccess ? (
                                    <div className="text-success">
                                        {t('requirementCompliance.compliant', 'Our school meets this requirement')}
                                    </div>
                                ) : complianceState.isFailure ? (
                                        <div className="text-danger">
                                            {t('requirementCompliance.compliant', 'Our school is working towards this requirement.')}
                                            <ConditionalFragment showIf={!!action}>
                                                <> </>
                                                <Link to={getActionUrl(action?.id ?? '', user, school?.id)}>
                                                    {t('requirementCompliance.seeAction', 'See action.')}
                                                </Link>
                                            </ConditionalFragment>
                                        </div>
                                ) : complianceState.isSkipped ? (
                                        <div className="text-muted">
                                            {t('requirementCompliance.skipped', 'This requirement is not relevant to our school')}
                                            <> </>
                                            <Button size="sm" outline onClick={() => changeOrToggleComplianceState(requirementComplianceStates.skipped)}>
                                                <FontAwesomeIcon icon="undo" />
                                                <> </>
                                                {t('requirementCompliance.undoSkip', 'Undo')}
                                            </Button>
                                        </div>
                                    ) : null
                            }
                         </Col>
                    </Row>
                </CardTitle>

                <Collapse isOpen={isOpen}>
                    <Row>
                        <Col>
                            <div>
                                <HtmlDisplay sanatizedHtml={requirement.descriptionHtml} />
                            </div>

                            <ConditionalFragment showIf={!requirementNotesHtmlIsEmpty}>
                                <strong>
                                    {t('requirementCompliance.basisForRequirementHeading', 'Basis for requirement')}
                                </strong>
                                <div>
                                    <HtmlDisplay sanatizedHtml={requirement.notesHtml} />
                                </div>
                            </ConditionalFragment>

                            <ConditionalFragment showIf={!!schoolBusLinks?.length}>
                                <strong>
                                    {t('requirementCompliance.linksHeading', 'Related policies and documents from our library')}
                                </strong>
                                <div>
                                    {
                                        schoolBusLinks?.map(item => (
                                            <div key={item.id}>
                                                <a href={addTrackingToUrl(item.url)} target="_blank" rel="noopener noreferrer">
                                                    {item.linkText || item.url}
                                                </a>
                                            </div>
                                            ))
                                    }
                                </div>
                            </ConditionalFragment>
                        </Col>
                        {/* Show a video if we have one */}
                        <Col xs={12} md="auto">
                            {
                                video ? (
                                    <VideoTile
                                        size="sm"
                                        model={video}
                                        thumbnailBlob={video.thumbnailBlob}
                                        />
                                    ): null
                            }
                        </Col>
                    </Row>

                    <Collapse isOpen={!complianceState.isUnanswered}>
                        <FormGroup className="mt-2">
                            <Label htmlFor="notes">{t('requirementCompliance.notesLabel', 'Please add contextual notes here to explain your decision.')}</Label>
                            <HtmlEditor value={schoolCompliance?.notesHtml ?? ''} onChange={value => changeCompliance({ notesHtml: value })} onBlur={() => saveComplianceDebounce()} />
                        </FormGroup>

                        <ConditionalFragment showIf={requirement?.allowEvidenceUpload ?? false}>
                            <FormGroup className="mt-2">
                                <Label htmlFor="notes">{t('requirementCompliance.allowEvidenceUploadLabel', 'If you want to, you can attach or upload your policy or supporting documentation as evidence to support your answer.')}</Label>
                                <AlertOnErrors errors={[uploadBlobErrors, uploadFileErrors]} />
                                <AlertOnErrors simple errors={[fileSizeError ? { message: fileSizeError } : null]} />
                                {
                                    links?.map(link => (
                                        <div className="mb-1">
                                            <ConditionalFragment showIf={!!link}>
                                                {
                                                    link?.linkType === requirementComplianceLinkTypes.policyManager.id ? (
                                                        <a href={addTrackingToUrl(`${isIntegratedInSchoolBus? '' : 'https://www.theschoolbus.net'}/compliancemanager?policy=${link?.url}`)} target="_blank" rel="noopener noreferrer">
                                                            {t('requirementCompliance.linkToPolicyManagerText', 'This requirement has already been responded to on Policy Manager and you can find it here')}
                                                        </a>
                                                    ) : (
                                                            <a href={addTrackingToUrl(link?.url)} target="_blank" rel="noopener noreferrer">
                                                                {getLinkFileName(link) || link?.url || ''}
                                                            </a>
                                                        )
                                                }
                                            </ConditionalFragment>
                                        </div>
                                        ))
                                }
                                <div className="mt-1">
                                    <ButtonGroup>
                                        <Label className={`btn btn-outline-primary requirement-compliance-upload-button ${(links?.length ?? 0) >= maxLinksCount? 'text-muted': ''}`}>
                                            {
                                                isUploadingFile ? (
                                                    <>
                                                        <Spinner size="sm" />
                                                        <> </>{t('requirementCompliance.uploading', 'Uploading...')}
                                                    </>
                                                ) : (links?.length ?? 0) >= 1 ? (
                                                        <>{t('requirementCompliance.uploadAnotherFile', 'Upload another file...')}</>
                                                ) : (
                                                        <>{t('requirementCompliance.uploadFile', 'Upload file...')}</>
                                                    )
                                            }

                                            <Input type="file" name="files" onChange={e => uploadFile(e.currentTarget.files)} disabled={(links?.length ?? 0) >= maxLinksCount} />
                                        </Label>
                                        {/*<ConditionalFragment showIf={requirement.policyManagerType === policyManagerLinkTypes.linked.id}>
                                            <Button color="primary" outline
                                                onClick={() => changeLink(requirementComplianceLinkTypes.policyManager, requirement.policyManagerId.toString())}
                                                >
                                                {t('requirementCompliance.uploads.usePolicyManager', 'Use policy manager')}
                                            </Button>
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={!!firstSchoolBusLink}>
                                            <Button color="primary" outline
                                                onClick={() => changeLink(requirementComplianceLinkTypes.urlLink, firstSchoolBusLink?.url)}
                                            >
                                                {t('requirementCompliance.uploads.useSchoolBusLink', 'Use "{{linkName}}"', { linkName: firstSchoolBusLink?.linkText || firstSchoolBusLink?.url || '' })}
                                            </Button>
                                        </ConditionalFragment>*/}
                                        <ConditionalFragment showIf={!!links?.length}>
                                            <ButtonDropdown isOpen={isUploadMenuOpen} toggle={() => toggleUploadMenuOpen()}>
                                                <DropdownToggle color="primary" outline caret>
                                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        links?.map(link => (
                                                            <DropdownItem className="text-danger" onClick={() => removeLink(link.id)}>
                                                                <FontAwesomeIcon icon="trash" />
                                                                <> </>{t('requirementCompliance.uploads.removeUpload', 'Remove {{filename}}', { filename: getLinkFileName(link) || link?.url || ''})}
                                                            </DropdownItem>
                                                            ))
                                                    }
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </ConditionalFragment>
                                    </ButtonGroup>
                                    <FormText>
                                        {t('requirementCompliance.maxLinksWarning', 'You can attach up to {{maxLinksCount}} policies or supporting documentation as evidence.', { maxLinksCount })}
                                    </FormText>
                                </div>
                            </FormGroup>
                        </ConditionalFragment>

                        <div className="mt-2">
                            <span>
                                {
                                    complianceState.isFailure && action ? (
                                        <span className="text-muted">
                                            {t('requirementCompliance.failureHasBeenAddedToActionPlan', 'An action has been automatically added to your action plan for you to plan the changes required to meet this requirement.')}
                                        </span>
                                    ) : complianceState.isSuccess && requirement.requiresReview && action && action.isAutomaticFromRequirementCompliance ? (
                                            <span className="text-muted">
                                                {t('requirementCompliance.reviewHasBeenAddedToActionPlan', 'An action has been added to your action plan for when you need to review this requirement again.')}
                                            </span>
                                        ): complianceState.isSuccess && requirement.requiresReview ? (
                                                <>
                                                    <strong>
                                                        {t('requirementCompliance.reviewsHeading', 'Review')}
                                                    </strong>
                                                    <div>
                                                        <HtmlDisplay sanatizedHtml={requirement.requiresReviewDescriptionHtml} />
                                                    </div>
                                                    <Row>
                                                        <Col xs="12" md="auto">
                                                            <Label htmlFor="reviewDate">
                                                                {t('requirementCompliance.reviewDate', 'Schedule next review for')}
                                                            </Label>
                                                        </Col>
                                                        <Col xs="12" md="auto">
                                                            <DateInput value={reviewDate} onChange={e => setReviewDate(e.currentTarget.value)} />
                                                        </Col>
                                                        <Col xs="12" md="">
                                                            <Button color="primary" onClick={() => { addOrUpdateAction('review', true, reviewDate); saveComplianceDebounce(); }}>
                                                                {t('requirementCompliance.addReviewToActionPlan', 'Add review reminder to action plan')}
                                                            </Button>                                                    
                                                        </Col>
                                                    </Row>
                                                </>
                                        ) : action && !action.isAutomaticFromRequirementCompliance ? (
                                                <span className="text-muted">
                                                    {t('requirementCompliance.maualActionHasBeenAdded', 'There is an action on the action plan for this requirement.')}
                                                </span>
                                            ) : (
                                                        <Button size="sm" color="primary" outline onClick={() => { addOrUpdateAction('userAdded', false); saveComplianceDebounce(); }}>
                                                            {t('requirementCompliance.addToActionPlan', 'Add this requirement to the action plan')}
                                                    </Button>
                                                )
                                }

                                <ConditionalFragment showIf={!!action}>
                                    <> </>
                                    <Link to={getActionUrl(action?.id ?? '', user, school?.id)}>
                                        {t('requirementCompliance.seeAction', 'See action.')}
                                    </Link>
                                </ConditionalFragment>
                            </span>
                        </div>
                    </Collapse>
                </Collapse>
            </CardBody>
            <ConditionalFragment showIf={isStandardHelpModalOpen}>
                <StandardsHelpModal isOpen={isStandardHelpModalOpen} toggle={() => toggleStandardHelpModal()} />
            </ConditionalFragment>
        </Card>
        );
};
