import * as React from 'react';
import { Modal, ModalHeader, Row, Col, ModalBody, Label, Button, ModalFooter, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Requirement } from '../../api/main/models/Requirement';
import { HtmlDisplay } from '../../shared/htmlEditor/HtmlDisplay';
import { DateInput } from '../shared/DateInput';

export interface CreateNextReviewActionModalProps {
    isOpen: boolean,
    toggle: () => void,

    model: Requirement,
    createNextReviewAction: (date: string) => void,
}

/**
 * Create the next review action when completing the previous review action.
 * @param props
 */
export const CreateNextReviewActionModal = (props: CreateNextReviewActionModalProps) => {
    const {
        isOpen, toggle,
        model,
        createNextReviewAction,
    } = props;
    const { t } = useTranslation();

    const [reviewDate, setReviewDate] = React.useState<string>(() => moment().add(model.requiresReviewInDefaultMonths, 'month').local().toISOString());

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle} modalClassName="root-app-modal">
            <ModalHeader toggle={toggle}>
                {t('createNextReviewActionModal.heading', 'Plan your next review')}
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label htmlFor="name">
                        {t('createNextReviewActionModal.requirement', 'Requirement')}
                    </Label>
                    <div>
                        {model.name}
                    </div>
                </FormGroup>
                <FormGroup>
                    <div>
                        <HtmlDisplay sanatizedHtml={model.requiresReviewDescriptionHtml} />
                    </div>
                    <Row>
                        <Col xs="12" md="auto">
                            <Label htmlFor="reviewDate">
                                {t('createNextReviewActionModal.reviewDate', 'Schedule next review for')}
                            </Label>
                        </Col>
                        <Col xs="12" md="auto">
                            <DateInput value={reviewDate} onChange={e => setReviewDate(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={() => { createNextReviewAction(reviewDate); toggle(); }}>
                    {t('createNextReviewActionModal.save', 'Add review reminder')}
                </Button>
                <> </>
                <Button color="danger" onClick={() => toggle()}>
                    {t('createNextReviewActionModal.cancel', 'No next review reminder')}
                </Button>
            </ModalFooter>
        </Modal>
        );
}