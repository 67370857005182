import * as React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'reactstrap';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/Background';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useParams } from 'react-router';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useSchoolCertificate } from '../../api/main/schoolCertificates/useSchoolCertificate';
import { AwardImage } from '../awards/awardImage/AwardImage';
import { AreaCertificateItem } from './AreaCertificateItem';
import { StandardRating } from '../../api/main/models/constants/StandardRatings';

/**
 * Certificate
 */
export const Certificate = () => {
    const {
        schoolId,
    } = useParams();

    const {
        data: {
            model,
        },
        isLoading, errors: loadingErrors
    } = useSchoolCertificate(schoolId);
 
    return (
        <Background className="certificate">
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h2 className="text-muted">
                                {model?.schoolName}
                            </h2>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>
            <Container fluid className="mt-2">
                <AlertOnErrors errors={[loadingErrors]} />

                <Row>
                    <Col xs={12} lg={6}>
                        <AwardImage standardRating={(model?.awardLevel as StandardRating) ?? undefined} />
                    </Col>
                    <Col xs={12} lg={6}>
                        <Card body className="mt-4">
                            <ListGroup flush>
                                {
                                    model?.areas?.map(item => {
                                        // Don't show anything where the user hasn't earnt an award level yet.
                                        if ((item.awardLevel ?? 'NoAward') === 'NoAward') {
                                            return null;
                                        }

                                        return (
                                            <AreaCertificateItem
                                                key={item.id}
                                                model={item}
                                            />
                                        );
                                    })
                                }
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Background>
        );
};
