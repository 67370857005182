import gql from "graphql-tag";
import { userFields } from "../generated/userFields";

export const userFieldsFragment = gql`
    fragment userFields on User {
        id
        schoolId
        userTypeId
        firstname
        surname
        email
        isMaster
        active
        isDeleted

    }
`;


export type User = Omit<userFields, '__typename'>;
