import * as React from 'react';
import { Route, Switch } from 'react-router';
import { RouteEntry } from '../../shared/routes';
import { AuthorizeRoute } from '../../shared/authorizeRoute';

export interface AppRoutesProps {
    routes: Array<RouteEntry>,
}

export const AppRoutes = (props: AppRoutesProps) => {
    const { routes } = props;

    return (
        <Switch>
            {
                routes.map((item, index) => {
                    // If we need to authorize for this route then use an AuthorizeRoute.
                    if (item.authorize || item.requireRole) {
                        return (<AuthorizeRoute key={index} path={item.path} exact={item.exact} component={item.component} render={item.render} requireRole={item.requireRole} />);
                    }

                    // Otherwise a simple Route will do.
                    return (<Route key={index} path={item.path} exact={item.exact} component={item.component} render={item.render} />);
                })
            }
        </Switch>
    );
};
