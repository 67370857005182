import * as React from "react";
import { useParams, useHistory } from "react-router";
import { ConditionalFragment } from "react-conditionalfragment";
import { EditSchoolTypesAndPhasesModal } from "./EditSchoolTypesAndPhasesModal";
import { useCurrentUser } from "../../api/api-authorization/useCurrentUser";
import { useToggleState } from "use-toggle-state";

/**
 * School settings main screen (launches the modal on access).
 */
export const SchoolSettings = () => {
    // Get the school to edit.
    const { schoolId: schoolIdParam } = useParams<{ schoolId?: string | undefined }>();
    const user = useCurrentUser();
    const schoolId = schoolIdParam ?? user?.schoolId;

    // Manage the modal.  The modal should be open on first use.
    const [modalIsOpen, _toggleModal] = useToggleState(true);
    const history = useHistory();
    const closeModal = React.useCallback(() => {
        _toggleModal();
        history.push(`/overview${schoolIdParam ? '/' + schoolIdParam : ''}`);
    }, [_toggleModal, history, schoolIdParam]);

    // UI.
    return (
        <ConditionalFragment showIf={modalIsOpen}>
            <EditSchoolTypesAndPhasesModal
                isOpen={modalIsOpen} toggle={closeModal}
                schoolId={schoolId ?? 0}
            />
        </ConditionalFragment>
    );
};
