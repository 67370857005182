import { Library, library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faLock, faUser, faSearch, faPlus, faCogs, faSave, faEdit, faUsers, faTrash, faCaretUp, faCaretDown, faCaretLeft, faUndo, faMinus, faExclamationCircle, faCaretRight, faTimesCircle, faCalendarWeek, faProjectDiagram, faBusinessTime, faStopwatch, faUserClock, faTimes, faStop, faGraduationCap, faSchool, faChalkboard, faHistory, faFlagCheckered, faLink, faUnlink, faCheckCircle, faCheck, faBookReader, faLandmark, faTasks, faPrint, faFilePdf, faMagic, faList, faBriefcase, faDownload, faShareSquare, faPaperPlane, faChartBar, faChartPie, faStickyNote, faPlusSquare, faEllipsisV, faThumbtack, faArrowRight, faAward, faUserCog, faUserCircle, faTh, faCommentAlt, faSort, faFileVideo, faPlay, faAngleDoubleUp, faAngleDoubleDown, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faFrown as farFrown, faFrownOpen as farFrownOpen, faSmile as farSmile } from '@fortawesome/free-regular-svg-icons';

// Import the fontawsome icons we actually want to use.
export const initializeIcons = () => {
    configureIcons(library);
};

export const configureIcons = (library: Library) => {
    library.add(
        // solid
        faBookReader,
        faBriefcase,
        faBusinessTime,
        faCalendarWeek,
        faCaretUp,
        faCaretDown,
        faCaretLeft,
        faCaretRight,
        faChalkboard,
        faChartBar,
        faChartPie,
        faCheck,
        faCheckCircle,
        faCogs,
        faDownload,
        faEdit,
        faExclamationCircle,
        faFilePdf,
        faFileVideo,
        faFlagCheckered,
        faGraduationCap,
        faHistory,
        faLandmark,
        faLink,
        faList,
        faLock,
        faMagic,
        faMinus,
        faPaperPlane,
        faPlay,
        faPlus,
        faPrint,
        faProjectDiagram,
        faSave,
        faSchool,
        faSearch,
        faShareSquare,
        faSpinner,
        faStop,
        faStopwatch,
        faTasks,
        faTimes,
        faTimesCircle,
        faTrash,
        faUndo,
        faUnlink,
        faUser,
        faUserClock,
        faUsers,
        faStickyNote,
        faPlusSquare,
        faEllipsisV,
        faThumbtack,
        faArrowRight,
        faAward,
        faUserCog,
        faUserCircle,
        faTh,
        faCommentAlt,
        faSort,
        faAngleDoubleUp,
        faAngleDoubleDown,
        faAngleRight,
        faAngleLeft,

        // regular
        farFrown,
        farFrownOpen,
        farSmile,
    );
};
