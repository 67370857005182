import gql from "graphql-tag";
import { requirementsReleaseRequirementAreaFields } from "../generated/requirementsReleaseRequirementAreaFields";
import { requirementAreaFieldsFragment } from "./RequirementArea";

export const requirementsReleaseRequirementAreaFieldsFragment = gql`
    fragment requirementsReleaseRequirementAreaFields on RequirementsReleaseRequirementArea {
        id
        requirementsReleaseId
        requirementAreaId
        displayOrder
        archived

        area {
            ...requirementAreaFields
        }
    }

    ${requirementAreaFieldsFragment}
`;


export type RequirementsReleaseRequirementArea = Omit<requirementsReleaseRequirementAreaFields, '__typename' | 'area'>;
