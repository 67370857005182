import gql from "graphql-tag";
import { videoFields } from "../generated/videoFields";
import { Guid } from "guid-string";

export const videoFieldsFragment = gql`
    fragment videoFields on Video {
        id
        name
        description
        authorName
        videoBlobId
        thumbnailBlobId
        archived
    }
`;


export type Video = Omit<videoFields, '__typename'>;

export const videoDefaultValues = (): Partial<Video> => ({
    id: Guid.newGuid(),
    name: '',
    description: '',
    authorName: '',
    videoBlobId: undefined,
    thumbnailBlobId: undefined,
    archived: false,
});
