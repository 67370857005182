import * as React from 'react';
import moment, { Moment } from 'moment';
import { InputProps, Input } from 'reactstrap';

export interface DateInputProps extends InputProps {
}

/**
 * Input enhanced for better handling of dates in the way we store them.
 * @param props
 */
export const DateInput = (props: DateInputProps) => {
    const {
        type = 'date',
        value,
        onChange,
        ...rest
    } = props;

    // Handle the value
    const theValue = React.useMemo(() => {
        let format = 'YYYY-MM-DD'
        if (type === 'datetime' || type === 'datetime-local') {
            format = 'YYYY-MM-DD[T]HH:mm';
        }

        const ret = moment(value).local().format(format);
        return ret;
    }, [value, type]);

    // Wrap the real on change event to always return the ISO date string in UTC time.
    const onChangeWrapper = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const newValue: Moment = moment(event.currentTarget.value).utc();
            if (!newValue.isValid()) {
                return;
            }

            const newEvent: any = {
                ...event,
                currentTarget: {
                    ...event.currentTarget,
                    value: newValue.toISOString(),
                    valueAsDate: newValue.toDate(),
                },
                target: {
                    ...event.target,
                    value: newValue.toISOString(),
                    valueAsDate: newValue.toDate(),
                }
            };

            onChange(newEvent);
        }
    }, [onChange]);

    return (
        <Input type={type} value={theValue} onChange={onChangeWrapper} {...rest} />
    );
};
