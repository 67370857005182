import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { RequirementSchoolPhaseUpdateInput } from '../generated/globalTypes';
import { updateRequirementSchoolPhaseMutation, updateRequirementSchoolPhaseMutationVariables } from '../generated/updateRequirementSchoolPhaseMutation';
import { requirementSchoolPhaseFieldsFragment } from '../models/RequirementSchoolPhase';

/**
 * Get a callback to update a RequirementSchoolPhase in the store.
 */
export function useUpdateRequirementSchoolPhaseCallback(): [(id: string, model: RequirementSchoolPhaseUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateRequirementSchoolPhaseMutation, updateRequirementSchoolPhaseMutationVariables>(
        gql`
            mutation updateRequirementSchoolPhaseMutation ($model: RequirementSchoolPhaseUpdateInput!) {
                updateRequirementSchoolPhase(model: $model) {
                    ...requirementSchoolPhaseFields
                }
            }

            ${requirementSchoolPhaseFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: RequirementSchoolPhaseUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
