import { useRouteMatch } from 'react-router';
import { useMemo } from 'react';
import { useCurrentUser } from '../../../api/api-authorization/useCurrentUser';
import { useLookupSchoolGroupForSchool } from '../../../api/main/schoolGroups/useLookupSchoolGroupForSchool';
import { schoolGroupTypes } from '../../../services/schoolGroupTypes/schoolGroupTypes';

/**
 * Hook to return the current schoolId either from the current URL (to cope with MAT users looking at other schools) or from the current user.
 */
export function useCurrentSchoolId(options?: { routeOnly: true }): number | undefined {
    // Extract the schoolId from the route.
    const routeMatch = useRouteMatch<{ view?: string, schoolId?: string }>("/:view/:schoolId");
    const routeSchoolId = useMemo(() => {
        const routeSchoolId = routeMatch?.params?.schoolId ?? '';
        if (/^-?\d+$/.test(routeSchoolId)) {
            return parseInt(routeSchoolId);
        }

        return undefined;
    }, [routeMatch]);

    // Lookup the current user.
    const user = useCurrentUser();
    
    // Grab the MAT for the route school and the current user's school.
    const { data: { model: routeMat } } = useLookupSchoolGroupForSchool(routeSchoolId?.toString() ?? '0', schoolGroupTypes.multiAcademyTrust.id.toString(), { fetchPolicy: 'cache-first' /* Can cache-first here without refreshing each time */ });
    const { data: { model: usersMat } } = useLookupSchoolGroupForSchool(user?.schoolId?.toString() ?? '0', schoolGroupTypes.multiAcademyTrust.id.toString(), { fetchPolicy: 'cache-first' /* Can cache-first here without refreshing each time */ });


    // Work out what we need to return.
    const ret = useMemo(() => {
        // If we don't have a schoolId on the route then our logic is simple we either return the user's schoolId, or if we are asked for routeOnly no id.
        if (!routeSchoolId) {
            if (options?.routeOnly) {
                return undefined;
            }

            return user?.schoolId;
        }

        //// If the user doesn't have the MAT role, they can't view any other schools so just return this user's school (if they have one).
        //if (!hasMATRole) {
        //    return user?.schoolId;
        //}

        // If the user can view other schools, only allow it if they are in the same MAT.
        if (usersMat && usersMat.id === routeMat?.id) {
            return routeSchoolId;
        }

        // The SchoolBusTeam (school 55) can also view anyones'
        const theSchoolBusTeamSchoolId = 55;
        if (user?.schoolId === theSchoolBusTeamSchoolId) {
            return routeSchoolId;
        }
        
        // Otherwise fall back on the user's own id.
        return user?.schoolId;
    }, [routeSchoolId, options, user, /*hasMATRole,*/ usersMat, routeMat]);

    return ret ?? undefined;
}
