import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { SchoolRequirementComplianceLinkCreateInput } from '../generated/globalTypes';
import { createSchoolRequirementComplianceLinkMutation, createSchoolRequirementComplianceLinkMutationVariables } from '../generated/createSchoolRequirementComplianceLinkMutation';
import { schoolRequirementComplianceLinkFieldsFragment } from '../models/SchoolRequirementComplianceLink';

/**
 * Get a callback to create a SchoolRequirementComplianceLink in the store.
 */
export function useCreateSchoolRequirementComplianceLinkCallback(): [(model: SchoolRequirementComplianceLinkCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createSchoolRequirementComplianceLinkMutation, createSchoolRequirementComplianceLinkMutationVariables>(
        gql`
            mutation createSchoolRequirementComplianceLinkMutation ($model: SchoolRequirementComplianceLinkCreateInput!) {
                createSchoolRequirementComplianceLink(model: $model) {
                    ...schoolRequirementComplianceLinkFields
                }
            }

            ${schoolRequirementComplianceLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: SchoolRequirementComplianceLinkCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
