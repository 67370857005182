import './schoolAreaCompliance.scss';

import * as React from 'react';
import { useParams, useHistory } from 'react-router';
import { Background } from '../shared/Background';
import { Banner } from '../shared/Banner';
import { Row, Col, Container, Spinner, Button, Alert, Card } from 'reactstrap';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { StickyToolbar } from '../shared/StickyToolbar';
import { ComplianceProgressBar } from './ComplianceProgressBar';
import { RequirementCompliance } from './RequirementCompliance';
import { useChangesArray } from '../../shared/useChanges';
import { SchoolRequirementCompliance } from '../../api/main/models/SchoolRequirementCompliance';
import { Requirement } from '../../api/main/models/Requirement';
import { Guid } from 'guid-string';
import moment  from 'moment';
import { useAsyncCallback } from 'react-use-async-callback';
import { useSaveSchoolRequirementComplianceCallback } from '../../api/main/schoolRequirementCompliances/useSaveSchoolRequirementComplianceCallback';
import { useDeleteSchoolRequirementComplianceCallback } from '../../api/main/schoolRequirementCompliances/useDeleteSchoolRequirementComplianceCallback';
import { RequirementComplianceLinkType, requirementComplianceLinkTypes } from '../../services/requirementComplianceLinkTypes/requirementComplianceLinkTypes';
import { SchoolRequirementComplianceLink } from '../../api/main/models/SchoolRequirementComplianceLink';
import { useSaveSchoolRequirementComplianceLinkCallback } from '../../api/main/schoolRequirementComplianceLinks/useSaveSchoolRequirementComplianceLinkCallback';
import { useDeleteSchoolRequirementComplianceLinkCallback } from '../../api/main/schoolRequirementComplianceLinks/useDeleteSchoolRequirementComplianceLinkCallback';
import { useTranslation } from 'react-i18next';
import { Action } from '../../api/main/models/Action';
import { useSaveActionCallback } from '../../api/main/actions/useSaveActionCallback';
import { useDeleteActionCallback } from '../../api/main/actions/useDeleteActionCallback';
import { useSearchParams } from '../../shared/useURLSearchParams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentUser } from '../../api/api-authorization/useCurrentUser';
import { useDebounce } from '../shared/hooks/useDebounce';
import { HtmlDisplay } from '../../shared/htmlEditor/HtmlDisplay';
import { useAddOrUpdateAction } from "./useAddOrUpdateAction";
import { useChangeCompliance } from './useChangeCompliance';
import { useSchoolAreaComplianceSupportingData } from '../../api/main/schoolCompliance/useSchoolAreaComplianceSupportingData';
import { useRequirementsForSchool } from "../schoolOverview/useRequirementsForSchool/useRequirementsForSchool";
import { RenderIfVisible } from '../../shared/useIsElementVisible';
import { LinkContainer } from 'react-router-bootstrap';
import { useSchoolOverviewViewModel } from '../../api/main/schoolOverview/useSchoolOverviewViewModel';
import { useSchoolOverviewSupportingData } from '../../api/main/schoolOverview/useSchoolOverviewSupportingData';
import { LoadingAndCalculatingPlaceholder } from '../schoolOverview/LoadingAndCalculatingPlaceholder';
import { useSchoolRequirementComplianceLinks } from '../../api/main/schoolRequirementComplianceLinks/useSchoolRequirementComplianceLinks';
import { useRequirementsFilterOptions } from '../schoolOverview/useRequirementsFilterOptions';
import { RequirementsFilter } from '../schoolOverview/RequirementsFilter';

export interface SchoolAreaComplianceProps {
}

export const SchoolAreaCompliance = (props: SchoolAreaComplianceProps) => {
    const {
        schoolId: paramsSchoolId,
        requirementAreaId,
    } = useParams();

    // Get the item of the requirement to filter to (if any).  Note this used to scroll but because of virtualising the off screen cards
    // we can no longer do this reliably due to variable hieghts of unrendered items.  For this reason we now filter to only show this item
    // instead.
    const { requirement: filterToRequirementOriginKey } = useSearchParams();

    const { t } = useTranslation();
    const history = useHistory();

    const user = useCurrentUser();
    const schoolId = paramsSchoolId ?? user?.schoolId?.toString();

    const {
        data: {
            school,
            schoolRequirementCompliances: storeSchoolRequirementCompliances,
            actions: storeActions,
            schoolSchoolTypes,
            schoolSchoolPhases,
        },
        isLoading: _isLoading, errors: loadingErrors,
    } = useSchoolOverviewViewModel(schoolId);

    const {
        data: {
            currentRequirementsRelease: release,
            //videos,
        },
        isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors,
    } = useSchoolOverviewSupportingData({ fetchPolicy: "cache-first" });

    // Load the extra supporting data that is not covered by useSchoolOverviewSupportingData.
    // We do this to maximise the benefits of "cache-first" as the user navigates between the two screens a lot and so this
    // lets us share a cache for the majority of the data.
    const {
        data: {
            requirementSchoolBusLinks: schoolBusLinks,
            videos,
        },
        isLoading: isLoadingSpecificsSupportingData, errors: loadSpecificsSupportingDataErrors
    } = useSchoolAreaComplianceSupportingData({ fetchPolicy: "cache-first" });

    // This is the only screen we want these compliance links on, so load them now.
    const { data: { items: storeSchoolRequirementComplianceLinks }, isLoading: isLoadingSchoolRequirementComplianceLinks, errors: loadSchoolRequirementComplianceLinksErrors } = useSchoolRequirementComplianceLinks({ schoolId: schoolId })

    const isLoading = _isLoading || isLoadingSupportingData || isLoadingSpecificsSupportingData || isLoadingSchoolRequirementComplianceLinks;

    // Manager that looks after all compliance records.
    const complianceManager = useChangesArray<SchoolRequirementCompliance, string>(storeSchoolRequirementCompliances, item => item.id);
    const changeCompliance = useChangeCompliance({
        complianceManager,
        schoolId,
    });
    const [saveCompliance, { errors: saveComplianceErrors }] = useSaveSchoolRequirementComplianceCallback();
    const [removeCompliance, { errors: removeComplianceErrors }] = useDeleteSchoolRequirementComplianceCallback();

    // Manager that looks after all complianceLink records.
    const complianceLinkManager = useChangesArray<SchoolRequirementComplianceLink, string>(storeSchoolRequirementComplianceLinks, item => item.id);
    const addUploadLink = React.useCallback((requirement: Requirement, linkType: RequirementComplianceLinkType | null, url?: string, blobId?: string) => {
        // Work out the compliance record for this requirement.
        const compliance = complianceManager.model.find(item => item.requirementOriginKey === requirement.originKey && !item.archived);
        if (!compliance) {
            return;
        }

        // Otherwise we want a record, so if we didn't find an entry, create one.
        const newEntry: SchoolRequirementComplianceLink = {
            id: Guid.newGuid(),
            schoolRequirementComplianceId: compliance.id,
            linkType: linkType?.id ?? requirementComplianceLinkTypes.fileUpload.id,
            linkedDate: moment().toISOString(),
            notesHtml: '',
            url: url ?? '',
            blobId: blobId || Guid.empty,
        };
        complianceLinkManager.addFor(newEntry);
    }, [complianceLinkManager, complianceManager]);

    const removeUploadLink = React.useCallback((id: string) => {
        complianceLinkManager.removeFor(id);
    }, [complianceLinkManager]);

    const [saveComplianceLink, { errors: saveComplianceLinkErrors }] = useSaveSchoolRequirementComplianceLinkCallback();
    const [removeComplianceLink, { errors: removeComplianceLinkErrors }] = useDeleteSchoolRequirementComplianceLinkCallback();

    // Manager that looks after actions.
    const actionsManager = useChangesArray<Action, string>(storeActions, item => item.id);
    const [saveAction, { errors: saveActionErrors }] = useSaveActionCallback();
    const [removeAction, { errors: removeActionErrors }] = useDeleteActionCallback();

    // Add or update the auto action for a requirement.
    const addOrUpdateAction = useAddOrUpdateAction({ actionsManager, schoolId });

    // Get the model of the area we are showing.
    const model = React.useMemo(() => {
        if (!release) {
            return null;
        }

        const myLink = release.areas.find(it => it.requirementAreaId === requirementAreaId);
        return myLink?.area ?? null;
    }, [release, requirementAreaId]);

    // Get all requirements that match this school's configuration within the area.
    const allRequirements = useRequirementsForSchool(release, schoolSchoolTypes, schoolSchoolPhases);

    // Filter the requirements down by our area.
    const requirements = React.useMemo(() => {
        return allRequirements.filter(requirement => {
            const link = release?.requirements.find(it => it.requirementId === requirement.id && it.requirementAreaId === requirementAreaId);
            if (!link) {
                return false;
            }

            return true;
        });
    }, [allRequirements, requirementAreaId, release]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options: { dontNavigateBack?: boolean } = {}) => {
        // Save all the compliance answers.
        for (const item of complianceManager.added) {
            await saveCompliance(item.id, complianceManager.changesFor(item.id), true);
        }
        for (const item of complianceManager.updated) {
            await saveCompliance(item.id, complianceManager.changesFor(item.id), false);
        }
        for (const item of complianceManager.removed) {
            await removeCompliance(item.id);
        }
        complianceManager.markAsSaved();

        // Save all the links to uploads/urls for the answers
        for (const item of complianceLinkManager.added) {
            await saveComplianceLink(item.id, complianceLinkManager.changesFor(item.id), true);
        }
        for (const item of complianceLinkManager.updated) {
            await saveComplianceLink(item.id, complianceLinkManager.changesFor(item.id), false);
        }
        for (const item of complianceLinkManager.removed) {
            await removeComplianceLink(item.id);
        }
        complianceLinkManager.markAsSaved();

        // Save all the actions.
        for (const item of actionsManager.added) {
            await saveAction(item.id, actionsManager.changesFor(item.id), true);
        }
        for (const item of actionsManager.updated) {
            await saveAction(item.id, actionsManager.changesFor(item.id), false);
        }
        for (const item of actionsManager.removed) {
            await removeAction(item.id);
        }
        actionsManager.markAsSaved();
    }, [
        complianceManager, saveCompliance, removeCompliance,
        complianceLinkManager, saveComplianceLink, removeComplianceLink,
        actionsManager, saveAction, removeAction,
    ]);

    // Save everything with some debounce support so we don't try and save after change if the user is making changes quickly.
    const saveFormDebounce = useDebounce(() => {
        saveForm();
    }, { delay: 2000 });

    // Filter options.  These are carried forward from SchoolOverview and will be maintained between sessions.
    const { filter, toggleFilter, resetFilter, shouldShowRequirement, } = useRequirementsFilterOptions();


    // We want to show the loading placeholder if we don't have enough information to show anything else.
    const showLoadingPlaceholder = !release || !model;

    // Get a list of visible requirements after applying the filtering.
    const filteredRequirements = React.useMemo(() => requirements?.filter(requirement => {
        // If we are trying to view to a specific named requirement by origin key, make sure we always include it regardless of the filter.
        if (!!filterToRequirementOriginKey && requirement.originKey === filterToRequirementOriginKey) {
            return true;
        }

        // Get the compliance state (if any) for this requirement.
        const compliance = complianceManager.model?.find(it => it.requirementOriginKey === requirement.originKey);

        // Check if we should display this item.
        const shouldShow = shouldShowRequirement(requirement, compliance);
        if (!shouldShow) {
            return false;
        }

        return true;
    }), [requirements, complianceManager, shouldShowRequirement]);
    const hasNoRequirementsToShow = !filteredRequirements?.length && !!requirements?.length;

    // Mark all requirements as not relevant to our school (unless they have already been answered as that could cause data loss)
    return (
        <div className="school-area-compliance">
            <Background>
                <Banner fluid>
                    <StickyToolbar>
                        <Row>
                            <Col>
                                <h2 className="text-muted" style={{ display: 'inline-block' }}>
                                    <Button color="primary" outline onClick={() => history.goBack()} style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                                        <FontAwesomeIcon icon="caret-left" />
                                        <span className="sr-only"><> </>{t('common.back', 'Back')}</span>
                                    </Button>
                                    <> </>
                                    {model?.name}
                                </h2>
                            </Col>
                            <ConditionalFragment showIf={isLoading && !showLoadingPlaceholder /* Don't want two flashing dots during the first load */}>
                                <Col xs="auto">
                                    <LoadingIndicator size="sm" />
                                </Col>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={isSaving}>
                                <Col xs="auto">
                                    <Spinner size="sm" />
                                </Col>
                            </ConditionalFragment>
                            <Col xs={12} lg={8}>
                                <ComplianceProgressBar size="lg" requirements={requirements} compliances={complianceManager.model} />
                            </Col>
                        </Row>
                    </StickyToolbar>
                    <div className="text-muted">
                        <HtmlDisplay sanatizedHtml={model?.descriptionHtml ?? ''} />
                    </div>
                </Banner>
                <Container fluid className="mt-2">
                    <AlertOnErrors errors={[
                        loadingErrors, loadSupportingDataErrors, loadSpecificsSupportingDataErrors,
                        saveActionErrors, removeActionErrors,
                        saveFormErrors,
                        saveComplianceErrors, removeComplianceErrors,
                        saveComplianceLinkErrors, removeComplianceLinkErrors,
                        loadSchoolRequirementComplianceLinksErrors,
                    ]} />

                    {
                        // Only show the filter area if we haven't got here by navigated to a specific named requirement.
                        !filterToRequirementOriginKey && !!model ? (
                            <Row className="mb-4">
                                <Col>
                                    <RequirementsFilter
                                        filter={filter}
                                        toggleFilter={toggleFilter}
                                        resetFilter={resetFilter}
                                    />
                                </Col>
                            </Row>
                        ): null
                        
                    }
                    

                    {/* Show the loading placeholder until we have enough data to show something more useful. */}
                    {
                        showLoadingPlaceholder ? (<LoadingAndCalculatingPlaceholder />) : null
                    }

                    <ConditionalFragment showIf={!!filterToRequirementOriginKey && !!model}>
                        <Alert color="info">
                            <Row>
                                <Col>
                                    {t('schoolAreaCompliance.onlyShowingFilteredRequirement', 'We are only showing you the requirement you clicked on.  There are {{count}} more {{areaName}} requirements available.', { count: ((requirements?.length ?? 0) - 1), areaName: model?.name })}
                                </Col>
                                <Col xs={12} sm="auto">
                                    <LinkContainer to={window.location.pathname}>
                                        <Button color="info">
                                            {t('schoolAreaCompliance.viewAll', 'View all {{areaName}} requirements', { areaName: model?.name })}
                                        </Button>
                                    </LinkContainer>
                                </Col>
                            </Row>
                        </Alert>
                    </ConditionalFragment>

                    {
                        filteredRequirements
                            ?.filter(item => !filterToRequirementOriginKey || item.originKey === filterToRequirementOriginKey)
                            ?.map(item => {
                                // Get the compliance state (if any) for this requirement.
                                const compliance = complianceManager.model.find(it => it.requirementOriginKey === item.originKey);

                                // Render the item.
                                return (
                                    <RenderIfVisible key={item.id} fallback={<div style={{ minHeight: '277px' }}></div>}>
                                        <RequirementCompliance
                                            requirement={item}
                                            schoolBusLinks={schoolBusLinks?.filter(it => it.requirementId === item.id) ?? []}
                                            schoolCompliance={compliance}
                                            changeCompliance={(changes) => changeCompliance(item, changes)}
                                            links={complianceLinkManager.model.filter(link => link.schoolRequirementComplianceId === complianceManager.model.find(it => it.requirementOriginKey === item.originKey)?.id)}
                                            addLink={(linkType, url, blobId) => addUploadLink(item, linkType, url, blobId)}
                                            removeLink={removeUploadLink}
                                            saveComplianceDebounce={saveFormDebounce}
                                            action={actionsManager.model.find(it => it.requirementOriginKey === item.originKey && /*it.isAutomaticFromRequirementCompliance === isAutoAction &&*/ !it.archived && !it.completedDate)}
                                            addOrUpdateAction={(reason, isAutoAction, date) => addOrUpdateAction(item, reason, isAutoAction, date)}
                                            user={user} school={school}
                                            video={videos?.find(it => it.id === item.videoId)}
                                        />
                                    </RenderIfVisible>
                                );
                            })
                    }

                    {
                        hasNoRequirementsToShow ? (
                            <Card body className="text-muted text-center">
                                {t('areaComplianceCard.nothingToShow.before', 'There are no requirements in {{name}} that meet your filter.', { name: model?.name, })}
                                <> </>
                                <Button color="link" style={{ paddingLeft: '0px', }} onClick={resetFilter}>
                                    {t('schoolOverview.filter.reset', 'Reset filters')}
                                </Button>
                                <> </>
                                {t('areaComplianceCard.nothingToShow.after', '', { name: model?.name, })}
                            </Card>
                        ) : null
                    }
                </Container>
            </Background>
        </div>
    );
};
