import * as React from 'react';
import { ModelChanges } from './ModelChanges';

/**
 * Hook that makes it easy to apply changes ontop of original data.
 */
export function useChanges<T>(unchanged: T | undefined | null, initialChanges: Partial<T> = {}): ModelChanges<T> {
    // Store changes in the state.
    const [changes, setChanges] = React.useState<Partial<T>>(initialChanges);

    // Provide a method to apply a change or set of changes.
    const change = React.useCallback((changes: Partial<T>) => {
        setChanges(prevState =>({
                ...prevState,
                ...changes
            }));
    }, [setChanges]);

    // Provide a method to reset all changes.
    const reset = React.useCallback(() => {
        setChanges({});
    }, [setChanges]);

    // Combine the initial model with the changes to produce model with its current state.
    const model = React.useMemo((): T => {
        return {
            ...(unchanged ?? {} as T), ...changes
        };
    }, [unchanged, changes]);

    // Combine everything into a return value and return it (caching it with useMemo()).
    const ret = React.useMemo(() => ({
        change: change,
        changes: changes,
        reset: reset,
        model: model
    }), [changes, change, reset, model]);

    return ret;
}